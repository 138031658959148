export const COLUMNS = [
  {
    Header: "Agent",
    Footer: "agent",
    accessor: "agent",
  },
  {
    Header: "command_id",
    Footer: "command_id",
    accessor: "command_id",
  },
  {
    Header: "Address",
    Footer: "address",
    accessor: "address",
  },
  {
    Header: "Car_owner_number",
    Footer: "car_owner_number",
    accessor: "car_owner_number",
  },
  {
    Header: "Client_full_name",
    Footer: "client_full_name",
    accessor: "client_full_name",
  },
  {
    Header: "Client_number",
    Footer: "client_number",
    accessor: "client_number",
  },
  {
    Header: "Car_name",
    Footer: "car_name",
    accessor: "car_name",
  },
  {
    Header: "Reports",
    Footer: "reports",
    accessor: "reports",
  },
];
