import axios from "axios";
import { getToken, getUserId, getUserType } from "../utils/helpers";

const API_URL = process.env.REACT_APP_API;

const getAgentsNames = () => {
  return axios.get(
    API_URL +
      getUserType().replace("_", "-") +
      "/agents/names/" +
      getUserId() +
      "/",
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};
const AssignSellerAgent = (command_id, agent_id) => {
  return axios.post(
    API_URL +
      getUserType().replace("_", "-") +
      "/assign-verification/" +
      getUserId() +
      "/",
    {
      command_id,
      agent_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};
const AssignClientAgent = (command_id, agent_id) => {
  return axios.post(
    API_URL +
      getUserType().replace("_", "-") +
      "/assign-payment/" +
      getUserId() +
      "/",
    {
      command_id,
      agent_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const sendVerficationDocs = (formData, doctype) => {
  if (doctype === "ID") {
    return axios.post(
      API_URL +
        JSON.parse(localStorage.getItem("user")).type.replace("_", "-") +
        "/upload-ID/" +
        getUserId(),
      formData,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  } else if (doctype === "PASSPORT") {
    return axios.post(
      API_URL +
        JSON.parse(localStorage.getItem("user")).type.replace("_", "-") +
        "/upload-passport/" +
        getUserId(),
      formData,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
};

const AuthAgentService = {
  getAgentsNames,
  AssignSellerAgent,
  AssignClientAgent,
  sendVerficationDocs,
};

export default AuthAgentService;
