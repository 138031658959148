import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import notificationService from "../../Service/notification.service";
import { setMessage } from "./message";

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async (payload, thunkAPI) => {
    try {
      const response = await notificationService.getNotifications();
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getNotificationContent = createAsyncThunk(
  "notification/getNotificationContent",
  async (payload, thunkAPI) => {
    try {
      const response = await notificationService.getNotificationContent(
        payload.id
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteNotification = createAsyncThunk(
  "notification/deleteNotification",
  async (payload, thunkAPI) => {
    try {
      const response = await notificationService.deleteNotification(payload.id);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    notification: [],
    isLoading: false,
  },
  extraReducers: {
    [getNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload;
      state.isLoading = false;
    },
    [getNotifications.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getNotifications.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getNotificationContent.fulfilled]: (state, action) => {
      state.notification = action.payload;
    },
  },
});

const { reducer } = notificationSlice;
export default reducer;
