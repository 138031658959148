import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AdminService from "../../Service/admin.service";
import { setMessage } from "./message";

export const getUnverifiedEmployees = createAsyncThunk(
  "admin/getUnverifiedEmployees",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.getUnverifiedEmployees(
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      return { UnverfiedEmp: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAuthAgents = createAsyncThunk(
  "admin/getAuthAgents",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.getAuthAgents(
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      return { AuthAgents: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAgents = createAsyncThunk(
  "admin/getAgents",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.getAgents(
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      return { Agents: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getClients = createAsyncThunk(
  "admin/getClients",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.getClients(
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      return { Clients: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getS_admin = createAsyncThunk(
  "admin/getS_admin",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.getS_admin(
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      return { S_admin: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBloggers = createAsyncThunk(
  "admin/getBloggers",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.getBloggers(
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      return { Bloggers: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deactivateAuthAgent = createAsyncThunk(
  "admin/deactivateAuthAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.deactivateAuthAgent(
        payload.id,
        payload.auth_agent_id,
        payload.token,
        payload.type
      );
      return { AuthAgents: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deactivateAgent = createAsyncThunk(
  "admin/deactivateAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.deactivateAgent(
        payload.id,
        payload.agent_id,
        payload.token,
        payload.type
      );
      return { Agents: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deactivateClient = createAsyncThunk(
  "admin/deactivateClient",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.deactivateClient(
        payload.id,
        payload.client_id,
        payload.token,
        payload.type
      );
      return { Clients: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deactivateS_admin = createAsyncThunk(
  "admin/deactivateS_admin",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.deactivateS_admin(
        payload.id,
        payload.sub_admin_id,
        payload.token,
        payload.type
      );
      return { S_admin: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deactivateBlogger = createAsyncThunk(
  "admin/deactivateBlogger",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.deactivateBlogger(
        payload.id,
        payload.blogger_id,
        payload.token,
        payload.type
      );
      return { Agents: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const accept = createAsyncThunk(
  "admin/accept",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.accept(payload.user_id, payload.type);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const decline = createAsyncThunk(
  "admin/decline",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.decline(
        payload.user_id,
        payload.type
      );
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const activateAuthAgent = createAsyncThunk(
  "admin/activateAuthAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.activateAuthAgent(
        payload.id,
        payload.auth_agent_id,
        payload.token,
        payload.type
      );
      return { AuthAgents: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const activateAgent = createAsyncThunk(
  "admin/activateAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.activateAgent(
        payload.id,
        payload.agent_id,
        payload.token,
        payload.type
      );
      return { Agents: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const activateClient = createAsyncThunk(
  "admin/activateClient",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.activateClient(
        payload.id,
        payload.client_id,
        payload.token,
        payload.type
      );
      return { Clients: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const activateS_admin = createAsyncThunk(
  "admin/activateS_admin",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.activateS_admin(
        payload.id,
        payload.sub_admin_id,
        payload.token,
        payload.type
      );
      return { S_admin: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const registerAuthAgent = createAsyncThunk(
  "admin/registerAuthAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.registerAuthAgent(
        payload.first_name,
        payload.last_name,
        payload.birth_date,
        payload.city,
        payload.communes,
        payload.phone,
        payload.email,
        payload.lang
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      // console.log("log error register ", error.response);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const registerAgent = createAsyncThunk(
  "admin/registerAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.registerAgent(
        payload.first_name,
        payload.last_name,
        payload.birth_date,
        payload.city,
        payload.phone,
        payload.email,
        payload.lang,
        payload.auth_agent_id
      );

      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const registerS_admin = createAsyncThunk(
  "admin/registerS_admin",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.registerS_admin(
        payload.first_name,
        payload.last_name,
        payload.birth_date,
        payload.city,
        payload.phone,
        payload.email,
        payload.lang
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      // console.log("log error register ", error.response);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const registerBlogger = createAsyncThunk(
  "admin/registerBlogger",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.registerBlogger(
        payload.first_name,
        payload.last_name,
        payload.birth_date,
        payload.city,
        payload.phone,
        payload.email,
        payload.lang
      );

      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAuthAgentsNames = createAsyncThunk(
  "admin/getAuthAgentsNames",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.getAuthAgentsNames(
        payload.id,
        payload.token,
        payload.lang,
        payload.type,
        payload.city
      );
      return { AuthAgents_names: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSettings = createAsyncThunk(
  "admin/getSettings",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.getSettings();
      return { Settings: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteFaq = createAsyncThunk(
  "admin/deleteFaq",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.deleteFaq(payload.id);
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateSettings = createAsyncThunk(
  "admin/updateSettings",
  async (payload, thunkAPI) => {
    try {
      const response = await AdminService.updateSettings(payload.data);
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return { Settings: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    AuthAgents: [],
    Agents: [],
    Clients: [],
    AuthAgents_names: [],
    S_admin: [],
    Bloggers: [],
    UnverfiedEmp: [],
    Settings: undefined,
    isLoading: false,
  },
  extraReducers: {
    [getAuthAgents.fulfilled]: (state, action) => {
      state.AuthAgents = action.payload.AuthAgents;
      state.isLoading = false;
    },

    [getAuthAgents.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getAuthAgents.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getAgents.fulfilled]: (state, action) => {
      state.Agents = action.payload.Agents;
      state.isLoading = false;
    },

    [getAgents.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getAgents.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getClients.fulfilled]: (state, action) => {
      state.Clients = action.payload.Clients;
      state.isLoading = false;
    },

    [getClients.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getClients.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getAuthAgentsNames.fulfilled]: (state, action) => {
      state.AuthAgents_names = action.payload.AuthAgents_names;
    },

    [getS_admin.fulfilled]: (state, action) => {
      state.S_admin = action.payload.S_admin;
      state.isLoading = false;
    },

    [getS_admin.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getS_admin.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getBloggers.fulfilled]: (state, action) => {
      state.Bloggers = action.payload.Bloggers;
      state.isLoading = false;
    },

    [getBloggers.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getBloggers.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getUnverifiedEmployees.fulfilled]: (state, action) => {
      state.UnverfiedEmp = action.payload.UnverfiedEmp;
      state.isLoading = false;
    },

    [getUnverifiedEmployees.rejected]: (state, action) => {
      state.isLoading = false;
    },

    [getUnverifiedEmployees.pending]: (state, action) => {
      state.isLoading = true;
    },

    [getSettings.fulfilled]: (state, action) => {
      state.Settings = action.payload.Settings;
      state.isLoading = false;
    },
    [getSettings.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getSettings.pending]: (state, action) => {
      state.isLoading = true;
    },
  },
});

const { reducer } = adminSlice;
export default reducer;
