import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Button from "../../components/Button.compenent";
import { ReactComponent as ResquestImg } from "../../assets/Request.svg";
import { getUserType } from "../../utils/helpers";
import { Navigate, useLocation } from "react-router-dom";
import moment from "moment";
import {
  getCommune,
  getDaira,
  getWilaya,
} from "../../redux/reducers/wilayaSlice";
import { clearMessage } from "../../redux/reducers/message";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { sendRequest } from "../../redux/reducers/requestSlice";
import Alert from "../../components/Alert";
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import { getSettings } from "../../redux/reducers/adminSlice";

function Request() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const id = JSON.parse(localStorage.getItem("user"))._id;
  const token = JSON.parse(localStorage.getItem("jwt"));
  const { t } = useTranslation();
  // const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [city, setCity] = useState();
  const [daira, setDaira] = useState();
  const [commune_id, setCommune] = useState();
  const wilaya = useSelector((state) => state.wilaya.wilaya);
  const Daira = useSelector((state) => state.wilaya.daira);
  const Commune = useSelector((state) => state.wilaya.commune);
  const { message } = useSelector((state) => state.message);
  // const { Settings } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const wilayas = [];
  const dairas = [];
  const communes = [];
  const CarOwnerPhone = useRef();
  const OwnerName = useRef();
  const CarYear = useRef();
  const CarBrand = useRef();
  const { state } = useLocation();

  moment.locale("fr");

  useEffect(() => {
    dispatch(getWilaya({ lang: currentLanguageCode }));
  }, [dispatch, currentLanguageCode]);

  useEffect(() => {
    city && dispatch(getDaira({ city: city, lang: currentLanguageCode }));
  }, [dispatch, city, currentLanguageCode]);

  useEffect(() => {
    daira && dispatch(getCommune({ daira: daira, lang: currentLanguageCode }));
  }, [dispatch, daira, currentLanguageCode]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  wilaya.map((e) =>
    wilayas.push({ value: e.wilaya_code, label: e.wilaya_name })
  );
  Daira.map((e) => dairas.push({ value: e.value, label: e.daira_name }));
  Commune.map((e) => communes.push({ value: e.id, label: e.commune_name }));

  const handleCity = (e) => {
    setCity(e.value);
  };

  const handleDaira = (e) => {
    setDaira(e.value);
  };
  const handleCommune = (e) => {
    setCommune(e.value);
  };

  const CustomTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: "#f8e9b8",
      primary50: "#e6b412",
      primary: "#e6b412",
    },
  });

  const handleRequest = async () => {
    setShow(true);
    await dispatch(
      sendRequest({
        CarOwnerPhone: CarOwnerPhone.current.value,
        OwnerName: OwnerName.current.value,
        CarYear: CarYear.current.value,
        CarBrand: CarBrand.current.value,
        commune_id: commune_id,
        lang: currentLanguageCode,
        token: token,
        id: id,
        planId: state.id,
      })
    );
    setTimeout(() => {
      setShow(false);
    }, 7000);

    // console.log(state.id);
    // console.log(CarBrand.current.value);
    // console.log(CarNumber.current.value);
    // console.log(OwnerName.current.value);
    // console.log(CarOwnerPhone.current.value);
  };

  message &&
    message[1] === "success" &&
    window.open("/client/commands", "_self");

  if (getUserType() !== "client") return <Navigate to="/login" />;
  return (
    <div>
      <NavBar />
      <div className="max-w-xs md:max-w-screen-md mt-5 lg:max-w-screen-lg lg:px-20 lg:inset-x-0 mx-auto">
        <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div className="flex items-center justify-center my-8 w-full md:px-4 col-span-1">
            <div className="block w-full">
              <p className="font-Saira text-center my-4 mb-6 font-extrabold text-4xl text-darkGray ">
                {t("CarInformationDetails")}
              </p>
              <p className="text-md mb-2">
                {t("ObligationFields").split(" * ")[0]}
                <span className="text-red-500"> * </span>
                {t("ObligationFields").split(" * ")[1]}
              </p>
              <div className="mb-3 ">
                <label
                  htmlFor="phone"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">* </span>
                  {t("PhoneNumberOwner")}
                </label>
                <input
                  type="number"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="phone"
                  ref={CarOwnerPhone}
                  placeholder={t("PhoneNumberOwner")}
                />
              </div>
              {/* //----- */}
              <div className="mb-3 hidden">
                <label
                  htmlFor="Nom"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  {t("Owner_name")}
                </label>
                <input
                  type="text"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="fullname"
                  ref={OwnerName}
                  placeholder={t("Owner_name")}
                />
              </div>
              <div className="mb-3 ">
                <label
                  htmlFor="Nom"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  {t("Car_Year")}
                </label>
                <input
                  type="number"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="carYear"
                  placeholder={t("Car_Year")}
                  ref={CarYear}
                />
              </div>
              <div className="mb-3 ">
                <label
                  htmlFor="VehicleType"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">* </span>
                  {t("VehicleType")}
                </label>
                <input
                  type="text"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="fullname"
                  placeholder={t("VehicleType")}
                  ref={CarBrand}
                />
              </div>
              {/* /// --------- */}

              <div className="mb-3">
                <label
                  htmlFor="fullname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("TheState")}
                </label>
                <Select
                  className="font-Saira font"
                  theme={CustomTheme}
                  placeholder={t("TheState")}
                  options={wilayas}
                  onChange={handleCity}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="fullname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("Daira")}
                </label>
                <Select
                  className="font-Saira font"
                  theme={CustomTheme}
                  placeholder={t("Daira")}
                  options={dairas}
                  value={dairas.filter((d) => d.value === daira)[0] || ""}
                  onChange={handleDaira}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="fullname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("Municipal")}
                </label>
                <Select
                  className="font-Saira font"
                  theme={CustomTheme}
                  placeholder={t("Municipal")}
                  options={communes}
                  value={
                    dairas.filter((d) => d.value === daira)[0] === undefined
                      ? ""
                      : communes.filter((d) => d.value === commune_id)[0] || ""
                  }
                  onChange={handleCommune}
                  required
                />
              </div>

              <div className="flex justify-between mb-10">
                <Button
                  custom="text-xl px-10 mt-5 block"
                  onClick={handleRequest}
                  title={t("Continue")}
                />
                {/* <Button
                  onClick={() =>
                    window.open(
                      "http://api.whatsapp.com/send?phone=$".replace(
                        "$",
                        Settings && Settings?.social_media?.whatsapp
                      )
                    )
                  }
                  custom="px-8 mt-5 bg-lightGray text-white"
                  title={t("GetSupport")}
                /> */}
              </div>
              {message ? (
                message[1] === "success" ? (
                  show && <Alert type={message[1]} content={message[0]} />
                ) : (
                  show && <Alert type={message[1]} content={message[0]} />
                )
              ) : (
                <></>
              )}
            </div>
          </div>
          <ResquestImg className="col-span-1 hidden md:block my-8" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Request;
