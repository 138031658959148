import React from 'react'
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

function ImageViewer(props) {
  return (
    <div>{props.url && 
    <Lightbox
        open={props.open}
        close={() => props.setOpen(false)}
        slides={[
          {
            src: props.url,
          },
          
        ]}
        plugins={[Zoom]}
        render={{
          buttonPrev: () => null ,
          buttonNext:  () => null
        }}
 
      />} 
      
      </div>
  )
}

export default ImageViewer