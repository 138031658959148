import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { COLUMNS } from "./columns";
import Button from "../../../../components/Button.compenent";
import "./table.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar-dz";
import Modal from "../Modal.agent.authagent";
import {
  deactivateAgent,
  getAgents,
} from "../../../../redux/reducers/adminSlice";
import { getToken, getUserId, getUserType } from "../../../../utils/helpers";
import { clearMessage } from "../../../../redux/reducers/message";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";

function Table() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const id = JSON.parse(localStorage.getItem("user"))._id;
  const columns = useMemo(() => COLUMNS, []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { Agents } = useSelector((state) => state.admin);
  const { isLoading } = useSelector((state) => state.admin);
  const [updated, setUpdated] = useState(false);

  moment.locale(
    (currentLanguageCode === "ar" && "ar-dz") || currentLanguageCode
  );

  const data = useMemo(
    () =>
      Agents.map((a, index) => {
        return {
          fullname: Agents[index].last_name + " " + Agents[index].first_name,
          phonenumber: Agents[index].phone,
          email: Agents[index].email,
          Address: Agents[index].city,
          id: Agents[index]._id,
        };
      }),
    [Agents]
  );

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getAgents({
        id: id,
        token: getToken(),
        lang: currentLanguageCode,
        type: getUserType(),
      })
    );
    setUpdated(false);
  }, [dispatch, currentLanguageCode, id, updated]);

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  const { globalFilter } = state;

  return (
    <>
      <div className="p-2 md:m-4 w-full">
        <div className="">
          <p className="font-Saira text-center font-extrabold text-4xl text-darkGray ">
            {t("Agents")}
          </p>
          <div className="flex justify-between mb-3">
            <div className=" flex items-stretch md:w-96 w-full mb-4">
              <input
                type="search"
                className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                aria-describedby="button-addon2"
              />
              <button
                className="inline-block px-6 py-2.5 bg-yellowPrimary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-yellow-500 hover:shadow-lg focus:bg-yellow-500  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                type="button"
                id="button-addon2"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  className="w-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
              </button>
            </div>
            <div>
              <Button
                title={t("Add")}
                onClick={() => navigate("/auth-agent/agents/add")}
              />
            </div>
          </div>
          {!isLoading ? (
            <div className="overflow-x-scroll  h-full md:overflow-hidden">
              <>
                <tr>
                  {t("Total")} {rows.length}
                </tr>
              </>
              <table {...getTableBodyProps()} className="font-Saira md:w-full ">
                <thead id="tfoot">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          id="tfoot"
                          {...column.getHeaderProps(
                            !(column.Header === "") &&
                              column.getSortByToggleProps()
                          )}
                        >
                          {t(column.render("Header"))}
                          {!(column.Header === "") &&
                            (column.isSorted ? (
                              column.isSortedDesc ? (
                                "▼"
                              ) : (
                                "▲"
                              )
                            ) : (
                              <>
                                <span>▲</span>
                                <span className="-mx-2">▼</span>
                              </>
                            ))}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr id="tr" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td id="td" {...cell.getCellProps()}>
                              {(cell.column.Header === "" && (
                                <div className="flex justify-evenly">
                                  <Modal
                                    title={t("View")}
                                    Agents={Agents[cell.row.index]}
                                  />

                                  {
                                    // Agents[cell.row.index].status.active ? (
                                    <Button
                                      title={t("Deactivate")}
                                      custom={` bg-red-500 text-white mx-1`}
                                      onClick={() => {
                                        window.confirm(
                                          t("Confirm_alert_admin") +
                                            " " +
                                            row.original.fullname
                                        ) &&
                                          dispatch(
                                            deactivateAgent({
                                              id: getUserId(),
                                              agent_id: row.original.id,
                                              token: getToken(),
                                              type: getUserType(),
                                            })
                                          ) &&
                                          setUpdated(true);
                                      }}
                                    />
                                    // )
                                    //  : (
                                    //   <Button
                                    //     title={t("Activate")}
                                    //     custom={` bg-black text-white mx-1`}
                                    //     onClick={() => {
                                    //       window.confirm(
                                    //         t("Confirm_alert_admin_activate") +
                                    //           " " +
                                    //           row.original.fullname
                                    //       ) &&
                                    //         dispatch(
                                    //           activateAgent({
                                    //             id: getUserId(),
                                    //             agent_id: row.original.id,
                                    //             token: getToken(),
                                    //             type: getUserType(),
                                    //           })
                                    //         ) &&
                                    //         setUpdated(true);
                                    //     }}
                                    //   />
                                    // )
                                  }
                                </div>
                              )) ||
                                cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </>
  );
}

export default Table;
