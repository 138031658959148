import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice";
import directionReducer from "./reducers/directionSlice";
import wilayaReducer from "./reducers/wilayaSlice";
import messageReducer from "./reducers/message";
import profileReducer from "./reducers/profileSlice";
import requestReducer from "./reducers/requestSlice";
import adminReducer from "./reducers/adminSlice";
import authAgentReducer from "./reducers/authAgentSlice";
import notificationReducer from "./reducers/notificationSlice";
import statisticsReducer from "./reducers/statSlice";
import bloggerReducer from "./reducers/bloggerSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    direction: directionReducer,
    wilaya: wilayaReducer,
    message: messageReducer,
    profile: profileReducer,
    request: requestReducer,
    admin: adminReducer,
    authAgent: authAgentReducer,
    notification: notificationReducer,
    statistics: statisticsReducer,
    blogger: bloggerReducer,
  },
});
