import React, { useEffect } from "react";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getNotificationContent } from "../../../redux/reducers/notificationSlice";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar-dz";
import Cookies from "js-cookie";

function NotificationPage() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { id } = useParams();
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.notification);

  moment.locale(
    (currentLanguageCode === "ar" && "ar-dz") || currentLanguageCode
  );

  // console.log(notification);

  useEffect(() => {
    dispatch(getNotificationContent({ id }));
  }, [dispatch, id]);

  return (
    <div className="m-4 md:w-full min-h-screen font-Saira">
      <p className="font-Saira text-center font-extrabold text-4xl text-darkGray ">
        {notification.subject}
      </p>

      <div className="mt-10 bg-gray-200 w-full rounded-lg p-4">
        <p className="font-semibold ">{notification.description}</p>
        <p className="font-semibold mt-10">
          {moment(notification.createdAt).format("LLLL")}
        </p>
      </div>
    </div>
  );
}

export default NotificationPage;
