import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import SideBar from "../../components/SideBar";
import { getUserType } from "../../utils/helpers";

function Profile() {
  if (getUserType() !== "admin") return <Navigate to="/login" />;
  return (
    <div className="md:flex">
      <div className="hidden md:block">
        <SideBar />
      </div>
      <Outlet />
    </div>
  );
}

export default Profile;
