import React, { useState, useEffect } from 'react';

function ImageWithHeaders({ src, headers, ...props }) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    async function fetchImage() {
      const response = await fetch(src, { headers });
      const blob = await response.blob();
      setImageUrl(URL.createObjectURL(blob));
    }
    fetchImage();
  }, [src, headers]);

  return <img src={imageUrl} {...props} alt=""/>;
}

export default ImageWithHeaders;