/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import Select from "react-select";
import Button from "../../components/Button.compenent";
import { ReactComponent as ResquestImg } from "../../assets/Information.svg";
import { Navigate } from "react-router-dom";
import { getToken, getUserType } from "../../utils/helpers";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getWilaya } from "../../redux/reducers/wilayaSlice";
import { clearMessage, setMessage } from "../../redux/reducers/message";
import {
  changeEmail,
  confirmEmail,
  getProfile,
  resetPassword,
  upadteProfilePic,
  updateProfile,
} from "../../redux/reducers/profileSlice";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar-dz";
import { Transition } from "@headlessui/react";
import Alert from "../../components/Alert";
import { resendCodeConfirmation } from "../../redux/reducers/authSlice";
import Compressor from "compressorjs";

function Information() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const token = JSON.parse(localStorage.getItem("jwt"));
  const id = JSON.parse(localStorage.getItem("user"))._id;
  const { t } = useTranslation();
  const first_name = useRef();
  const last_name = useRef();
  const birth_date = useRef();
  const phone = useRef();
  const email = useRef();
  const R_password = useRef();
  const password = useRef();
  const code = useRef();
  const Old_password = useRef();
  const [city, setCity] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const [isLoginPrivacy, setIsLoginPivacy] = useState(false);
  const [isPhoneUpdate, setIsPhoneUpdate] = useState(false);
  const [isEmailUpdate, setIsEmailUpdate] = useState(false);
  const [res, setRes] = useState({});
  const [show, setShow] = useState(true);
  const wilaya = useSelector((state) => state.wilaya.wilaya);
  const { message } = useSelector((state) => state.message);
  const user = useSelector((state) => state.profile.user);
  const dir = useSelector((state) => state.direction.dir.dir);
  const dispatch = useDispatch();
  const wilayas = [];

  moment.locale(
    (currentLanguageCode === "ar" && "ar-dz") || currentLanguageCode
  );

  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    dispatch(getWilaya({ lang: currentLanguageCode }));
  }, [dispatch, currentLanguageCode, city]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getProfile({
        id: id,
        token: getToken(),
        lang: currentLanguageCode,
        type: getUserType(),
      })
    );
  }, [dispatch, currentLanguageCode]);

  wilaya &&
    wilaya.map((e) =>
      wilayas.push({ value: e.wilaya_code, label: e.wilaya_name })
    );

  // const handleCity = (e) => {
  //   setCity(e.value);
  // };

  // const CustomTheme = (theme) => ({
  //   ...theme,
  //   borderRadius: 0,
  //   colors: {
  //     ...theme.colors,
  //     primary25: "#f8e9b8",
  //     primary50: "#e6b412",
  //     primary: "#e6b412",
  //   },
  // });
  // console.log("Outside dairas ", dairas);
  useEffect(() => {
    (async function waitingLoadWilayas() {
      await wilaya;
      // console.log("UseEffect dairas", dairas);
      try {
        // console.log(city);
        if (city === undefined) {
          setCity(wilayas.filter((w) => w.label === user.city)[0].value);
        } else {
          setCity(city);
        }
        // setCity(city || wilayas.filter((w) => w.label === user.city)[0].value);
      } catch (error) {}
    })();
  }, [wilayas, wilaya, city, user.city]);

  const handleCompressedUpload = (e, name) => {
    Object.keys(e.target.files).map(
      (f, index) =>
        new Compressor(e.target.files[index], {
          quality: 0.7,
          success: (compressedResult) => {
            // console.log(compressedResult);
            compressedResult.size < 5242880 &&
              setRes({ ...res, [name]: { compressedResult, name } });
          },
        })
    );
  };

  const handleSubmit = () => {
    setShow(true);
    const formData = new FormData();
    Object.keys(res).map((e) =>
      formData.append("image", res[e].compressedResult, res[e].name)
    );
    dispatch(
      upadteProfilePic({
        formData,
      })
    );
  };

  const handleUpdateInfo = () => {
    dispatch(
      updateProfile({
        first_name: first_name.current.value,
        last_name: last_name.current.value,
        birth_date: moment(birth_date.current.value).format("YYYY-MM-DD"),
        commune_id: city,
        id: id,
        token: token,
        lang: currentLanguageCode,
        type: getUserType(),
      })
    );
    // console.log(message);
  };

  const handleRestPassword = () => {
    if (password.current.value === R_password.current.value) {
      dispatch(
        resetPassword({
          new_password: password.current.value,
          old_password: Old_password.current.value,
          token: token,
          id: id,
          lang: currentLanguageCode,
          type: getUserType(),
        })
      );
    } else dispatch(setMessage([t("passwordNotMatch"), "Failed"]));
  };
  /// Email  ------------------------
  const handleChangeEmail = () => {
    dispatch(
      changeEmail({
        email: email.current.value,
        id: id,
        token: token,
        lang: currentLanguageCode,
        type: getUserType(),
      })
    );
  };

  const handleConfirmEmail = () => {
    dispatch(
      confirmEmail({
        code: code.current.value,
        id: id,
        token: token,
        lang: currentLanguageCode,
        type: getUserType(),
      })
    );
  };

  const handleResendCode = () => {
    setSeconds(60);
    dispatch(
      resendCodeConfirmation({
        userType: getUserType(),
        id,
        token,
        lang: currentLanguageCode,
      })
    );
    setShow(true);
  };

  useEffect(() => {
    try {
      setShow(true);
      message[1] === "success" &&
        message[2] === "changed" &&
        isEmailUpdate === false &&
        setIsEmailUpdate(true);
      message[1] === "Failed" &&
        message[2] === "notChanged" &&
        isEmailUpdate === true &&
        setIsEmailUpdate(false);
      message[1] === "success" &&
        message[2] === "confirmed" &&
        window.location.reload(false);
      setTimeout(() => {
        setShow(false);
      }, 7000);
    } catch (error) {}
  }, [message]);

  useEffect(() => {
    try {
      if (
        (message[1] === "success" && message[2] === "changed") ||
        (message[1] === "Failed" && message[2] === "notConfirmed")
      ) {
        if (seconds > 0) {
          let sec = seconds - 1;
          setTimeout(
            () => setSeconds(sec.toString().length === 1 ? "0" + sec : sec),
            1000
          );
        } else {
          setSeconds("00");
        }
      }
    } catch (error) {}
  });

  if (getUserType() !== "blogger") return <Navigate to="/login" />;
  return (
    <div>
      <div className="max-w-md md:max-w-screen-md mt-5 lg:max-w-screen-lg lg:px-20 lg:inset-x-0 mx-auto">
        <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div className="flex items-center justify-center  w-full md:px-4 col-span-1">
            <div className="block w-full">
              <p className="font-Saira text-center my-4 mb-6 font-extrabold text-4xl text-darkGray ">
                {t("Profile")}
              </p>
              <div className="my-4 px-4">
                <Button
                  onClick={() => {
                    message[1] === "success" && window.location.reload(false);
                    setIsOpen(!isOpen);
                  }}
                  title={`${!isOpen ? t("Edit") : t("Back")}  `}
                />
              </div>
              {/* / */}

              <div className="px-6 md:px-0">
                <Transition
                  show={isOpen}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                >
                  <div className="mb-3 ">
                    <label
                      htmlFor="Firstname"
                      className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                    >
                      {t("Firstname")}
                    </label>
                    <input
                      type="text"
                      className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                      id="firstname"
                      ref={first_name}
                      placeholder={t("Firstname")}
                      defaultValue={user.first_name}
                      required
                    />
                  </div>
                  <div className="mb-3 ">
                    <label
                      htmlFor="Famillyname"
                      className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                    >
                      {t("Famillyname")}
                    </label>
                    <input
                      type="text"
                      className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                      id="Famillyname"
                      ref={last_name}
                      defaultValue={user.last_name}
                      placeholder={t("Famillyname")}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="bithdate"
                      className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                    >
                      {t("Birth_date")}
                    </label>
                    <input
                      type="date"
                      className={`${
                        dir === "rtl" ? "text-right" : "text-left"
                      } block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none`}
                      ref={birth_date}
                      defaultValue={moment(user.birth_date).format(
                        "YYYY-MM-DD"
                      )}
                      placeholder={t("Birth_date")}
                      required
                    />
                  </div>
                  {/* <div className="mb-3">
                    <label
                      htmlFor="fullname"
                      className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                    >
                      {t("TheState")}
                    </label>
                    <Select
                      className="font-Saira font"
                      theme={CustomTheme}
                      placeholder={t("TheState")}
                      options={wilayas}
                      value={
                        (city && wilayas.filter((d) => d.value === city)[0]) ||
                        wilayas.filter((d) => d.value === user.city)[0] ||
                        ""
                      }
                      onChange={handleCity}
                      required
                    />
                  </div> */}

                  {/* ----- image */}
                  <div className="mb-3 ">
                    <label
                      htmlFor="formFile"
                      className="form-label font-normal font-Saira inline-block text-darkGray"
                    >
                      {t("UpdateProfileImg")}
                    </label>
                    <div className="flex">
                      <input
                        className="w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                        accept="image/png, image/jpg, image/jpeg"
                        type="file"
                        id="formFile"
                        onChange={handleCompressedUpload}
                      />
                      <Button
                        custom="mx-2"
                        title={t("Update")}
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                  <div className="flex w-80 mx-auto justify-between mb-10">
                    {isOpen && (
                      <Button
                        onClick={handleUpdateInfo}
                        custom="text-xl mx-2 w-full px-10 mt-5 block"
                        title={t("Update")}
                      />
                    )}
                  </div>
                  {message && message[0] !== undefined ? (
                    show && <Alert type={message[1]} content={message[0]} />
                  ) : (
                    <></>
                  )}
                </Transition>
              </div>

              <>
                <Transition
                  show={!isOpen}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                >
                  {/* /// ----------------------------------------*/}
                  <div className="flex flex-col w-full">
                    <div className="sm:-mx-6 lg:-mx-8 inset-0">
                      <div className="py-2 inline-block w-full px-4 sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                          <table className="w-full font-Saira">
                            <tbody>
                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Firstname")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {user.first_name}
                                </td>
                              </tr>
                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Famillyname")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {user.last_name}
                                </td>
                              </tr>
                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Birth_date")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {user.birth_date &&
                                    moment(user.birth_date).format("LL")}
                                </td>
                              </tr>
                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Address")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 ">
                                  {user.city}
                                </td>
                              </tr>
                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block mt text-darkGray">
                                    {t("PhoneNumber")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {user.phone}
                                </td>
                              </tr>
                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Email")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className="text-sm text-gray-900 font-normal py-4 ">
                                  {user.email}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
                {/* /// -------------------------------------------- */}
              </>
              <hr />
              <br />
            </div>
          </div>
          <ResquestImg className="col-span-1 m-4 hidden md:block" />
        </div>

        <div className="mx-5">
          <p className="font-medium font-Saira text-lg text-darkGray cursor-pointer mb-5">
            {t("Privacy_settings")}
          </p>

          <>
            <div className="block">
              <Button
                onClick={() => {
                  setIsLoginPivacy(!isLoginPrivacy);
                  isPasswordOpen && setIsPasswordOpen(!isPasswordOpen);
                }}
                title={t("Login_privacy")}
                custom={`text-white mb-4 ${
                  isLoginPrivacy ? "bg-yellowDark" : "bg-darkGray"
                }`}
              />
            </div>
            {
              <Transition
                show={isLoginPrivacy}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div>
                  <div className="mb-3">
                    <label
                      htmlFor="phone"
                      className="form-label font-normal font-Saira inline-block mt text-darkGray"
                    >
                      {t("PhoneNumber")}
                    </label>
                    <div className="flex">
                      <input
                        type="number"
                        className="block w-fit px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                        id="phone"
                        ref={phone}
                        defaultValue={user.phone}
                        placeholder={t("PhoneNumber")}
                        required
                      />
                      <button
                        onClick={() => setIsPhoneUpdate(!isPhoneUpdate)}
                        className="mx-4 p-1 my-1 w-fit bg-darkGray transition ease-in hover:bg-yellowDark rounded-lg text-white"
                      >
                        {t("Update")}
                      </button>
                    </div>
                    {isPhoneUpdate && (
                      <div className="flex">
                        <input
                          type="number"
                          className="block w-2/3 mt-1 px-3 py-1.5 text-sm font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                          id="otpPhone"
                          ref={phone} //Otp code
                          placeholder={t("EnterTheCode")}
                          required
                        />
                        <button
                          onClick={() => {}}
                          className="mx-4 p-1 my-1 w-fit bg-darkGray transition ease-in hover:bg-yellowDark rounded-lg text-white"
                        >
                          {t("Submit")}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="mb-3 ">
                    <label
                      htmlFor="email"
                      className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                    >
                      {t("Email")}
                    </label>
                    <div className="flex">
                      <input
                        type="email"
                        className="block w-fit px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                        id="email"
                        ref={email}
                        defaultValue={user.email}
                        placeholder={t("Email")}
                        required
                      />
                      {/* {console.log(
                              message &&
                                message[1] === "success" &&
                                message[2] === "send" &&
                                true
                            )} */}
                      <button
                        onClick={handleChangeEmail}
                        disabled={
                          (message &&
                            message[1] === "success" &&
                            message[2] === "changed" &&
                            true) ||
                          false
                        }
                        className="mx-4 p-1 my-1 w-fit bg-darkGray transition ease-in hover:bg-yellowDark rounded-lg text-white"
                      >
                        {t("Update")}
                      </button>
                    </div>
                    {isEmailUpdate && (
                      <>
                        <div className="flex">
                          <input
                            type="number"
                            className="block w-2/3 mt-1 px-3 py-1.5 text-sm font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                            id="Code"
                            ref={code} //Otp code
                            placeholder={t("EnterTheCode")}
                            required
                          />
                          <button
                            onClick={handleConfirmEmail}
                            className="mx-4 p-1 my-1 w-fit bg-darkGray transition ease-in hover:bg-yellowDark rounded-lg text-white"
                          >
                            {t("Submit")}
                          </button>
                        </div>
                        <p className="text-sm">
                          {seconds === "00" ? (
                            <>
                              <button
                                onClick={handleResendCode}
                                className="text-yellowPrimary cursor-pointer hover:text-yellowDark font-normal"
                              >
                                Resend code
                              </button>
                            </>
                          ) : (
                            <>
                              Resend code
                              <span className="text-blue-500">
                                00:{seconds}
                              </span>
                            </>
                          )}
                        </p>
                        {message &&
                        (message[2] === "changed" ||
                          (message[1] === "Failed" &&
                            message[2] === "notConfirmed")) ? (
                          show && (
                            <Alert type={message[1]} content={message[0]} />
                          )
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    <div className="mt-2">
                      {message && message[2] === "notChanged" ? (
                        show && <Alert type={message[1]} content={message[0]} />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  {/* <div className="flex w-80 mx-auto justify-between mb-10">
                          <Button
                            custom="text-xl mx-2 w-full px-10 mt-5 block"
                            title={t("Update")}
                          />
                        </div> */}
                </div>
              </Transition>
            }
          </>
          <>
            <Button
              onClick={() => {
                setIsPasswordOpen(!isPasswordOpen);
                isLoginPrivacy && setIsLoginPivacy(!isLoginPrivacy);
              }}
              custom={`text-white mb-4 ${
                isPasswordOpen ? "bg-yellowDark" : "bg-darkGray"
              }`}
              title={t("Edit_Password")}
            />

            {
              <Transition
                show={isPasswordOpen}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div>
                  <div className="mb-3">
                    <label className="form-label font-normal font-Saira inline-block mb-1 mt text-darkGray">
                      <span className="text-red-500">*</span> {t("OldPassword")}
                    </label>
                    <input
                      type="password"
                      ref={Old_password}
                      className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                      id="oldpass"
                      placeholder={t("OldPassword")}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label font-normal font-Saira inline-block mb-1 mt text-darkGray">
                      <span className="text-red-500">*</span> {t("NewPassword")}
                    </label>
                    <input
                      type="password"
                      ref={password}
                      className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                      id="newpass"
                      placeholder={t("NewPassword")}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label font-normal font-Saira inline-block mb-1 mt text-darkGray">
                      <span className="text-red-500">*</span>{" "}
                      {t("RepeatPassword")}
                    </label>
                    <input
                      type="password"
                      ref={R_password}
                      className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                      id="Rpass"
                      placeholder={t("RepeatPassword")}
                      required
                    />
                  </div>
                  {message && message[0] !== undefined ? (
                    show && <Alert type={message[1]} content={message[0]} />
                  ) : (
                    <></>
                  )}
                  <div className="flex w-80 mx-auto justify-between mb-10">
                    <Button
                      onClick={handleRestPassword}
                      custom="text-xl mx-2 w-full px-10 mt-5 block"
                      title={t("Update")}
                    />
                  </div>
                </div>
              </Transition>
            }
          </>
        </div>
      </div>
    </div>
  );
}

export default Information;
