import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RapportTable from "../../components/rapport.table";
import { getReportDone } from "../../redux/reducers/requestSlice";

function Report() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { Report } = useSelector((state) => state.request);
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      getReportDone({
        command_id: id,
        lang: currentLanguageCode,
      })
    );
  }, [dispatch, id, currentLanguageCode]);

  return (
    <div className="w-full md:mt-8">
      <div className="mx-6 md:max-w-screen-md mt-5 lg:max-w-screen-lg lg:px-10 lg:inset-x-0">
        <p className="font-Saira my-4 mb-6 font-extrabold text-4xl text-darkGray ">
          {console.log("--", Report)}
          {t("Report")}
        </p>
        <div className="md:grid md:gap-12 md:grid-cols-2 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
            {Report && (
              <RapportTable
                title="car_information"
                data={Report.car_information.map((o) => {
                  return o;
                })}
              />
            )}
          </div>
          <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
            {Report && (
              <RapportTable
                title="interior"
                data={Report.interior.map((o) => {
                  return o;
                })}
              />
            )}
          </div>

          <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
            {Report && (
              <RapportTable
                title="exterior"
                data={Report.exterior.map((o) => {
                  return o;
                })}
              />
            )}
          </div>
          <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
            {Report && (
              <RapportTable
                title="mechanical"
                data={Report.mechanical.map((o) => {
                  return o;
                })}
              />
            )}
          </div>
          <div className="mx-auto mb-10">
            {Report && <ReactPlayer url={Report.video_url} />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Report;
