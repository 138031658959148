import axios from "axios";
import { getToken, getUserId, getUserType } from "../utils/helpers";

const API_URL = process.env.REACT_APP_API;

const getNotifications = () => {
  return axios.get(API_URL + getUserType() + "/notifications/" + getUserId(), {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const getNotificationContent = (id) => {
  return axios.get(
    API_URL + getUserType() + "/notification/" + getUserId() + "/" + id,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const deleteNotification = (id) => {
  return axios.delete(
    API_URL + getUserType() + "/notification/" + getUserId() + "/" + id,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const notificationService = {
  getNotifications,
  getNotificationContent,
  deleteNotification,
};

export default notificationService;
