import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { COLUMNS } from "./columns";
// import Select from "react-select";
import "./table.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar-dz";
// import Modal from "../Modal.client.admin";
import { clearMessage } from "../../../../redux/reducers/message";
import {
  cancelCommand,
  getCommands,
  sendConfirmCommand,
} from "../../../../redux/reducers/requestSlice";
// import { getAgentsNames } from "../../../../redux/reducers/authAgentSlice";
import Button from "../../../../components/Button.compenent";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";

function Table() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const columns = useMemo(() => COLUMNS, []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { commands } = useSelector((state) => state.request);
  const { isLoading } = useSelector((state) => state.request);
  // const { Agents_names } = useSelector((state) => state.authAgent);
  const [checked, setChecked] = useState({});
  const [updated, setUpdated] = useState(true);
  // const [Agent, setAgent] = useState({});
  // const Agents = [];

  const status = {
    "01": "confirm_command",
    "02": "contacting_car_owner",
    "03": "payment_in_process",
    "04": "payment_in_process",
    "05": "AssignVerfication",
    "06": "Report",
    "07": "",
    "08": "",
  };
  // const status = {
  //   "01": ["confirm_in_process", "confirm_command"],
  //   "02": ["confirm_in_process", "contacting_car_owner"],
  //   "03": ["AssignPaymentToAgent", "payment_in_process"],
  //   "04": ["payment", "payment_in_process"],
  //   "05": ["verfication_in_process", "AssignVerfication"],
  //   "06": ["verfication_in_process", "Report"],
  //   "07": ["Done", "Done"],
  // };

  moment.locale(
    (currentLanguageCode === "ar" && "ar-dz") || currentLanguageCode
  );

  const data = useMemo(
    () =>
      commands.map((command, index) => {
        return {
          command_id: command._id.substring(0, 10),
          car_name: command.car_name,
          car_owner_number: command.seller_phone,
          address: command.address || "N/A",
          agent: command.agent_seller || "N/A",
          auth_agent: command.auth_agent_seller || "N/A",
          client_full_name: command.client_name || "N/A",
          client_number: command.client_phone,
        };
      }),
    [commands]
  );

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCommands({ lang: currentLanguageCode }));
    setUpdated(false);
    setChecked({});
  }, [dispatch, updated, currentLanguageCode]);

  // Agents_names.map((e) =>
  //   Agents.push({ value: e._id, label: e.first_name + " " + e.last_name })
  // );

  // useEffect(() => {
  //   dispatch(getAgentsNames());
  //   // setUpdated(false);
  // }, [dispatch]);

  // console.log(commands);

  const handleStatus = (e, command) => {
    if (
      window.confirm(
        e.target.value === "yes"
          ? t("confirmation_request_yes")
          : t("confirmation_request_no")
      )
    ) {
      setChecked({ ...checked, [command._id]: e.target.value });
      dispatch(
        sendConfirmCommand({
          command_id: command._id,
          action: e.target.value,
        })
      );
      setUpdated(true);
    }
  };

  // const handleAgent = (e, command_id) => {
  //   setAgent({ ...Agent, [command_id]: e.value });
  // };

  // const handleConfirm = (command_id) => {
  //   dispatch(AssignSellerAgent({ command_id, agent_id: Agent[command_id] }));
  //   setUpdated(true);
  // };

  // const CustomTheme = (theme) => ({
  //   ...theme,
  //   borderRadius: 0,
  //   colors: {
  //     ...theme.colors,
  //     primary25: "#f8e9b8",
  //     primary50: "#e6b412",
  //     primary: "#e6b412",
  //   },
  // });

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  const { globalFilter } = state;

  return (
    <>
      <div className="p-2 md:m-4 w-full">
        <div className="">
          <p className="font-Saira text-center font-extrabold text-4xl text-darkGray ">
            {t("CarsCommands")}
          </p>
          <div className="mb-3">
            <div className=" flex items-stretch w-full md:w-96 mb-4">
              <input
                type="search"
                className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                aria-describedby="button-addon2"
              />
              <button
                className="inline-block px-6 py-2.5 bg-yellowPrimary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-yellow-500 hover:shadow-lg focus:bg-yellow-500  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                type="button"
                id="button-addon2"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  className="w-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>

          {!isLoading ? (
            <div className="overflow-x-scroll overflow-visible md:overflow-visible h-full md:w-11/12 lg:w-full">
              <>
                <tr>
                  {t("Total")} {rows.length}
                </tr>
              </>
              <table {...getTableBodyProps()} className="font-Saira md:w-full">
                <thead id="tfoot">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          id="tfoot"
                          {...column.getHeaderProps(
                            !(column.Header === "Reports") &&
                              column.getSortByToggleProps()
                          )}
                        >
                          {t(column.render("Header"))}
                          {!(column.Header === "Reports") &&
                            (column.isSorted ? (
                              column.isSortedDesc ? (
                                "▼"
                              ) : (
                                "▲"
                              )
                            ) : (
                              <>
                                <span>▲</span>
                                <span className="-mx-2">▼</span>
                              </>
                            ))}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr id="tr" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td id="td" {...cell.getCellProps()}>
                              {(cell.column.Header === "Reports" && (
                                <div>
                                  {/* {console.log(commands[cell.row.index])} */}
                                  <div className="text-center">
                                    {commands[cell.row.index].status !== "06" &&
                                      t(
                                        status[commands[cell.row.index].status]
                                      )}
                                  </div>
                                  {(commands[cell.row.index].status === "01" ||
                                    commands[cell.row.index].status ===
                                      "02") && (
                                    <div className="flex justify-evenly">
                                      <label>
                                        <input
                                          className="mx-2"
                                          type="radio"
                                          value="yes"
                                          checked={
                                            checked[
                                              commands[cell.row.index]._id
                                            ] === "yes"
                                          }
                                          onChange={(e) => {
                                            handleStatus(
                                              e,
                                              commands[cell.row.index]
                                            );
                                          }}
                                        />
                                        {t("yes")}
                                      </label>
                                      <label>
                                        <input
                                          className="mx-2"
                                          type="radio"
                                          value="no"
                                          checked={
                                            checked[
                                              commands[cell.row.index]._id
                                            ] === "no"
                                          }
                                          onChange={(e) => {
                                            handleStatus(
                                              e,
                                              commands[cell.row.index]
                                            );
                                          }}
                                        />
                                        {t("no")}
                                      </label>
                                    </div>
                                  )}
                                  {/* {commands[cell.row.index].status === "09" && (
                                  <div className="flex">
                                    <Select
                                      className="font-Saira w-40"
                                      theme={CustomTheme}
                                      placeholder={t("Agent")}
                                      options={Agents}
                                      onChange={(e) => {
                                        handleAgent(
                                          e,
                                          commands[cell.row.index]._id
                                        );
                                      }}
                                      required
                                    />
                                    <Button
                                      title={t("Confirm")}
                                      onClick={() =>
                                        handleConfirm(
                                          commands[cell.row.index]._id
                                        )
                                      }
                                    />
                                  </div>
                                )} */}
                                  {(commands[cell.row.index].status === "06" ||
                                    commands[cell.row.index].status ===
                                      "07") && (
                                    <div className="flex place-content-center">
                                      <Button
                                        title={
                                          (commands[cell.row.index].status ===
                                            "07" &&
                                            t("Edit.Report")) ||
                                          (commands[cell.row.index].status ===
                                            "06" &&
                                            t("Create.Report"))
                                        }
                                        onClick={() =>
                                          navigate(
                                            "../commands/report/" +
                                              commands[cell.row.index]._id,
                                            {
                                              replace: true,
                                              state: {
                                                plan_id:
                                                  commands[cell.row.index]
                                                    .plan_id,
                                              },
                                            }
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  {commands[cell.row.index].status === "08" && (
                                    <div className="flex place-content-center">
                                      <Button
                                        title={t("View_report")}
                                        onClick={() =>
                                          window.open(
                                            "./commands/get-report/" +
                                              commands[cell.row.index]._id,
                                            "_self"
                                          )
                                        }
                                      />
                                    </div>
                                  )}

                                  {commands[cell.row.index].status !== "08" && (
                                    <div className="mt-1 flex place-content-center">
                                      <Button
                                        title={t("cancel")}
                                        custom={`bg-red-500 text-white mx-1 py-0`}
                                        onClick={async () =>
                                          window.confirm(
                                            t("Confirm_alert_delete_commands")
                                          ) &&
                                          (await dispatch(
                                            cancelCommand({
                                              command_id:
                                                commands[cell.row.index]._id,
                                            })
                                          )) &&
                                          setUpdated(true)
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )) ||
                                cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </>
  );
}

export default Table;
