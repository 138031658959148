import React from 'react'

function Feedback(props) {
  return (<div className='container relative bg-yellowPrimary mx-auto min-h-full w-52 text-base rounded-2xl p-2 mb-4 shadow-lg'>
 
 <div className='flex justify-center'>
   <img src={props.image} alt="feedback" className='-mt-6 self-center' />
 </div>
   <div className='block text-center'>“{props.title}”</div>
  </div>
  )
}

export default Feedback