import axios from "axios";

const API_URL = process.env.REACT_APP_API;

const register = (
  first_name,
  last_name,
  birth_date,
  commune_id,
  phone,
  email,
  password,
  lang
) => {
  return axios.post(API_URL + "client/signup", {
    first_name,
    last_name,
    birth_date,
    commune_id,
    phone,
    email,
    password,
    lang,
  });
};

const login = (email, password, lang) => {
  return axios
    .post(API_URL + "signin", {
      email,
      password,
      lang,
    })
    .then((response) => {
      // console.log("login response", response);
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("jwt", JSON.stringify(response.data.token));
      }
      return response.user;
    });
  //.catch((err) => console.log(err));
};

const confirmation = (userType, id, code, token, lang) => {
  return axios.post(
    API_URL + userType + "/confirm/" + id,
    {
      lang,
      code,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const resendCodeConfirmation = (
  userType,
  id,
  token,
  lang,
  type = "new-email"
) => {
  return axios.post(
    API_URL +
      JSON.parse(localStorage.getItem("user")).type +
      "/resend-confirm/" +
      id,
    {
      lang,
      type: type,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const newPassword = (password, token, lang) => {
  return axios.post(API_URL + "reset-password", {
    password,
    token,
    lang,
  });
};

const restPassword = (email, lang) => {
  return axios.post(API_URL + "reset", {
    email,
    lang,
  });
};

const checkToken = (token) => {
  return axios.get(API_URL + "check-token/" + token);
};

const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("jwt");
  window.location.reload(false);
  window.open("/", "_self");
};

const authService = {
  register,
  login,
  logout,
  confirmation,
  newPassword,
  restPassword,
  checkToken,
  resendCodeConfirmation,
};

export default authService;
