import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthService from "../../Service/auth.service";
import { setMessage } from "./message";

const user = JSON.parse(localStorage.getItem("user"));

export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    try {
      const data = await AuthService.login(
        payload.email,
        payload.password,
        payload.lang
      );
      return { user: data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthService.register(
        payload.first_name,
        payload.last_name,
        payload.birth_date,
        payload.commune_id,
        payload.phone,
        payload.email,
        payload.password,
        payload.lang
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      // console.log("log error register ", error.response);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

export const confirmation = createAsyncThunk(
  "auth/confirmation",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthService.confirmation(
        payload.userType,
        payload.id,
        payload.code,
        payload.token,
        payload.lang
      );
      thunkAPI.dispatch(setMessage(response.status));
      return response.status;
    } catch (error) {
      // console.log("log error register ", error.response);
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resendCodeConfirmation = createAsyncThunk(
  "auth/resendCodeConfirmation",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthService.resendCodeConfirmation(
        payload.userType,
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success", "send"]));
      return response.status;
    } catch (error) {
      // console.log("log error register ", error.response);
      thunkAPI.dispatch(
        setMessage([error.response.data.err, "Failed", "notSend"])
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const newPassword = createAsyncThunk(
  "auth/newPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthService.newPassword(
        payload.password,
        payload.token,
        payload.lang
      );
      thunkAPI.dispatch(setMessage(response.status));
      // console.log("slice ", response);
      return response.status;
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const restPassword = createAsyncThunk(
  "auth/restPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthService.restPassword(
        payload.email,
        payload.lang
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.data;
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const checkToken = createAsyncThunk(
  "auth/checkToken",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthService.checkToken(payload.token);
      // console.log("Slice", response.data.msg);
      thunkAPI.dispatch(setMessage(response.data.msg));
      return response.data;
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
