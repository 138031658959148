import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import "flag-icon-css/css/flag-icons.min.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../Button.compenent";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/reducers/authSlice";
import { getUserType } from "../../utils/helpers";
import { HashLink as Link } from "react-router-hash-link";
import { getNotifications } from "../../redux/reducers/notificationSlice";
import { Badge } from "@mui/material";

export default function MobileDropdown(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const name = isAuth && JSON.parse(localStorage.getItem("user")).first_name;
  const pathname = window.location.pathname;
  const { notifications } = useSelector((state) => state.notification);
  const { dir } = useSelector((state) => state.direction.dir);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const unReadNotifCount = notifications.filter(
    (notification) => notification.isRead === false
  ).length;

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div>
      <Menu as="div" className="relative w-full inline-block text-left z-50 ">
        <div>
          <Menu.Button className="inline-flex mt-3 w-full justify-center rounded-md hover:opacity-70 text-white  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <span
              className={`${props.nameHide} flex font-Saira font-medium items-center mx-2`}
            >
              {t("Hi")},{name}
            </span>
            {props.content}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute origin-top-right ${props.menuDir} divide-y divide-gray-100 rounded-md border-2 bg-lightGray border-darkGray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
            style={{ height: props.height, overflowY: props.overflowY }}
          >
            <div className={`${props.width}`}>
              {/* {console.log(props.menuItems)} */}
              {Object.keys(props.menuItems).map((item) => (
                <div key={item} className="px-1 ">
                  {getUserType() === "admin" &&
                  JSON.parse(localStorage.getItem("user")).role !==
                    "main_admin" &&
                  (props.menuItems[item] === "/admin/settings" ||
                    props.menuItems[item] === "/admin/s-admin") ? (
                    <></>
                  ) : (
                    <Menu.Item>
                      {props.menuItems[item].includes("#") ? (
                        <div className="flex items-baseline ">
                          <Link
                            smooth
                            to={
                              pathname === "/trust-all/"
                                ? props.menuItems[item]
                                : `/trust-all/${props.menuItems[item]}`
                            }
                            className={`flex items-center justify-center px-3 text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-14 hover:text-darker_grey font-normal font-Saira text-xl`}
                          >
                            {t(item)}
                          </Link>
                        </div>
                      ) : (
                        // <a
                        //   href={props.menuItems[item]}
                        //   className={`flex items-center justify-start w-fit px px-3 text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-14 hover:text-darker_grey font-normal font-Saira text-xl`}
                        // >
                        //   {t(item)}
                        // </a>
                        <button
                          onClick={() => {
                            props.menuItems[item] === "/admin/settings"
                              ? window.open(props.menuItems[item], "_self")
                              : navigate(props.menuItems[item]);
                          }}
                          className={`px-3 text-grey_light block hover:border-b-4 hover:border-yellowPrimary text-white h-14 hover:text-darker_grey font-normal font-Saira text-xl`}
                        >
                          {item === "Notifications" ? (
                            unReadNotifCount > 0 ? (
                              <Badge
                                badgeContent={unReadNotifCount}
                                color="error"
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: dir === "ltr" ? "right" : "left",
                                }}
                              >
                                {t("Notifications")}
                              </Badge>
                            ) : (
                              t("Notifications")
                            )
                          ) : (
                            t(item)
                          )}
                        </button>
                      )}
                    </Menu.Item>
                  )}
                </div>
              ))}
              <div
                className={`${props.hiddenSignup} flex items-center justify-center mb-3`}
              >
                <Menu.Item>
                  <Button
                    className="items-center"
                    onClick={handleLogout}
                    title={t("Logout")}
                  />
                </Menu.Item>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
