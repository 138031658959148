export const COLUMNS = [
  {
    Header: "Agent",
    Footer: "agent",
    accessor: "agent",
  },
  {
    Header: "command_id",
    Footer: "command_id",
    accessor: "command_id",
  },
  {
    Header: "Address",
    Footer: "address",
    accessor: "address",
  },
  {
    Header: "Client_full_name",
    Footer: "client_full_name",
    accessor: "client_full_name",
  },
  {
    Header: "Client_number",
    Footer: "client_number",
    accessor: "client_number",
  },
  {
    Header: "Money_amount",
    Footer: "money_amount",
    accessor: "money_amount",
  },
  {
    Header: "Status",
    Footer: "reports",
    accessor: "reports",
  },
];
