export const COLUMNS = [
  {
    Header: "command_id",
    Footer: "command_id",
    accessor: "command_id",
  },
  {
    Header: "Car_name",
    Footer: "car_name",
    accessor: "car_name",
  },
  {
    Header: "Address",
    Footer: "address",
    accessor: "address",
  },
  {
    Header: "plan",
    Footer: "plan",
    accessor: "plan",
  },
  {
    Header: "date",
    Footer: "date",
    accessor: "date",
  },
  {
    Header: "preview",
    Footer: "preview",
    accessor: "preview",
  },
];
