import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import statService from "../../Service/stat.service";
import { setMessage } from "./message";

export const getStatistics = createAsyncThunk(
  "statistics/getStatistics",
  async (payload, thunkAPI) => {
    try {
      const response = await statService.getStatistics(
        payload.city,
        payload.auth_agent_id,
        payload.date_start,
        payload.date_end
      );
      //   console.log(response.data);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

const statSlice = createSlice({
  name: "statistics",
  initialState: { statistics: [] },
  extraReducers: {
    [getStatistics.fulfilled]: (state, action) => {
      state.statistics = action.payload;
    },
  },
});

const { reducer } = statSlice;
export default reducer;
