import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavBarBlack from "./NavBar_Black";
import NavBarMobile from "./NavBar_mobile";
import logo from "../../assets/Logo/Logo.svg";
import { HashLink as Link } from "react-router-hash-link";
import Cookies from "js-cookie";

function NavBar(props) {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  return (
    <>
      <NavBarBlack register={props.register} login={props.login} />
      <nav className="bg-lightGray sticky shadow-sm top-0 z-10">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <NavBarMobile register={props.register} login={props.login} />
          <div className="flex items-center justify-between md:h-16">
           {/* <div className="flex items-center"> */}

            <div className="hidden md:block">
              <div className=" flex items-baseline space-x-4">
                <img
                  className="h-10 cursor-pointer"
                  src={logo}
                  alt="logo"
                  onClick={() => window.open("/", "_self")}
                />
              </div>
            </div>

            <div className="hidden md:block">
              <div className=" flex items-baseline space-x-4">
                <button
                  onClick={() => {
                    navigate("/");
                  }}
                  className={`px-3 text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${currentLanguageCode === "fr" ? "text-base": "text-xl"}`}
                >
                  {t("Home")}
                </button>
              </div>
            </div>

            <div className="hidden md:block">
            <div className={`${currentLanguageCode === "fr" ? "ml-5":"ml-10"} flex items-baseline space-x-4`}>
                {/* <a
                  href="#services" */}
                <button
                  onClick={() => {
                    navigate("/plans");
                  }}
                  className={`flex items-center justify-center px-3 text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${currentLanguageCode === "fr" ? "text-base": "text-xl"}`}
                >
                  {t("Services")}
                </button>

                {/* >
                    {t("Services")}
                  </a> */}
              </div>
            </div>

            <div className="hidden md:block">
            <div className={`${currentLanguageCode === "fr" ? "ml-5":"ml-10"} flex items-baseline space-x-4`}>
                <button
                  onClick={() => {
                    navigate("/blogs");
                  }}
                  className={`px-3 text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${currentLanguageCode === "fr" ? "text-base": "text-xl"}`}
                >
                  {t("Blogs")}
                </button>
              </div>
            </div>

            <div className="hidden md:block">
            <div className={`${currentLanguageCode === "fr" ? "ml-5":"ml-10"} flex items-baseline space-x-4`}>
                <button
                  onClick={() => {
                    navigate("/FAQs");
                  }}
                  className={`px-3 text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${currentLanguageCode === "fr" ? "text-base": "text-xl"}`}
                >
                  {t("FAQs")}
                </button>
              </div>
            </div>
            <div className="hidden md:block">
              <div className={`${currentLanguageCode === "fr" ? "ml-5":"ml-10"} flex items-baseline space-x-4`}>
                <Link
                  smooth
                  to={
                    pathname === "/trust-all/"
                      ? "#contact-us"
                      : "/trust-all/#contact-us"
                  }
                  className={`flex items-center justify-center text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${currentLanguageCode === "fr" ? "text-base": "text-xl"}`}
                >
                  {t("Contact_us")}
                </Link>
              </div>
            </div>

            <div className="hidden md:block">
            <div className={`${currentLanguageCode === "fr" ? "ml-5":"ml-10"} flex items-baseline space-x-4`}>
                {
                  <Link
                    replace
                    smooth
                    to={
                      pathname === "/trust-all/"
                        ? "#about-us"
                        : "/trust-all/#about-us"
                    }
                    className={`flex items-center justify-center px-3 text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${currentLanguageCode === "fr" ? "text-base": "text-xl"}`}
                  >
                    {t("About_us")}
                  </Link>
                }
              </div>
            </div>

            <div className="hidden md:block">
            <div className={`${currentLanguageCode === "fr" ? "ml-5":"ml-10"} flex items-baseline space-x-4`}>
                <button
                  onClick={() => {
                    navigate("/join-us");
                  }}
                  className={`px-3 text-grey_light hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${currentLanguageCode === "fr" ? "text-base": "text-xl"}`}
                >
                  {t("join_us")}
                </button>
              </div>
            </div>
             
          </div>
        </div>
        {/* </div> */}
      </nav>
    </>
  );
}

export default NavBar;
