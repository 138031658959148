import React from "react";
import Table from "./Table/Table.client";

function Commands() {
  return (
    <div className="p-2 md:m-4 w-full">
      <div className="overflow-x-scroll md:overflow-hidden">
        <Table />
      </div>
    </div>
  );
}

export default Commands;
