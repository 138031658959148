import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BloggerService from "../../Service/blogger.service";
import { setMessage } from "./message";

export const createBlog = createAsyncThunk(
  "blogger/createBlog",
  async (payload, thunkAPI) => {
    try {
      const response = await BloggerService.createBlog(payload.formData);
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response;
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getBlogs = createAsyncThunk(
  "blogger/getBlogs",
  async (payload, thunkAPI) => {
    try {
      const response = await BloggerService.getBlogs();
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return { blogs: response.data };
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPublicBlogs = createAsyncThunk(
  "blogger/getPublicBlogs",
  async (payload, thunkAPI) => {
    try {
      const response = await BloggerService.getPublicBlogs();
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return { blogs: response.data };
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "blogger/deleteBlog",
  async (payload, thunkAPI) => {
    try {
      const response = await BloggerService.deleteBlog(payload.id);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

const bloggerSlice = createSlice({
  name: "blogger",
  initialState: { blogger: [], isLoading: false, blogs: [] },
  extraReducers: {
    [createBlog.pending]: (state, action) => {
      state.isLoading = true;
    },
    [createBlog.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [createBlog.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getBlogs.fulfilled]: (state, action) => {
      state.blogs = action.payload.blogs;
      state.isLoading = false;
    },
    [getBlogs.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getBlogs.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getPublicBlogs.fulfilled]: (state, action) => {
      state.blogs = action.payload.blogs;
      state.isLoading = false;
    },
    [getPublicBlogs.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getPublicBlogs.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

const { reducer } = bloggerSlice;
export default reducer;
