import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button.compenent";
import ImageViewer from "./ImageViewer";

function RapportTable(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  return (
    <div className="flex flex-col w-full min-h-full text-sm ">
      <div className="sm:-mx-6 lg:-mx-8 inset-0">
        <div className="inline-block w-full ">
          <div className="overflow-hidden">
            <table className="w-full font-Saira">
              <thead className=" bg-darkGray">
                <tr>
                  <th
                    colSpan={4}
                    className="text-sm font-normal text-white px-6 py-2 rounded-t-md"
                  >
                    {t(props.title)}
                  </th>
                </tr>
              </thead>
              <tbody>
              <ImageViewer
                        url={imageUrl}
                        open={open}
                        setOpen={setOpen}
                      />
                {props.data.map((k) => (
                  <tr
                    key={Object.values(k)[0]}
                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                  >
                    <td className="text-black font-normal py-4 whitespace-nowrap">
                      <p className="form-label font-normal font-Saira inline-block w-full text-darkGray px-2">
                        {t(Object.keys(k)[0])}
                      </p>
                    </td>

                    <td className=" text-black font-normal py-4 whitespace-nowrap">
                      {/* {console.log(k[Object.keys(k)[0]]["checked"])} */}
                      {k[Object.keys(k)[0]]["status"] === true || k[Object.keys(k)[0]]["status"] === false ? (
                        <div className="">
                          <input
                            type="checkbox"
                            checked={k[Object.keys(k)[0]]["status"]}
                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                          />
                        </div>
                      ) : (
                        t(Object.values(k)[0])
                      )}
                    </td>

                    <td
                      className={`font-normal px-2 ${
                        k[Object.keys(k)[0]]["status"] === true
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                    >
                      {k[Object.keys(k)[0]]["description"]}
                    </td>
                    <td className={`text-black font-normal w-4 ${k[Object.keys(k)[0]]["image_url"] ? "" : "hidden"}`}>
                      <Button
                        title={t("ViewImage")}
                        onClick={() => {
                          setImageUrl(k[Object.keys(k)[0]]["image_url"])
                          setOpen(true);
                        }}
                      />
                     
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RapportTable;
