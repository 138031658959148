import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./utils/i18next";
import Error404 from "./pages/404";
import Loading from "./components/Loading";
import Login from "./pages/Auth/Login";
import SignUp from "./pages/Auth/SignUp";
import Confirmation from "./pages/Auth/Confirmation";
import RestPassword from "./pages/Auth/RestPassword";
import Client from "./pages/Client";
import Admin from "./pages/Admin";
import AuthAgent from "./pages/AuthAgent";
import Agent from "./pages/Agent";
import Blogger from "./pages/Blogger";
import Request from "./pages/Client/Request";
import Profile from "./pages/Client/Profile";
import ProfileAdmin from "./pages/Admin/Profile";
import ProfileAuthAgent from "./pages/AuthAgent/Profile";
import ProfileAgent from "./pages/Agent/Profile";
import ProfileBlogger from "./pages/Blogger/Profile";
import Information from "./pages/Client/Information";
import InformationAdmin from "./pages/Admin/Information.admin";
import InformationAuthAgent from "./pages/AuthAgent/Information.authagent";
import InformationAgent from "./pages/Agent/Information.agent";
import InformationBlogger from "./pages/Blogger/Information.blogger";
import CarsCommands from "./pages/Admin/Car_commands/cars_commands.admin";
import CarsCommandsAuthAgent from "./pages/AuthAgent/Car_commands/cars_commands.auth_agent";
import CarsCommandsAgent from "./pages/Agent/Car_commands/cars_commands.agent";
import PrivateRoutes from "./Routes/PrivateRoutes";
import NewPassword from "./pages/Auth/NewPassword";
import Commands from "./pages/Client/Commands/Commands";
import RequestStatus from "./pages/Client/RequestStatus";
import ReportClient from "./pages/Client/Report";
import Plans from "./pages/Client/Plans";
import AuthAgents from "./pages/Admin/AuthAgent/AuthAgents.admin";
import Agents from "./pages/Admin/Agent/Agent.admin";
import AuthAgentAgents from "./pages/AuthAgent/Agent/Agent.authagent";
import Clients from "./pages/Admin/Clients/Clients.admin";
import AddAuthAgent from "./pages/Admin/AuthAgent/Add.authagent.admin";
import AddAgent from "./pages/Admin/Agent/Add.agent.admin";
import AuthAgentAddAgent from "./pages/AuthAgent/Agent/Add.agent.authagent";
import Sadmin from "./pages/Admin/s_admin/S_admin.admin";
import AddSadmin from "./pages/Admin/s_admin/Add.s-admin.admin";
import Report from "./pages/Report.fill";
import MoneyCommands from "./pages/Admin/Money_commands/Money_commands.admin";
import MoneyCommandsAuthAgent from "./pages/AuthAgent/Money_commands/money_commands.auth_agent";
import MoneyCommandsAgent from "./pages/Agent/Money_commands/money_commands.agent";
import Verfication from "./pages/Verfication";
import AdminVerfication from "./pages/Admin/Verfication/Verficaiton.AuthAgents.admin";
import PaymentMethod from "./pages/Client/PaymentMethod";
import CreatePlan from "./pages/Admin/Create_plan/CreatePlan";
import ManagePlans from "./pages/Admin/Create_plan/ManagePlans";
import Notification from "./pages/Admin/Notification/notifiaction.admin";
import NotificationPage from "./pages/Admin/Notification/notification_page.admin";
import Statestiques from "./pages/Admin/Statistique/Statistiques.admin";
import Bloggers from "./pages/Admin/Blogger/Blogger.admin";
import AddBlogger from "./pages/Admin/Blogger/Add.blogger.admin";
import ManageBlogs from "./pages/Blogger/ManageBlogs/manage_Blogs.blogger";
import ManageBlog from "./pages/Blogger/ManageBlogs/manage_Blog.blogger";
import EditBlog from "./pages/Blogger/ManageBlogs/Edit_blog.blogger";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetail from "./pages/Blogs/BlogDetail";
import FAQs from "./pages/FAQs/FAQs";
import AboutUsContactUs from "./pages/AboutUsContactUs/AboutUsContactUs";
import Terms from "./pages/Terms/Terms";
import Settings from "./pages/Admin/Settings";
import ScrollToTop from "./utils/Scrollup";
import CanceledCommands from "./pages/Admin/Canceled_commands/canceled_commands.admin";
import Cars from "./pages/Admin/Cars/cars.admin";
import CarsAuthAgent from "./pages/AuthAgent/Cars/cars.authagent";
import CanceledCommandsAuthAgent from "./pages/AuthAgent/Canceled_commands/canceled_commands.authagent";
import JoinUs from "./pages/JoinUs";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Suspense fallback={<Loading />}>
    <BrowserRouter>
      <Provider store={store}>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/restpassword" element={<RestPassword />} />
            <Route path="/newpassword/:token" element={<NewPassword />} />
            <Route path="/request" element={<Request />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/verification" element={<Verfication />} />
            {/* <Route path="/blogs" element={<Blogs />} /> */}
            {/* <Route path="/blog/:id" element={<BlogDetail />} /> */}
            {/* <Route path="/FAQs" element={<FAQs />} /> */}
            {/* <Route path="/trust-all/" element={<AboutUsContactUs />} /> */}
            {/* <Route path="/terms" element={<Terms />} /> */}
            {/* <Route path="/join-us" element={<JoinUs />} /> */}

            {/* Private Routes */}
            <Route element={<PrivateRoutes />}>
              <Route path="/confirmation" element={<Confirmation />} />
              {/* client */}
              <Route path="/client" element={<Navigate to="/" />} />
              <Route path="client" element={<Client />}>
                <Route element={<Profile />}>
                  <Route path="information" element={<Information />} />
                  <Route path="commands" element={<Commands />} />
                  <Route
                    path="commands/request-status/:id"
                    element={<RequestStatus />}
                  />
                  {/* <Route
                    path="commands/payment-method/:id"
                    element={<PaymentMethod />}
                  /> */}
                  <Route
                    path="commands/report/:id"
                    element={<ReportClient />}
                  />
                </Route>
              </Route>
              {/* admin */}
              <Route path="/admin" element={<Navigate to="/" />} />
              <Route path="/admin" element={<Admin />}>
                <Route element={<ProfileAdmin />}>
                  <Route path="information" element={<InformationAdmin />} />
                  <Route path="auth-agents" element={<AuthAgents />} />
                  <Route path="auth-agents/add" element={<AddAuthAgent />} />
                  <Route path="agents/add" element={<AddAgent />} />
                  <Route path="s-admin/add" element={<AddSadmin />} />
                  <Route path="agents" element={<Agents />} />
                  <Route path="clients" element={<Clients />} />
                  <Route path="s-admin" element={<Sadmin />} />
                  {/* <Route path="bloggers" element={<Bloggers />} /> */}
                  {/* <Route path="bloggers/add" element={<AddBlogger />} /> */}
                  <Route path="commands/report/:id" element={<Report />} />
                  <Route path="cars-commands" element={<CarsCommands />} />
                  <Route path="money-commands" element={<MoneyCommands />} />
                  {/* <Route path="cars" element={<Cars />} /> */}
                  {/* <Route
                    path="canceled-commands"
                    element={<CanceledCommands />}
                  /> */}
                  <Route path="verification" element={<AdminVerfication />} />
                  {/* <Route path="notifications" element={<Notification />} /> */}
                  <Route
                    path="notification/:id"
                    element={<NotificationPage />}
                  />
                  {/* <Route
                    path="manage-plans/create-plan"
                    element={<CreatePlan />}
                  />
                  <Route path="manage-plans" element={<ManagePlans />} /> */}
                  {/* <Route
                    path="commands/get-report/:id"
                    element={<ReportClient />}
                  /> */}
                  {/* <Route path="settings" element={<Settings />} />
                  <Route path="statistics" element={<Statestiques />} /> */}
                </Route>
              </Route>
            </Route>
            {/* AuthAgent */}
            <Route path="/auth-agent" element={<Navigate to="/" />} />
            <Route path="/auth-agent" element={<AuthAgent />}>
              <Route element={<ProfileAuthAgent />}>
                <Route path="information" element={<InformationAuthAgent />} />
                <Route
                  path="cars-commands"
                  element={<CarsCommandsAuthAgent />}
                />
                <Route
                  path="money-commands"
                  element={<MoneyCommandsAuthAgent />}
                />
                {/* <Route
                  path="canceled-commands"
                  element={<CanceledCommandsAuthAgent />}
                /> */}
                <Route path="cars" element={<CarsAuthAgent />} />
                <Route path="agents" element={<AuthAgentAgents />} />
                <Route path="agents/add" element={<AuthAgentAddAgent />} />
                <Route path="commands/report/:id" element={<Report />} />
                <Route
                  path="commands/get-report/:id"
                  element={<ReportClient />}
                />
                {/* <Route path="notifications" element={<Notification />} />
                <Route path="notification/:id" element={<NotificationPage />} /> */}
              </Route>
            </Route>
            {/* Agent */}
            <Route path="/agent" element={<Navigate to="/" />} />
            <Route path="/agent" element={<Agent />}>
              <Route element={<ProfileAgent />}>
                <Route path="information" element={<InformationAgent />} />
                <Route path="cars-commands" element={<CarsCommandsAgent />} />
                <Route path="money-commands" element={<MoneyCommandsAgent />} />
                <Route path="commands/report/:id" element={<Report />} />
                {/* <Route
                  path="commands/get-report/:id"
                  element={<ReportClient />}
                />
                <Route path="notifications" element={<Notification />} />
                <Route path="notification/:id" element={<NotificationPage />} /> */}
              </Route>
            </Route>
            {/* Blogger */}
            {/* <Route path="/blogger" element={<Navigate to="/" />} />
            <Route path="/blogger" element={<Blogger />}>
              <Route element={<ProfileBlogger />}>
                <Route path="information" element={<InformationBlogger />} />
                <Route path="manage-blogs" element={<ManageBlogs />} />
                <Route path="manage-blogs/add" element={<ManageBlog />} />
                <Route path="manage-blogs/edit/:id" element={<EditBlog />} />
              </Route>
            </Route> */}

            <Route path="*" element={<Error404 />} />
          </Routes>
        </ScrollToTop>
      </Provider>
    </BrowserRouter>
  </Suspense>

  // </React.StrictMode>
);
