import React from "react";
import Footer from "../../../components/Footer/Footer";
import NavBar from "../../../components/NavBar/NavBar";
import Plans from "./Plans";

function index() {
  return (
    <div>
      <NavBar />
      <div className="mt-10"></div>
      <Plans />
      <Footer />
    </div>
  );
}

export default index;
