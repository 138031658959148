import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import requestService from "../../Service/request.service";
import { setMessage } from "./message";

export const sendRequest = createAsyncThunk(
  "request/sendRequest",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.sendRequest(
        payload.CarOwnerPhone,
        payload.OwnerName,
        payload.CarYear,
        payload.CarBrand,
        payload.commune_id,
        payload.planId,
        payload.token,
        payload.id,
        payload.lang
      );
      // console.table({ response });
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      // console.table({ error });
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendConfirmCommand = createAsyncThunk(
  "request/sendConfirmCommand",
  async (payload, thunkAPI) => {
    try {
      // console.log("haniiiiiiiii req slice");
      const response = await requestService.sendConfirmCommand(
        payload.command_id,
        payload.action
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      // console.log(response);
      return response.status;
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const RequestEpaymant = createAsyncThunk(
  "request/RequestEpaymant",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.RequestEpaymant(
        payload.command_id,
        payload.mode
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return { paymentURL: response.data.msg };
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendConfirmPayment = createAsyncThunk(
  "request/sendConfirmPayment",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.sendConfirmPayment(
        payload.command_id
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      // console.log(response);
      return response.status;
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getOffers = createAsyncThunk(
  "request/getOffers",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getOffers(payload.lang);
      return { offers: response.data.msg };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getOffer = createAsyncThunk(
  "request/getOffer",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getOffer(
        payload.plan_id,
        payload.lang
      );
      return { offer: response.data.msg };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPlanAdmin = createAsyncThunk(
  "request/getPlanAdmin",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getPlanAdmin(payload.lang);
      return { offer: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const deleteOffer = createAsyncThunk(
  "request/deleteOffer",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.deleteOffer(payload.plan_id);
      thunkAPI.dispatch(setMessage(response.data.msg));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCommands = createAsyncThunk(
  "request/getCommands",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getCommands(payload.lang);
      return { commands: response.data };
    } catch (error) {
      console.log(error);

      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCanceledCommands = createAsyncThunk(
  "request/getCanceledCommands",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getCanceledCommands(payload.lang);
      return { cancledCommands: response.data };
    } catch (error) {
      console.log(error);
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCars = createAsyncThunk(
  "request/getCars",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getCars(payload.lang);
      return { Cars: response.data };
    } catch (error) {
      console.log(error);
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getMoneyCommands = createAsyncThunk(
  "request/getMoneyCommands",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getMoneyCommands(payload.lang);
      return { MoneyCommands: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getReport = createAsyncThunk(
  "request/getReport",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getReport(payload.command_id);
      return { Report: response.data };
    } catch (error) {
      // console.log(error);
      // thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getReportDone = createAsyncThunk(
  "request/getReport",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.getReportDone(
        payload.command_id,
        payload.lang
      );
      // console.log(response);
      return { Report: response.data };
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendReport = createAsyncThunk(
  "request/sendReport",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.sendReport(payload.formData);
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      // console.table({ error });
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const Request_status = createAsyncThunk(
  "request/RequestStatus",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.Request_status(payload.command_id);
      // console.log(response);
      return { ReqStatus: response.data };
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const createPlan = createAsyncThunk(
  "request/createPlan",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.createPlan(payload.descriptions);
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      // console.table({ error });
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const cancelCommand = createAsyncThunk(
  "request/cancelCommand",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.cancelCommand(payload.command_id);
      thunkAPI.dispatch(setMessage(response.data.msg));
      // console.log(payload.command_id);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const recoverCommand = createAsyncThunk(
  "request/recoverCommand",
  async (payload, thunkAPI) => {
    try {
      const response = await requestService.recoverCommand(payload.command_id);
      thunkAPI.dispatch(setMessage(response.data.msg));
      // console.log(payload.command_id);
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

// export const getImages = createAsyncThunk(
//   "request/getImages",
//   async (payload, thunkAPI) => {
//     try {
//       const response = await requestService.getImages(payload.type, payload.id);
//       return { images: response };
//     } catch (error) {
//       thunkAPI.dispatch(setMessage(error.response.data.err));
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

const requestSlice = createSlice({
  name: "request",
  initialState: {
    offers: [],
    commands: [],
    MoneyCommands: [],
    cancledCommands: [],
    Cars: [],
    offer: [],
    paymentURL: [],
    ReqStatus: undefined,
    Report: undefined,
    isLoading: false,
    isLoadingReport: false,
  },
  extraReducers: {
    [getOffers.fulfilled]: (state, action) => {
      state.offers = action.payload.offers;
      state.isLoading = false;
    },
    [getOffers.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getOffers.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCommands.fulfilled]: (state, action) => {
      state.commands = action.payload.commands;
      state.isLoading = false;
    },
    [getCommands.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCommands.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getCanceledCommands.fulfilled]: (state, action) => {
      state.cancledCommands = action.payload.cancledCommands;
      state.isLoading = false;
    },
    [getCanceledCommands.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCanceledCommands.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getCars.fulfilled]: (state, action) => {
      state.Cars = action.payload.Cars;
      state.isLoading = false;
    },
    [getCars.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCars.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getMoneyCommands.fulfilled]: (state, action) => {
      state.MoneyCommands = action.payload.MoneyCommands;
      state.isLoading = false;
    },
    [getMoneyCommands.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getMoneyCommands.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getOffer.fulfilled]: (state, action) => {
      state.offer = action.payload.offer;
    },
    [getPlanAdmin.fulfilled]: (state, action) => {
      state.offer = action.payload.offer;
    },
    [sendReport.pending]: (state, action) => {
      state.isLoading = true;
    },
    [sendReport.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [sendReport.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getReport.fulfilled]: (state, action) => {
      state.Report = action.payload.Report;
      state.isLoadingReport = false;
    },
    [getReport.rejected]: (state, action) => {
      state.isLoadingReport = false;
    },
    [getReport.pending]: (state, action) => {
      state.isLoadingReport = true;
    },
    [getReportDone.fulfilled]: (state, action) => {
      state.Report = action.payload.Report;
    },
    [Request_status.fulfilled]: (state, action) => {
      state.ReqStatus = action.payload.ReqStatus;
    },
    [RequestEpaymant.fulfilled]: (state, action) => {
      state.paymentURL = action.payload.paymentURL;
      state.isLoading = false;
    },
    [RequestEpaymant.pending]: (state, action) => {
      state.isLoading = true;
    },
    [RequestEpaymant.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

const { reducer } = requestSlice;
export default reducer;
