import Footer from "../components/Footer/Footer";
import NavBar from "../components/NavBar/NavBar";
import Guest from "./guest/Guest";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Guest />
      <Footer />
    </div>
  );
}

export default App;
