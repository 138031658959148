import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSettings } from "../../redux/reducers/adminSlice";
import { logout } from "../../redux/reducers/authSlice";
import { getUserType } from "../../utils/helpers";
// import { ReactComponent as Profile } from "../../assets/Profile.svg";
// import { logout } from "../../reduxe/reducers/authSlice";
import Button from "../Button.compenent";
import Dropdown from "./Dropdown";
import ProfileDropdown from "./ProfileDropdown";

function NavBarBlack(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dir = useSelector((state) => state.direction.dir.dir);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const menuDir = dir === "rtl" ? "left-0" : "right-0";
  const name = isAuth && JSON.parse(localStorage.getItem("user")).first_name;
  const dispatch = useDispatch();
  // const { Settings } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const handleLogin = () => {
    navigate("/login");
  };
  const handleRegister = () => {
    navigate("/signup");
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  // console.log(getUserType())

  return (
    <nav className="bg-darkGray h-16 p-1 text-white z-90">
      <div className="flex items-center justify-between h-16 mx-auto md:max-w-screen-2xl">
        {/* text */}
        {/* {((isAuth && getUserType() === "client") || !isAuth) && (
          <span
            onClick={() =>
              window.open(
                "http://api.whatsapp.com/send?phone=$".replace(
                  "$",
                  Settings && Settings?.social_media?.whatsapp
                )
              )
            }
            className="font-Saira font-normal cursor-pointer hover:text-yellowPrimary w-36 sm:w-auto mx-1 md:mx-4"
          >
            {t("GetSupport")}
          </span>
        )} */}
        <div>
          <p className="mx-2 md:mx-20 font-Saira font-normal ">
            {t("work_houres")}
          </p>
        </div>
        <div className="inline-flex">
          {dir === "ltr" ? (
            <>
              {/* isAuth ? ( */}
              <span className="mr-2 md:mr-10">
                <Dropdown />
              </span>

              {isAuth ? (
                <div className="font-Saira font-normal md:flex md:mr-5 hidden">
                  {/* <Button onClick={handleLogout} title={t("Logout")} /> */}
                  <span className="flex items-center mx-2">
                    {t("Hi")}, {name}
                  </span>
                  {getUserType() === "client" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/client/information",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                  {getUserType() === "auth-agent" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/auth-agent/cars-commands",
                        Notifications: "/auth-agent/notifications",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                  {getUserType() === "admin" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/admin/information",
                        Notifications: "/admin/notifications",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                  {getUserType() === "agent" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/agent/information",
                        Notifications: "/agent/notifications",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                  {getUserType() === "blogger" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/blogger/information",
                        Manage_Blogs: "/blogger/manage-blogs",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                </div>
              ) : (
                <>
                  <span className={`hidden md:block md:${props.register} mr-5`}>
                    <Button onClick={handleRegister} title={t("Register")} />
                  </span>
                  <span className={`hidden md:block  md:${props.login} mr-10`}>
                    <Button onClick={handleLogin} title={t("Login")} />
                  </span>
                </>
              )}
            </>
          ) : (
            <>
              <span className="ml-4 md:ml-10">
                <Dropdown />
              </span>
              {/* isAuth ? ( */}

              {isAuth ? (
                <div className="font-Saira font-normal md:flex md:ml-5 hidden">
                  {/* <Button onClick={handleLogout} title={t("Logout")} /> */}
                  <span className="flex items-center mx-2">
                    {t("Hi")}, {name}
                  </span>

                  {getUserType() === "notVerified" && (
                    <Button
                      className="items-center"
                      onClick={handleLogout}
                      title={t("Logout")}
                    />
                  )}

                  {/* <Profile /> */}
                  {getUserType() === "client" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/client/information",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                  {getUserType() === "auth-agent" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/auth-agent/cars-commands",
                        Notifications: "/auth-agent/notifications",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                  {getUserType() === "admin" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/admin/information",
                        Notifications: "/admin/notifications",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                  {getUserType() === "agent" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/agent/information",
                        Notifications: "/agent/notifications",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                  {getUserType() === "blogger" && (
                    <ProfileDropdown
                      menuItems={{
                        Profile: "/blogger/information",
                        Manage_Blogs: "/blogger/manage-blogs",
                      }}
                      menuDir={menuDir}
                      width="min-w-max"
                    />
                  )}
                </div>
              ) : (
                <>
                  <span className={`hidden md:block md:${props.register} ml-5`}>
                    <Button onClick={handleRegister} title={t("Register")} />
                  </span>
                  <span className={`hidden md:block  ml-10 md:${props.login}`}>
                    <Button onClick={handleLogin} title={t("Login")} />
                  </span>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default NavBarBlack;
