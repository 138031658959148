import React, { useEffect, useMemo } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
// import Data from "./MOCK_DATA.json";
import { COLUMNS } from "./columns";
import Button from "../../../../components/Button.compenent";
import "./table.css";
import { useTranslation } from "react-i18next";
import {
  cancelCommand,
  getCommands,
} from "../../../../redux/reducers/requestSlice";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar-dz";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
import { useState } from "react";

function Table() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const columns = useMemo(() => COLUMNS, []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { commands } = useSelector((state) => state.request);
  const { isLoading } = useSelector((state) => state.request);
  const [updated, setUpdated] = useState(false);

  moment.locale(
    (currentLanguageCode === "ar" && "ar-dz") || currentLanguageCode
  );

  useEffect(() => {
    dispatch(getCommands({ lang: currentLanguageCode }));
  }, [dispatch, currentLanguageCode, updated]);

  const data = useMemo(
    () =>
      commands.map((command) => {
        return {
          command_id: command._id.substring(0, 10),
          plan: command.plan,
          date: moment(command.createdAt).format("LL"),
          preview: command.preview,
          address: command.city,
          car_name: command.car_name,
          status: command.status,
        };
      }),
    [commands]
  );

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  const { globalFilter } = state;

  return (
    <>
      <div className="p-2 md:m-4 w-full">
        <div className="">
          <div className="mb-3">
            <div className=" flex items-stretch md:w-96 w-full mb-4">
              <input
                type="search"
                className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                aria-describedby="button-addon2"
              />
              <button
                className="inline-block px-6 py-2.5 bg-yellowPrimary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-yellow-500 hover:shadow-lg focus:bg-yellow-500  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                type="button"
                id="button-addon2"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  className="w-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          {!isLoading ? (
            <div className="overflow-x-scroll h-full md:overflow-hidden">
              <>
                <tr>Total commands: {rows.length}</tr>
              </>
              <table {...getTableBodyProps()} className="font-Saira md:w-full">
                <thead id="tfoot">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          id="tfoot"
                          {...column.getHeaderProps(
                            !(
                              column.Header === "download" ||
                              column.Header === "preview"
                            ) && column.getSortByToggleProps()
                          )}
                        >
                          {t(column.render("Header"))}
                          {!(
                            column.Header === "download" ||
                            column.Header === "preview"
                          ) &&
                            (column.isSorted ? (
                              column.isSortedDesc ? (
                                "▼"
                              ) : (
                                "▲"
                              )
                            ) : (
                              <>
                                <span>▲</span>
                                <span className="-mx-2">▼</span>
                              </>
                            ))}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr id="tr" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td id="td" {...cell.getCellProps()}>
                              {(cell.row.original.status !== "canceled"
                                ? cell.column.Header === "preview" && (
                                    <div className="flex place-content-center">
                                      <Button
                                        title={t("preview_btn")}
                                        custom="mx-1"
                                        onClick={
                                          () =>
                                            window.open(
                                              "commands/request-status/" +
                                                commands[cell.row.index]._id,
                                              "_self"
                                            )
                                          // cell.value &&
                                          // window.open(cell.value, "_blank")
                                        }
                                      />

                                      {(cell.row.original.status === "01" ||
                                        cell.row.original.status === "02") && (
                                        <Button
                                          title={t("cancel")}
                                          custom={`bg-red-500 text-white mx-1`}
                                          onClick={async () =>
                                            window.confirm(
                                              t("Confirm_alert_delete_commands")
                                            ) &&
                                            (await dispatch(
                                              cancelCommand({
                                                command_id:
                                                  commands[cell.row.index]._id,
                                              })
                                            )) &&
                                            setUpdated(!updated)
                                          }
                                        />
                                      )}
                                    </div>
                                  )
                                : cell.column.Header === "preview" && (
                                    <p className="text-center text-red-600 font-medium">
                                      {t("canceled")}
                                    </p>
                                  )) || cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </>
  );
}

export default Table;
