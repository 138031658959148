import React from "react";
import Button from "../Button.compenent";
import { ReactComponent as Whatsapp } from "../../assets/icons/whatsapp2.svg";
import { getUserType } from "../../utils/helpers";

function CardStepOrders(props) {
  return (
    <div className={`relative container bg-darkGray col-span-1 ${props.hidden !== "hidden" && "min-h-[13rem]"} md:min-h-[25rem] rounded-2xl p-4 mb-4 shadow-lg`}>
      <p className="text-yellowDark text-xl font-normal text-center">{props.title}</p>
      <p className="text-base font-normal mt-4 px-2 text-center ">{props.description}</p>
      <div className={`absolute bottom-4 flex items-center ${props.isAuth && getUserType() !== "client" && "hidden"} ${props.hidden}`}>
        <Button onClick={props.onClick} title={props.buttonTitle} custom="text-base font-medium"/>
        <Whatsapp className="mx-4 cursor-pointer" onClick={()=>window.open("http://api.whatsapp.com/send?phone=213" + props.number?.substring(1, props.number?.length))} />
      </div>
    </div>
  );
}

export default CardStepOrders;