import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/NavBar/NavBar";
import { getUserStatus } from "../utils/helpers";
import Passport from "../assets/Docs/Algeria.webp";
import IdCard from "../assets/Docs/biometric.jpg";
import Button from "../components/Button.compenent";
import Compressor from "compressorjs";
import { useDispatch, useSelector } from "react-redux";
import { sendVerficationDocs } from "../redux/reducers/authAgentSlice";
import { CircularProgress } from "@mui/material";
import { clearMessage } from "../redux/reducers/message";
import Alert from "../components/Alert";

function Verfication() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.authAgent);
  const { message } = useSelector((state) => state.message);
  const [showPassport, setShowPassport] = useState(false);
  const [showCardId, setShowCardId] = useState(false);
  const [show, setShow] = useState(false);
  const [res, setRes] = useState({});

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleCompressedUpload = (e, name) => {
    Object.keys(e.target.files).map(
      (f, index) =>
        new Compressor(e.target.files[index], {
          quality: 0.7,
          success: (compressedResult) => {
            compressedResult.size < 5242880 &&
              setRes({ ...res, [name]: { compressedResult, name } });
          },
        })
    );
  };

  const handleSubmit = (event, doctype) => {
    setShow(true);
    const formData = new FormData();
    Object.keys(res).map((e) =>
      formData.append("images", res[e].compressedResult, res[e].name)
    );

    // for (const value of formData.values()) {
    //   console.log(value);
    // }

    dispatch(
      sendVerficationDocs({
        formData,
        doctype,
      })
    );
    // setTimeout(() => {
    // setShow(false);
    // }, 7000);
  };

  if (
    JSON.parse(localStorage.getItem("user")).type !== "auth-agent" &&
    JSON.parse(localStorage.getItem("user")).type !== "agent" &&
    JSON.parse(localStorage.getItem("user")).type !== "blogger"
  )
    return <Navigate to="/login" />;

  return (
    <div>
      <NavBar />
      <div className="max-w-md md:max-w-screen-md mt-5 lg:max-w-screen-lg lg:px-20 lg:inset-x-0 mx-auto h-screen">
        <div className="md:grid md:gap-4 md:grid-cols-1  place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div className="flex items-center justify-center  w-full md:px-4 col-span-1">
            <div className="block w-full">
              {getUserStatus() ? (
                <div className="w-full border-4">{t("VerficationPending")}</div>
              ) : (
                <>
                  <p className="font-Saira text-center my-4 mb-2 font-extrabold text-4xl text-darkGray ">
                    {t("Verfication")}
                  </p>
                  <p className="text-center mb-4 font-Saira">
                    {t("ChooseDoc")}
                  </p>

                  <div className="flex w-full place-content-center">
                    <lable>
                      {/* Replace text with images */}
                      <button
                        className="mx-10"
                        onClick={() => {
                          setShowCardId(!showCardId);
                          showPassport && setShowPassport(!showPassport);
                        }}
                      >
                        {/* {t("UploadId")} */}
                        <img width={120} src={IdCard} alt="ÏdCard" />
                      </button>
                    </lable>
                    <lable>
                      <button
                        onClick={() => {
                          setShowPassport(!showPassport);
                          showCardId && setShowCardId(!showCardId);
                        }}
                      >
                        <img width={100} src={Passport} alt="IdCard" />
                        {/* {t("UploadPassport")} */}
                      </button>
                    </lable>
                  </div>

                  {showCardId && (
                    <>
                      <div className="mb-3 ">
                        <label
                          htmlFor="formFile"
                          className="form-label font-normal font-Saira inline-block text-darkGray"
                        >
                          {t("UploadFrontFaceIdDoc")}
                        </label>
                        <input
                          className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                          accept="image/png, image/jpg, image/jpeg"
                          type="file"
                          id="formFile"
                          onChange={(e) => handleCompressedUpload(e, "front")}
                        />
                      </div>
                      <div className="mb-3 ">
                        <label
                          htmlFor="formFile"
                          className="form-label font-normal font-Saira inline-block text-darkGray"
                        >
                          {t("UploadBackIdDoc")}
                        </label>
                        <input
                          className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                          accept="image/png, image/jpg, image/jpeg"
                          type="file"
                          id="formFile"
                          onChange={(e) => handleCompressedUpload(e, "back")}
                        />
                      </div>
                      <div className="mb-3 ">
                        <label
                          htmlFor="formFile"
                          className="form-label font-normal font-Saira inline-block text-darkGray"
                        >
                          {t("UploadSelfieWithIdDoc")}
                        </label>
                        <input
                          className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                          accept="image/png, image/jpg, image/jpeg"
                          type="file"
                          id="formFile"
                          onChange={(e) => handleCompressedUpload(e, "selfie")}
                        />
                      </div>

                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          title="Upload"
                          onClick={(e) => handleSubmit(e, "ID")}
                        />
                      )}
                    </>
                  )}
                  {showPassport && (
                    <>
                      <div className="mb-3 ">
                        <label
                          htmlFor="formFile"
                          className="form-label font-normal font-Saira inline-block text-darkGray"
                        >
                          {t("UploadPassport")}
                        </label>
                        <input
                          className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                          accept="image/png, image/jpg, image/jpeg"
                          type="file"
                          id="formFile"
                          onChange={(e) => handleCompressedUpload(e, "front")}
                        />
                      </div>

                      <div className="mb-3 ">
                        <label
                          htmlFor="formFile"
                          className="form-label font-normal font-Saira inline-block text-darkGray"
                        >
                          {t("UploadSelfieWithIdDoc")}
                        </label>
                        <input
                          className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                          accept="image/png, image/jpg, image/jpeg"
                          type="file"
                          id="formFile"
                          onChange={(e) => handleCompressedUpload(e, "selfie")}
                        />
                      </div>

                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          title="Upload"
                          onClick={(e) => handleSubmit(e, "PASSPORT")}
                        />
                      )}
                    </>
                  )}

                  <div className="mb-10 mt-4">
                    {console.log(message)}
                    {!isLoading && message ? (
                      message[1] === "success" ? (
                        show && <Alert type={message[1]} content={message[0]} />
                      ) : (
                        show && <Alert type={message[1]} content={message[0]} />
                      )
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Verfication;
