import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import "flag-icon-css/css/flag-icons.min.css";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Button from "../Button.compenent";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/reducers/authSlice";
import { getPhotoProfile } from "../../utils/helpers";
import { ReactComponent as Profile } from "../../assets/Profile.svg";


export default function ProfileDropdown(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <div>
      <Menu as="div" className="relative  w-full inline-block text-left z-50">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md hover:opacity-70 text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
          {getPhotoProfile() ? <img className="rounded-full w-12 h-12" src={getPhotoProfile()} alt=""/> : <Profile width={40} />}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute mt-2 origin-top-right ${props.menuDir} divide-y divide-gray-100 rounded-md border-2 bg-white p-4 border-darkGray shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <div className={`${props.width}`}>
              {/* {console.log(props.menuItems)} */}
              {Object.keys(props.menuItems).map((item) => (
                <div key={item} className="px-1 py-1 ">
                  <Menu.Item>
                    <button
                      onClick={() => {
                        navigate(props.menuItems[item]);
                      }}
                      className={`px-3 text-grey_light block border-2 text-darkGray mb-2 hover:text-darker_grey font-normal font-Saira text-xl`}
                    >
                      {t(item)}
                    </button>
                  </Menu.Item>
                </div>
              ))}
              <div
                className={`${props.hiddenSignup} font-bold flex items-center justify-center mb-1`}
              >
                <Menu.Item>
                  <Button
                    className="items-center"
                    onClick={handleLogout}
                    title={t("Logout")}
                  />
                </Menu.Item>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
