import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button.compenent";
import Footer from "../../components/Footer/Footer";
import { ReactComponent as ForgetPassword } from "../../assets/ForgetPassword.svg";
import NavBar from "../../components/NavBar/NavBar";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { restPassword } from "../../redux/reducers/authSlice";
import Alert from "../../components/Alert";
import { clearMessage } from "../../redux/reducers/message";
import { getSettings } from "../../redux/reducers/adminSlice";

function RestPassword() {
  const { t } = useTranslation();
  const { message } = useSelector((state) => state.message);
  const { Settings } = useSelector((state) => state.admin);
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const dispatch = useDispatch();
  const email = useRef();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const handleResetPassword = () => {
    // console.log("email ", email.current.value);
    dispatch(
      restPassword({ email: email.current.value, lang: currentLanguageCode })
    );
  };

  return (
    <div>
      <NavBar />
      <div className="h-screen font-Saira ">
        <div className="font-extrabold mt-11 text-4xl flex justify-center inset-x-0 mx-auto">
          <p className="self-center">{t("RestPassword")}</p>
        </div>

        <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center md:max-w-screen-md px-4 md:px-20 lg:max-w-screen-2xl lg:inset-x-0 mx-auto">
          <div className="col-span-1  mt-10">
            <div className="max-w-sm mx-auto md:max-w-lg mb-32">
              <div className="w-full">
                <div className="flex flex-row px-2 mt-5">
                  <div className="w-full">
                    <div className="mb-3 ">
                      <label
                        htmlFor="email"
                        className="form-label font-normal font-Saira inline-block mb-1 mt text-darkGray"
                      >
                        {t("Email/Phone")}
                      </label>
                      <input
                        type="text"
                        className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                        id="email"
                        ref={email}
                        placeholder="trustal@example.com"
                      />
                    </div>
                    <div className="flex justify-between md:w-96 w-full mt-5 mb-5">
                      <Button
                        custom="text-xl px-10 mt-5 block"
                        title={t("Verify")}
                        onClick={handleResetPassword}
                      />
                      <Button
                        custom="px-8 mt-5 bg-lightGray text-white"
                        title={t("GetSupport")}
                        onClick={() =>
                          window.open(
                            "http://api.whatsapp.com/send?phone=213$".replace(
                              "$",
                              Settings &&
                                Settings?.social_media?.whatsapp.substring(
                                  1,
                                  Settings?.social_media?.whatsapp?.length
                                )
                            )
                          )
                        }
                      />
                    </div>
                    {message ? (
                      <Alert type={message[1]} content={message} />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ForgetPassword className="col-span-1 hidden md:block my-12 mx-12" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default RestPassword;
