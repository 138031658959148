import React from 'react'

function Button(props) {
  
  return (
    <button onClick={props.onClick} className={`bg-yellowPrimary inline-block text-black font-Saira font-semibold px-4 py-2 rounded-full hover:bg-yellowDark transition ease ${props.custom}`}>
      {props.title}
      </button>
  )
}

export default Button