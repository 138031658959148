export const COLUMNS = [
  {
    Header: "fullname",
    Footer: "fullname",
    accessor: "fullname",
  },
  {
    Header: "phonenumber",
    Footer: "phonenumber",
    accessor: "phonenumber",
  },
  {
    Header: "email",
    Footer: "email",
    accessor: "email",
  },
  {
    Header: "Address",
    Footer: "Address",
    accessor: "Address",
  },
  // {
  //   Header: "Active",
  //   Footer: "status",
  //   accessor: "status",
  // },
  {
    Header: "AuthAgents",
    Footer: "authagent",
    accessor: "authagent",
  },
  {
    Header: "",
    Footer: "preview",
    accessor: "preview",
  },
];
