import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../redux/reducers/adminSlice";

function GetSupport() {
  const { t } = useTranslation();
  const { Settings } = useSelector((state) => state.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  return (
    <button
      className="fixed z-90 bottom-10 right-8 bg-yellow-400 w-auto h-10 rounded-2xl drop-shadow-lg flex justify-center items-center text-white text-xl hover:bg-yellowDark hover:drop-shadow-xl hover:animate-bounce duration-500 "
      onClick={() =>
        window.open(
          "http://api.whatsapp.com/send?phone=213$".replace(
            "$",
            Settings && Settings?.social_media?.whatsapp.substring(1, Settings?.social_media?.whatsapp?.length)
          )
        )
      }
    >
      <span className="p-2 text-black font-medium">
        {t("GetSupport")}&nbsp;&#9742;
      </span>
    </button>
  );
}

export default GetSupport;
