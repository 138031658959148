import axios from "axios";
import { getToken, getUserId, getUserType } from "../utils/helpers";

const API_URL = process.env.REACT_APP_API;

const sendRequest = (
  CarOwnerPhone,
  OwnerName,
  CarYear,
  CarBrand,
  commune_id,
  planId,
  token,
  id,
  lang
) => {
  return axios.post(
    API_URL + "client/add-command/" + id,
    {
      car_name: CarBrand,
      seller_name: OwnerName,
      seller_phone: CarOwnerPhone,
      commune_id,
      car_year: CarYear,
      plan_id: planId,
      lang,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getOffers = (lang) => {
  return axios.get(API_URL + "plan/all-formatted/" + lang);
};

const getOffer = (plan_id, lang) => {
  return axios.get(API_URL + "plan/" + plan_id + "/" + lang);
};

const getPlanAdmin = (lang) => {
  return axios.get(API_URL + "admin/plan-options/" + getUserId() + "/" + lang, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const getCommands = (lang) => {
  return getUserType() === "client"
    ? axios.get(
        API_URL +
          getUserType().replace("_", "-") +
          "/commands/" +
          getUserId() +
          "?lang=" +
          lang,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
    : axios.get(
        API_URL +
          getUserType().replace("_", "-") +
          "/car-commands/" +
          getUserId() +
          "?lang=" +
          lang,
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      );
};

const getCanceledCommands = (lang) => {
  return axios.get(
    API_URL +
      getUserType().replace("_", "-") +
      "/canceled-commands/" +
      getUserId() +
      "?lang=" +
      lang,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const getCars = (lang) => {
  return axios.get(
    API_URL +
      getUserType().replace("_", "-") +
      "/cars/" +
      getUserId() +
      "?lang=" +
      lang,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const getMoneyCommands = (lang) => {
  return axios.get(
    API_URL +
      getUserType().replace("_", "-") +
      "/money-commands/" +
      getUserId() +
      "?lang=" +
      lang,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const getReport = (command_id) => {
  return axios.get(
    API_URL +
      getUserType().replace("_", "-") +
      "/report/" +
      getUserId() +
      "?command_id=" +
      command_id,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const getReportDone = (command_id, lang) => {
  return axios.get(
    API_URL +
      getUserType().replace("_", "-") +
      "/get-report/" +
      getUserId() +
      "?command_id=" +
      command_id +
      "&lang=" +
      lang,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const Request_status = (command_id) => {
  return axios.get(
    API_URL +
      getUserType().replace("_", "-") +
      "/command/" +
      getUserId() +
      "?command_id=" +
      command_id,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const sendReport = (formData) => {
  return axios.post(
    API_URL + getUserType().replace("_", "-") + "/upload-report/" + getUserId(),
    formData,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const sendConfirmCommand = (command_id, action) => {
  // console.log(action);
  return axios.post(
    API_URL +
      getUserType().replace("_", "-") +
      "/confirm-command/" +
      getUserId(),
    { command_id, action },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const RequestEpaymant = (command_id, mode) => {
  // console.log(action);
  return axios.post(
    API_URL + getUserType().replace("_", "-") + "/e-payment/" + getUserId(),
    { command_id, mode },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const sendConfirmPayment = (command_id) => {
  return axios.post(
    API_URL +
      getUserType().replace("_", "-") +
      "/confirm-payment/" +
      getUserId(),
    { command_id },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const createPlan = (descriptions) => {
  return axios.post(
    API_URL + getUserType().replace("_", "-") + "/create-plan/" + getUserId(),
    descriptions,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const deleteOffer = (plan_id) => {
  return axios.delete(
    API_URL + getUserType() + "/plan/" + getUserId() + "/" + plan_id,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const cancelCommand = (command_id) => {
  return axios.post(
    API_URL + getUserType() + "/cancel-command/" + getUserId() + "/",
    {
      command_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const recoverCommand = (command_id) => {
  return axios.post(
    API_URL + getUserType() + "/recover-command/" + getUserId() + "/",
    {
      command_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

// /http://localhost:8000/api/admin/blogger/get-ID/63c326794c6aec49de3517a5/?id=63ce7c9ee4417b0ff0586fff

// const getImages = (type, id) => {
//   return axios.get(
//     API_URL +
//       getUserType().replace("_", "-") +
//       "/" +
//       type +
//       "/get-ID/" +
//       getUserId() +
//       "?id=" +
//       id,
//     {
//       headers: {
//         Authorization: `Bearer ${getToken()}`,
//       },
//     }
//   );
// };

const requestService = {
  sendRequest,
  sendReport,
  sendConfirmCommand,
  sendConfirmPayment,
  getOffers,
  getCommands,
  getMoneyCommands,
  getCanceledCommands,
  getCars,
  getPlanAdmin,
  getOffer,
  getReport,
  getReportDone,
  Request_status,
  RequestEpaymant,
  createPlan,
  deleteOffer,
  cancelCommand,
  recoverCommand,
};

export default requestService;
