import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import wilayaService from "../../Service/wilaya.service";

const initialState = {
  wilaya: [],
  daira: [],
  commune: [],
  auth_agent_communes: [],
};

export const getWilaya = createAsyncThunk(
  "wilaya/getwilaya",
  async (payload) => {
    try {
      return await wilayaService.getWilaya(payload.lang);
    } catch (error) {
      console.log("error ", error);
    }
  }
);

export const getDaira = createAsyncThunk("wilaya/getDaira", async (payload) => {
  try {
    return await wilayaService.getDaira(payload.city, payload.lang);
  } catch (error) {
    console.log("error ", error);
  }
});

export const getCommune = createAsyncThunk(
  "wilaya/getCommune",
  async (payload) => {
    try {
      return await wilayaService.getCommune(payload.daira, payload.lang);
    } catch (error) {
      console.log("error ", error);
    }
  }
);

export const getCommunesAuthAgent = createAsyncThunk(
  "wilaya/getCommunesAuthAgent",
  async (payload) => {
    try {
      return await wilayaService.getCommunesAuthAgent(
        payload.city,
        payload.lang
      );
    } catch (error) {
      console.log("error ", error);
    }
  }
);

const wilayaSlice = createSlice({
  name: "wilaya",
  initialState,
  extraReducers: {
    [getWilaya.fulfilled]: (state, action) => {
      // console.log("fethced data successfully ");
      state.wilaya = action.payload;
    },

    [getDaira.fulfilled]: (state, action) => {
      // console.log("fethced data successfully ");
      state.daira = action.payload;
    },

    [getCommune.fulfilled]: (state, action) => {
      // console.log("fethced data successfully ");
      state.commune = action.payload;
    },

    [getCommunesAuthAgent.fulfilled]: (state, action) => {
      // console.log("fethced data successfully ");
      state.auth_agent_communes = action.payload;
    },
  },
});

const { reducer } = wilayaSlice;
export default reducer;
