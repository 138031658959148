import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { ReactComponent as Newpassword } from "../../assets/Newpassword.svg";
import Button from "../../components/Button.compenent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { checkToken, newPassword } from "../../redux/reducers/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert";
import { setMessage } from "../../redux/reducers/message";
import { getSettings } from "../../redux/reducers/adminSlice";

function NewPassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const password = useRef();
  const R_password = useRef();
  const { message } = useSelector((state) => state.message);
  const { Settings } = useSelector((state) => state.admin);
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { token } = useParams();
  const navigate = useNavigate();
  const [done, setDone] = useState(false);

  useEffect(() => {
    dispatch(checkToken({ token }));
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const handleNewPassword = () => {
    if (password.current.value === R_password.current.value) {
      dispatch(
        newPassword({
          password: password.current.value,
          token: token,
          lang: currentLanguageCode,
        })
      ).then(() => {
        if (message === 200) setDone(true);
      });
    } else {
      dispatch(setMessage(t("passwordNotMatch")));
    }
  };
  if (message === "false") setDone(true);

  // console.log("messssage New pass ", message);

  if (!done || message === 200) {
    return (
      <div>
        <NavBar />
        <div className="h-screen font-Saira ">
          <div className="font-extrabold mt-11 text-4xl flex justify-center inset-x-0 mx-auto">
            <p className="self-center">{t("NewPassword")}</p>
          </div>
          <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center md:max-w-screen-md px-4 md:px-20 lg:max-w-screen-2xl lg:inset-x-0 mx-auto">
            <div className="col-span-1  mt-10">
              <div className="max-w-sm mx-auto md:max-w-lg mb-32">
                <div className="w-full">
                  <div className="flex flex-row px-2 mt-5">
                    <div className="w-full">
                      <div className="mb-3">
                        <label
                          htmlFor="pass"
                          className="form-label font-normal font-Saira inline-block mb-1 mt text-darkGray"
                        >
                          <span className="text-red-500">*</span>{" "}
                          {t("Password")}
                        </label>
                        <input
                          type="password"
                          ref={password}
                          className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                          id="pass"
                          placeholder={t("Password")}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="Rpass"
                          className="form-label font-normal font-Saira inline-block mb-1 mt text-darkGray"
                        >
                          <span className="text-red-500">*</span>{" "}
                          {t("RepeatPassword")}
                        </label>
                        <input
                          type="password"
                          ref={R_password}
                          className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                          id="Rpass"
                          placeholder={t("RepeatPassword")}
                          required
                        />
                      </div>
                      <div className="flex justify-between md:w-96 w-full mt-5 mb-5">
                        <Button
                          onClick={handleNewPassword}
                          custom="text-xl px-10 mt-5 block"
                          title={t("Submit")}
                        />
                        <Button
                          onClick={() =>
                            window.open(
                              "http://api.whatsapp.com/send?phone=213$".replace(
                                "$",
                                Settings &&
                                  Settings?.social_media?.whatsapp.substring(
                                    1,
                                    Settings?.social_media?.whatsapp?.length
                                  )
                              )
                            )
                          }
                          custom="px-8 mt-5 bg-lightGray text-white"
                          title={t("GetSupport")}
                        />
                      </div>
                      {message !== "true" && message !== 200 ? (
                        <Alert type="Failed" content={message} />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Newpassword className="col-span-1 hidden md:block my-12 mx-12" />
          </div>
        </div>
        <Footer />
      </div>
    );
  } else {
    navigate("/login");
  }
}

export default NewPassword;
