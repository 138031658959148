import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Profile } from "../../assets/Profile.svg";
import { logout } from "../../redux/reducers/authSlice";
import { getPhotoProfile, getUserType } from "../../utils/helpers";
import Button from "../Button.compenent";
import MobileDropdown from "./MobileDropdown";
import logo from "../../assets/Logo/Logo.svg";

function NavBarMobile(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const dir = useSelector((state) => state.direction.dir.dir);
  const menuDir = dir === "rtl" ? "left-0" : "right-0";
  const dispatch = useDispatch();

  const menu1 = (
    <svg
      className="block h-6 w-6"
      xmlns="https://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      aria-hidden="true"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  );

  const menu2 = (
    <p className="font-Saira -mt-3 font-normal flex mr-5 cursor-pointer">
      {isAuth && getPhotoProfile() ? (
        <img className="rounded-full w-12 h-12" src={getPhotoProfile()} alt="" />
      ) : (
        <Profile width={40} />
      )}
    </p>
  );

  const handleLogin = () => {
    navigate("/login");
  };
  const handleRegister = () => {
    navigate("/signup");
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <div className="py-2 md:py-0">
        <div className={`-mr-2 flex justify-between md:hidden`}>
          <div className="flex">
            <MobileDropdown
              menuItems={{
                Home: "/",
                Services: "/plans",
                Blogs: "/blogs",
                FAQs: "/FAQs",
                Contact_us: "#contact-us",
                About_us: "#about-us",
                join_us: "/join-us"
              }
              }
              content={menu1}
              menuDir={menuDir === "left-0" ? "right-0" : "left-0"}
                  width="min-w-max"
                
              hiddenSignup="hidden"
              nameHide="hidden"
           />
            <div className=" flex items-baseline space-x-4 mx-4">
              <img className="h-10 cursor-pointer"
                  src={logo}
                  alt="logo"
                  onClick={() => window.open("/", "_self")}/>
            </div>
          </div>

          {isAuth ? (
            <>
              {getUserType() === "notVerified" && (
                <Button
                  className="items-center"
                  onClick={handleLogout}
                  title={t("Logout")}
                />
              )}

              {getUserType() === "client" && (
                <MobileDropdown
                  menuItems={{
                    MyInformation: "/client/information",
                    MyCommands: "/client/commands",
                  }}
                  content={menu2}
                  menuDir={menuDir}
                  width="min-w-max"
                />
              )}

              {getUserType() === "auth-agent" && (
                <MobileDropdown
                  menuItems={{
                    MyInformation: "/auth-agent/information",
                    Notifications: "/auth-agent/notifications",
                    Cars_Commands: "/auth-agent/cars-commands",
                    Money_commands: "/auth-agent/money-commands",
                    Canceled_Commands: "/auth-agent/canceled-commands",
                    Cars: "/auth-agent/cars",
                    Agents: "/auth-agent/agents",
                  }}
                  content={menu2}
                  menuDir={menuDir}
                  width="min-w-max"
                />
              )}

              {getUserType() === "admin" && (
                <MobileDropdown
                  menuItems={{
                    MyInformation: "/admin/information",
                    Notifications: "/admin/notifications",
                    Cars_Commands: "/admin/cars-commands",
                    Money_commands: "/admin/money-commands",
                    Canceled_Commands: "/admin/canceled-commands",
                    Cars: "/admin/cars",
                    Sub_admin: "/admin/s-admin",
                    AuthAgents: "/admin/auth-agents",
                    Agents: "/admin/agents",
                    Clients: "/admin/clients",
                    Bloggers: "/admin/bloggers",
                    Verification: "/admin/verification",
                    Settings: "/admin/settings",
                    Statistics: "/admin/statistics"
                  }}
                  content={menu2}
                  menuDir={menuDir}
                  width="min-w-max"
                  overflowY = "scroll"
                  height="1400%"
                />
              )}

              {getUserType() === "agent" && (
                <MobileDropdown
                  menuItems={{
                    MyInformation: "/agent/information",
                    Notifications: "/agent/notifications",
                    Cars_Commands: "/agent/cars-commands",
                    Money_commands: "/agent/money-commands",
                  }}
                  content={menu2}
                  menuDir={menuDir}
                  width="min-w-max"
                />
              )}
             
              {getUserType() === "blogger" && (
                <MobileDropdown
                  menuItems={{
                    MyInformation: "/blogger/information",
                    Manage_Blogs: "/blogger/manage-blogs",
                  }}
                  content={menu2}
                  menuDir={menuDir}
                  width="min-w-max"
                />
              )}
            </>
          ) : (
            <div className="block ">
              <span className={`m-2 ${props.register}`}>
                <Button onClick={handleRegister} title={t("Register")} />
              </span>
              <span className={`m-2 ${props.login}`}>
                <Button onClick={handleLogin} title={t("Login")} />
              </span>
            </div>
          )}
          {/* Login + Singup */}
        </div>
      </div>
    </>
  );
}

export default NavBarMobile;
