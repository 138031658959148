import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function Alert(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {dir} = useSelector((state) => state.direction.dir);
  return (
    <>
      {props.type === "Failed" ? (
        <div
          className={`bg-red-200 border-red-600 text-red-600 ${dir=== "rtl" ? "border-r-4":"border-l-4"}  p-4`}
          role="alert"
        >
          <p className="font-normal">Oops!</p>
          {Array.isArray(props.content) ? (props.content.map((elem)=>
            <p key={elem}>- {elem}</p>
          )) : (<p>{props.content}</p>)}
          
        </div>
      ) : (
        <div
          className={`bg-green-200 border-green-600 text-green-600 ${dir=== "rtl" ? "border-r-4":"border-l-4"} p-4`}
          role="alert"
        >
          <p className="font-normal">Success</p>
          <p>{props.content}</p>
          <button
            onClick={() => navigate("/login")}
            className={`font-normal underline ${props.loginLink || "hidden"}`}
          >
            {t("BackLogin")}
          </button>
        </div>
      )}
    </>
  );
}
export default Alert;
