import axios from "axios";
import { getToken, getUserId, getUserType } from "../utils/helpers";

const API_URL = process.env.REACT_APP_API;

const getStatistics = (city, auth_agent_id, date_start, date_end) => {
  const City = city ? "city=" + city : "";
  const AuthAgent = auth_agent_id ? "&auth_agent_id=" + auth_agent_id : "";
  const DateStart = date_start ? "&date_start=" + date_start : "";
  const DateEnd = date_end ? "&end_start=" + date_end : "";

  // console.log(date_start, DateStart);
  // console.log(date_end);

  return axios.get(
    API_URL +
      getUserType() +
      "/statistics/" +
      getUserId() +
      "?" +
      City +
      AuthAgent +
      DateStart +
      DateEnd,

    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const statService = {
  getStatistics,
};

export default statService;
