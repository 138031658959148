import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ReactComponent as ManCheck } from "../../assets/mancheck.svg";
import Button from "../../components/Button.compenent";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { register } from "../../redux/reducers/authSlice";
import {
  getWilaya,
  getDaira,
  getCommune,
} from "../../redux/reducers/wilayaSlice";
import Cookies from "js-cookie";
import moment from "moment";
import { clearMessage, setMessage } from "../../redux/reducers/message";
import Alert from "../../components/Alert";
import "moment/locale/fr";
import { getSettings } from "../../redux/reducers/adminSlice";

function SignUp() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const first_name = useRef();
  const last_name = useRef();
  const R_password = useRef();
  const birth_date = useRef();
  const phone = useRef();
  const email = useRef();
  const password = useRef();
  const [show, setShow] = useState(true);
  const [city, setCity] = useState();
  const [daira, setDaira] = useState();
  const [commune_id, setCommune] = useState();
  const wilaya = useSelector((state) => state.wilaya.wilaya);
  const Daira = useSelector((state) => state.wilaya.daira);
  const Commune = useSelector((state) => state.wilaya.commune);
  const { message } = useSelector((state) => state.message);
  const { Settings } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  const wilayas = [];
  const dairas = [];
  const communes = [];

  moment.locale("fr");

  useEffect(() => {
    dispatch(getWilaya({ lang: currentLanguageCode }));
  }, [dispatch, currentLanguageCode]);

  useEffect(() => {
    city && dispatch(getDaira({ city: city, lang: currentLanguageCode }));
  }, [dispatch, city, currentLanguageCode]);

  useEffect(() => {
    daira && dispatch(getCommune({ daira: daira, lang: currentLanguageCode }));
  }, [dispatch, daira, currentLanguageCode]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  wilaya.map((e) =>
    wilayas.push({ value: e.wilaya_code, label: e.wilaya_name })
  );
  Daira.map((e) => dairas.push({ value: e.value, label: e.daira_name }));
  Commune.map((e) => communes.push({ value: e.id, label: e.commune_name }));

  const handleCity = (e) => {
    setCity(e.value);
  };

  const handleDaira = (e) => {
    setDaira(e.value);
  };
  const handleCommune = (e) => {
    setCommune(e.value);
  };

  const CustomTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: "#f8e9b8",
      primary50: "#e6b412",
      primary: "#e6b412",
    },
  });

  const handleSignUp = () => {
    // console.log(birth_date.current.value);
    // console.log(moment(birth_date.current.value).format("YYYY-MM-DD"));
    setShow(true);
    if (R_password.current.value === password.current.value) {
      dispatch(
        register({
          first_name: first_name.current.value,
          last_name: last_name.current.value,
          birth_date: moment(birth_date.current.value).format("YYYY-MM-DD"),
          commune_id: commune_id,
          phone: phone.current.value,
          email: email.current.value,
          password: password.current.value,
          lang: currentLanguageCode,
        })
      );
      // console.log(message);
    } else dispatch(setMessage([t("passwordNotMatch"), "Failed"]));
    setTimeout(() => {
      setShow(false);
    }, 10000);
  };
  // console.log("Daira => ", dairas.filter((d) => d.value === daira)[0]);
  // console.log("Commune => ", communes.filter((d) => d.value === commune_id)[0]);
  // useEffect(() => {
  //   message[0] === 200 && navigate("/login");
  // }, [message, navigate]);
  return (
    <div>
      <NavBar register="hidden" />
      <div className="max-w-xs md:max-w-screen-md my-10 min-h-screen lg:max-w-screen-lg lg:px-20 lg:inset-x-0 mx-auto">
        <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div className="flex items-center justify-center w-full md:px-4 col-span-1">
            <div className="block w-full">
              <p className="font-Saira text-center font-extrabold text-4xl text-darkGray ">
                {t("Registration")}
              </p>

              <p
                onClick={() => navigate("/login")}
                className="font-Saira text-center text-lightGray hover:underline hover:text-yellowPrimary font-normal cursor-pointer mb-10"
              >
                {t("OrLogin")}
              </p>

              <p className="text-md mb-2">
                {t("ObligationFields").split(" * ")[0]}
                <span className="text-red-500"> * </span>
                {t("ObligationFields").split(" * ")[1]}
              </p>
              <div className="mb-3 ">
                <label
                  htmlFor="Firstname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">* </span>
                  {t("Firstname")}
                </label>
                <input
                  type="text"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="firstname"
                  ref={first_name}
                  placeholder={t("Firstname")}
                  required
                />
              </div>
              <div className="mb-3 ">
                <label
                  htmlFor="Famillyname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("Famillyname")}
                </label>
                <input
                  type="text"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="Famillyname"
                  ref={last_name}
                  placeholder={t("Famillyname")}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="bithdate"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  {t("Birth_date")}
                </label>
                <input
                  type="date"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="Birth_date"
                  ref={birth_date}
                  placeholder={t("Birth_date")}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="fullname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("TheState")}
                </label>
                <Select
                  className="font-Saira font"
                  theme={CustomTheme}
                  placeholder={t("TheState")}
                  options={wilayas}
                  onChange={handleCity}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="fullname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("Daira")}
                </label>
                <Select
                  className="font-Saira font"
                  theme={CustomTheme}
                  placeholder={t("Daira")}
                  options={dairas}
                  value={dairas.filter((d) => d.value === daira)[0] || ""}
                  onChange={handleDaira}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="fullname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("Municipal")}
                </label>
                <Select
                  className="font-Saira font"
                  theme={CustomTheme}
                  placeholder={t("Municipal")}
                  options={communes}
                  value={
                    dairas.filter((d) => d.value === daira)[0] === undefined
                      ? ""
                      : communes.filter((d) => d.value === commune_id)[0] || ""
                  }
                  onChange={handleCommune}
                  required
                />
              </div>
              <div className="mb-3 ">
                <label
                  htmlFor="phone"
                  className="form-label font-normal font-Saira inline-block mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("PhoneNumber")}
                </label>
                <input
                  type="number"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="phone"
                  ref={phone}
                  placeholder={t("PhoneNumber")}
                  required
                />
              </div>
              <div className="mb-3 ">
                <label
                  htmlFor="email"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  {t("Email")}
                </label>
                <input
                  type="email"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="email"
                  ref={email}
                  placeholder={t("Email")}
                  required
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="pass"
                  className="form-label font-normal font-Saira inline-block mb-1 mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("Password")}
                </label>
                <input
                  type="password"
                  ref={password}
                  className="
                  block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="pass"
                  placeholder={t("Password")}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="Rpass"
                  className="form-label font-normal font-Saira inline-block mb-1 mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("RepeatPassword")}
                </label>
                <input
                  type="password"
                  ref={R_password}
                  className="
                  block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="Rpass"
                  placeholder={t("RepeatPassword")}
                  required
                />
              </div>
              <p className="font-extrabold mb-2 mt-4">
                {t("AgreeTerms")
                  .split(" - ")
                  .map((cond, i) =>
                    i === 1 ? (
                      <span
                        onClick={() => window.open("/terms", "_self")}
                        key={i}
                        className="hover:underline cursor-pointer text-blue-500 font-normal"
                      >
                        &nbsp;{cond}&nbsp;
                      </span>
                    ) : (
                      <span key={i}>&nbsp;{cond}&nbsp;</span>
                    )
                  )}
              </p>
              <div className="flex justify-between">
                <Button
                  onClick={handleSignUp}
                  custom="text-xl px-10 mt-5 block"
                  title={t("Register")}
                />
                <Button
                  onClick={() =>
                    window.open(
                      "http://api.whatsapp.com/send?phone=213$".replace(
                        "$",
                        Settings &&
                          Settings?.social_media?.whatsapp.substring(
                            1,
                            Settings?.social_media?.whatsapp?.length
                          )
                      )
                    )
                  }
                  custom="px-8 mt-5 bg-lightGray text-white"
                  title={t("GetSupport")}
                />
              </div>
              <div className="mt-4">
                {message ? (
                  message[1] === "success" ? (
                    show && (
                      <Alert
                        type={message[1]}
                        content={message[0]}
                        loginLink="block"
                      />
                    )
                  ) : (
                    show && <Alert type={message[1]} content={message[0]} />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <ManCheck className="col-span-1 hidden md:block my-8" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignUp;
