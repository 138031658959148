import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AuthAgentService from "../../Service/authAgent.service";
import { setMessage } from "./message";

export const getAgentsNames = createAsyncThunk(
  "authagent/getAuthAgentsNames",
  async (thunkAPI) => {
    try {
      const response = await AuthAgentService.getAgentsNames();
      return { Agents_names: response.data };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const AssignSellerAgent = createAsyncThunk(
  "authagent/AssignSellerAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthAgentService.AssignSellerAgent(
        payload.command_id,
        payload.agent_id
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
    } catch (error) {
      // console.log("log error register ", error.response);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const AssignClientAgent = createAsyncThunk(
  "authagent/AssignSellerAgent",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthAgentService.AssignClientAgent(
        payload.command_id,
        payload.agent_id
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
    } catch (error) {
      // console.log("log error register ", error.response);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const sendVerficationDocs = createAsyncThunk(
  "authagent/sendVerficationDocs",
  async (payload, thunkAPI) => {
    try {
      const response = await AuthAgentService.sendVerficationDocs(
        payload.formData,
        payload.doctype
      );
      // console.log("slice ,,,,,", response);
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      return response.status;
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

const authAgentSlice = createSlice({
  name: "authAgent",
  initialState: { Agents_names: [], isLoading: false },
  extraReducers: {
    [getAgentsNames.fulfilled]: (state, action) => {
      state.Agents_names = action.payload.Agents_names;
    },
    [sendVerficationDocs.pending]: (state, action) => {
      state.isLoading = true;
    },
    [sendVerficationDocs.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [sendVerficationDocs.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

const { reducer } = authAgentSlice;
export default reducer;
