import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { ReactComponent as LoginSvg } from "../../assets/login1.svg";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button.compenent";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/reducers/authSlice";
import { getUserType } from "../../utils/helpers";
import { clearMessage } from "../../redux/reducers/message";
import Alert from "../../components/Alert";
import Cookies from "js-cookie";
import { getSettings } from "../../redux/reducers/adminSlice";

function Login() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const email = useRef();
  const password = useRef();
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const { message } = useSelector((state) => state.message);
  const { Settings } = useSelector((state) => state.admin);
  const [show, setShow] = useState(true);

  const handleLogin = () => {
    setShow(true);
    dispatch(
      login({
        email: email.current.value,
        password: password.current.value,
        lang: currentLanguageCode,
      })
    );
    setTimeout(() => {
      setShow(false);
    }, 7000);
  };

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  if (getUserType() === "notVerified") {
    if (JSON.parse(localStorage.getItem("user")).type === "client")
      return <Navigate to="/confirmation" />;
    else {
      return <Navigate to="/verification" />;
    }
  } else if (isAuth) {
    // console.log(getUserType())
    if (getUserType() === "agent") return <Navigate to="/agent/commands" />;
    if (getUserType() === "auth-agent")
      return <Navigate to="/auth-agent/cars-commands" />;
    if (getUserType() === "admin")
      return <Navigate to="/admin/cars-commands" />;
    if (getUserType() === "blogger")
      return <Navigate to="/blogger/information" />;
    if (getUserType() === "client") return <Navigate to="/plans" />;
    return <Navigate to="/" />;
  }

  return (
    <div>
      <NavBar login="hidden" />
      <div className="max-w-xs md:max-w-screen-md lg:max-w-screen-lg lg:px-20 lg:inset-x-0 mx-auto">
        <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div className="flex items-center justify-center md:h-screen py-10 w-full md:px-4 col-span-1">
            <div className="block w-full">
              <p className="font-Saira text-center font-extrabold text-4xl text-darkGray mb-10">
                {t("Login")}
              </p>
              {/* <div className="block md:hidden ">
                <LoginSvg className="my-8" />
              </div> */}
              <div className="mb-3 ">
                <label
                  htmlFor="email"
                  className="form-label font-normal font-Saira inline-block mb-1 text-darkGray"
                >
                  {t("Email/Phone")}
                </label>
                <input
                  type="text"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="email"
                  ref={email}
                  placeholder="trustal@example.com"
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="pass"
                  className="form-label font-normal font-Saira inline-block mb-1 text-darkGray"
                >
                  {t("Password")}
                </label>
                <input
                  type="password"
                  className="
                    block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="pass"
                  ref={password}
                  placeholder={t("Password")}
                />
              </div>
              <button
                onClick={() => {
                  return navigate("/restpassword");
                }}
                className="font-extrabold hover:underline cursor-pointer mb-5"
              >
                {t("Forget_Password")}
              </button>
              <div className="flex justify-between">
                <Button
                  custom="text-xl px-10 mt-5 block"
                  title={t("Login")}
                  onClick={handleLogin}
                />
                <Button
                  custom="px-8 mt-5 bg-lightGray text-white"
                  onClick={() =>
                    window.open(
                      "http://api.whatsapp.com/send?phone=213$".replace(
                        "$",
                        Settings &&
                          Settings?.social_media?.whatsapp.substring(
                            1,
                            Settings?.social_media?.whatsapp?.length
                          )
                      )
                    )
                  }
                  title={t("GetSupport")}
                />
              </div>
              <div className="mt-4">
                {message ? (
                  show && <Alert type="Failed" content={message} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <LoginSvg className="col-span-1 hidden md:block my-8" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
