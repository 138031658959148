import moment from "moment/moment";
import * as React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button.compenent";
import testImage from "../../../assets/testImage.png";

export default function Modal(props) {
  const [showModal, setShowModal] = React.useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Button title={props.title} onClick={() => setShowModal(true)} />

      {showModal ? (
        <>
          <div className="justify-center items-center max-h-min flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative md:w-5/12 my-6 mx-auto max-w-2xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {props.Agents.last_name + " " + props.Agents.first_name}
                  </h3>
                  <button
                    className="p-1 bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-4xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto overflow-x-hidden overflow-scroll h-96">
                <img className='mx-auto rounded-full w-40 h-40' src={props.Agents.img !== "" ? props.Agents.img : testImage } alt="profile"/>
                  <div className="flex flex-col w-full">
                    <div className="sm:-mx-6 lg:-mx-8 inset-0">
                      <div className="py-2 inline-block w-full px-4 sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                          <table className="w-full font-Saira">
                            <tbody>
                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Famillyname")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {props.Agents.last_name}
                                </td>
                              </tr>

                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Firstname")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {props.Agents.first_name}
                                </td>
                              </tr>

                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Birth_date")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {moment(props.Agents.birth_date).format("LL")}
                                </td>
                              </tr>

                               <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("TheState")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {props.Agents.city}
                                </td>
                              </tr> 

                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("PhoneNumber")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {props.Agents.phone}
                                </td>
                              </tr>

                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Email")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {props.Agents.email}
                                </td>
                              </tr>

                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    {t("Date_Creation")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {moment(props.Agents.createAt).format("LL")}
                                </td>
                              </tr>
                              <br />
                              <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                {t("Status")} &nbsp;&nbsp;
                              </p>
                              {/* <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    &nbsp;&nbsp;{t("Active")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {props.Agents.status.active === true
                                    ? t("yes")
                                    : t("no")}
                                </td>
                              </tr> */}
                              <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    &nbsp;&nbsp;{t("Verfied")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {props.Agents.status.verified === true
                                    ? t("yes")
                                    : t("no")}
                                </td>
                              </tr>
                              {/* <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                <td className="text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  <p className="form-label font-normal font-Saira inline-block  mt text-darkGray">
                                    &nbsp;&nbsp;{t("AuthAgent")} :&nbsp;&nbsp;
                                  </p>
                                </td>
                                <td className=" text-gray-900 font-normal  py-4 whitespace-nowrap">
                                  {props.Agents.auth_agent_last_name +
                                    " " +
                                    props.Agents.auth_agent_first_name}
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-normal uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
