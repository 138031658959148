import React from "react";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { Navigate, Outlet } from "react-router-dom";
import { getUserType } from "../../utils/helpers";

function Index() {
  if (getUserType() !== "client") return <Navigate to="/login" />;
  return (
    <div>
      <NavBar />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Index;
