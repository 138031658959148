import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button.compenent";
import {
  getOffer,
  getReport,
  sendReport,
} from "../redux/reducers/requestSlice";
import Compressor from "compressorjs";
import { CircularProgress } from "@mui/material";
import { clearMessage } from "../redux/reducers/message";
import Alert from "../components/Alert";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ImageViewer from "../components/ImageViewer";
import { getUserType } from "../utils/helpers";
import ReportSkeletone from "../components/Skeleton/ReportSkeletone";

function Report(props) {
  const { t } = useTranslation();
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const dispatch = useDispatch();
  const { offer } = useSelector((state) => state.request);
  const { isLoading } = useSelector((state) => state.request);
  const { isLoadingReport } = useSelector((state) => state.request);
  const { Report } = useSelector((state) => state.request);
  const { message } = useSelector((state) => state.message);
  const [changed, setChanged] = useState([]);
  const [res, setRes] = useState({});
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [descriptions, setDescriptions] = useState({
    car_information: {},
    mechanical: {},
    mechanical_check: {},
    interior: {},
    interior_check: {},
    exterior: {},
    exterior_check: {},
    url: "",
  });
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getOffer({
        plan_id: location.state.plan_id,
        lang: currentLanguageCode,
      })
    );
  }, [dispatch, currentLanguageCode, location]);

  useEffect(() => {
    dispatch(
      getReport({
        command_id: id,
      })
    );
    dispatch(clearMessage());
  }, [dispatch, id]);

  const handleCompressedUpload = (e, name) => {
    Object.keys(e.target.files).map(
      (f, index) =>
        new Compressor(e.target.files[index], {
          quality: 0.6,
          success: (compressedResult) => {
            compressedResult.size < 5242880 &&
              setRes({ ...res, [name]: { compressedResult, name } });
          },
        })
    );
  };

  // console.log(Report);

  const handleSubmit = () => {
    setShow(true);
    const formData = new FormData();
    formData.append("command_id", id);
    formData.append("descriptions", JSON.stringify(descriptions));
    formData.append("lang", currentLanguageCode);

    Object.keys(res).map((e) =>
      formData.append("uploadedImages", res[e].compressedResult, res[e].name)
    );

    dispatch(
      sendReport({
        formData,
      })
    );
    // setTimeout(() => {
    // setShow(false);
    // }, 7000);
  };

  message &&
    message[1] === "success" &&
    navigate("/" + getUserType() + "/cars-commands");
  console.log(offer);

  return (
    <div className="w-full md:mt-8">
      {!isLoadingReport ? (
        <div className=" md:max-w-screen-md mt-5 lg:max-w-screen-lg lg:px-10 lg:inset-x-0">
          <p className="font-Saira my-4 mb-6 font-extrabold text-4xl text-darkGray ">
            {t("Report")}
          </p>

          <ImageViewer url={url} open={open} setOpen={setOpen} />

          {offer && offer.car_information && (
            <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
              <div className="flex flex-col w-full min-h-full text-sm ">
                <div className="sm:-mx-6 lg:-mx-8 inset-0">
                  <div className="inline-block w-full ">
                    <div className="overflow-x-scroll md:overflow-hidden">
                      <table className="w-full font-Saira">
                        <>
                          <thead className=" bg-darkGray">
                            {
                              <tr>
                                <th
                                  colSpan={4}
                                  className="text-sm font-normal text-white px-6 py-2 rounded-t-md"
                                >
                                  {t("car_information")}
                                </th>
                              </tr>
                            }
                          </thead>
                          <tbody>
                            {Object.keys(offer.car_information).map(
                              (i, index) => {
                                return (
                                  <>
                                    <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                      <td className="text-black font-normal py-4 whitespace-nowrap">
                                        <p className="form-label font-normal font-Saira inline-block w-full text-darkGray px-2">
                                          {offer.car_information[i]}
                                        </p>
                                      </td>

                                      <td className={`font-normal px-2 w-full`}>
                                        <input
                                          type="text"
                                          className="block w-3/5 mx-auto px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                                          id={offer.car_information[i]}
                                          defaultValue={
                                            Report && Report.car_information[i]
                                          }
                                          onChange={(e) => {
                                            let json = descriptions;
                                            json.car_information[i] =
                                              e.target.value;
                                            setDescriptions(json);
                                          }}
                                          placeholder={t("Description")}
                                          required
                                        />
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                          </tbody>
                        </>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <br />
          <p className="text-md mb-2">{t("CheckBox")}</p>
          {offer && offer.interior && (
            <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
              <div className="flex flex-col w-full min-h-full text-sm ">
                <div className="sm:-mx-6 lg:-mx-8 inset-0">
                  <div className="inline-block w-full ">
                    <div className="overflow-x-scroll md:overflow-hidden">
                      <table className="w-full font-Saira">
                        <>
                          <thead className=" bg-darkGray">
                            {
                              <tr>
                                <th
                                  colSpan={4}
                                  className="text-sm font-normal text-white px-6 py-2 rounded-t-md"
                                >
                                  {t("interior")}
                                </th>
                              </tr>
                            }
                          </thead>
                          <tbody>
                            {Object.keys(offer.interior).map((i, index) => {
                              return (
                                <>
                                  <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                    <td className="text-black font-normal py-4 whitespace-nowrap">
                                      <p className="form-label font-normal font-Saira inline-block w-full text-darkGray px-2">
                                        {offer.interior[i]}
                                      </p>
                                    </td>
                                    <td className=" text-black font-normal py-4 whitespace-nowrap">
                                      {
                                        <div>
                                          <p className="form-label font-normal font-Saira inline-block text-darkGray">
                                            {t("Status")}
                                          </p>
                                          <input
                                            type="checkbox"
                                            id={offer.interior[i]}
                                            defaultChecked={
                                              Report &&
                                              Report.interior[i].status
                                            }
                                            onChange={(e) => {
                                              let json = descriptions;
                                              json.interior_check[i] = e.target
                                                .checked
                                                ? "on"
                                                : "off";
                                              setDescriptions(json);
                                            }}
                                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                                          />
                                        </div>
                                      }
                                    </td>
                                    <td className={`font-normal px-2`}>
                                      <input
                                        type="text"
                                        className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                                        id={offer.interior[i]}
                                        defaultValue={
                                          Report &&
                                          Report.interior[i].description
                                        }
                                        onChange={(e) => {
                                          let json = descriptions;
                                          json.interior[i] = e.target.value;
                                          setDescriptions(json);
                                        }}
                                        placeholder={t("Description")}
                                        required
                                      />
                                    </td>
                                    <td
                                      className={`text-black inline-flex mx-auto font-normal`}
                                    >
                                      <div>
                                        <input
                                          className="block w-56 px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                                          accept="image/png, image/jpg, image/jpeg"
                                          type="file"
                                          id={i + "."}
                                          onChange={(e) => {
                                            setChanged((state) => [
                                              ...state,
                                              offer.interior[i],
                                            ]);
                                            handleCompressedUpload(
                                              e,
                                              "interior." + i
                                            );
                                          }}
                                          style={{ display: "none" }}
                                        />
                                        <label htmlFor={i + "."}>
                                          {changed.find(
                                            (e) => e === offer.interior[i]
                                          ) ? (
                                            <svg
                                              className="w-8 h-8 fill-current text-green-600"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                                            </svg>
                                          ) : (
                                            <svg
                                              className="w-8 h-8 fill-current text-yellowDark cursor-pointer"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                            </svg>
                                          )}
                                        </label>
                                      </div>
                                      {Report &&
                                        Report.interior[i].image_url && (
                                          <div className="md:w-12">
                                            <Button
                                              title={t("ViewImage")}
                                              onClick={() => {
                                                setUrl(
                                                  Report.interior[i].image_url
                                                );

                                                setOpen(true);
                                              }}
                                            />
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <br />
          <p className="text-md mb-2">{t("CheckBox")}</p>
          {offer && offer.exterior && (
            <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
              <div className="flex flex-col w-full min-h-full text-sm ">
                <div className="sm:-mx-6 lg:-mx-8 inset-0">
                  <div className="inline-block w-full ">
                    <div className="overflow-x-scroll md:overflow-hidden">
                      <table className="w-full font-Saira">
                        <>
                          <thead className=" bg-darkGray">
                            {
                              <tr>
                                <th
                                  colSpan={4}
                                  className="text-sm font-normal text-white px-6 py-2 rounded-t-md"
                                >
                                  {t("exterior")}
                                </th>
                              </tr>
                            }
                          </thead>
                          <tbody>
                            {Object.keys(offer.exterior).map((i, index) => {
                              return (
                                <>
                                  <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                    <td className="text-black font-normal py-4 whitespace-nowrap">
                                      <p className="form-label font-normal font-Saira inline-block w-full text-darkGray px-2">
                                        {offer.exterior[i]}
                                      </p>
                                    </td>
                                    <td className=" text-black font-normal py-4 whitespace-nowrap">
                                      {
                                        <div className="">
                                          <p className="form-label font-normal font-Saira inline-block text-darkGray">
                                            {t("Status")}
                                          </p>
                                          <input
                                            type="checkbox"
                                            id={offer.exterior[i]}
                                            defaultChecked={
                                              Report &&
                                              Report.exterior[i].status
                                            }
                                            onChange={(e) => {
                                              let json = descriptions;
                                              json.exterior_check[i] = e.target
                                                .checked
                                                ? "on"
                                                : "off";
                                              setDescriptions(json);
                                            }}
                                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                                          />
                                        </div>
                                      }
                                    </td>
                                    <td className={`font-normal px-2`}>
                                      <input
                                        type="text"
                                        className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                                        id={offer.exterior[i]}
                                        defaultValue={
                                          Report &&
                                          Report.exterior[i].description
                                        }
                                        onChange={(e) => {
                                          let json = descriptions;
                                          json.exterior[i] = e.target.value;
                                          setDescriptions(json);
                                        }}
                                        placeholder={t("Description")}
                                        required
                                      />
                                    </td>

                                    <td
                                      className={`text-black inline-flex mx-auto font-normal`}
                                    >
                                      <div>
                                        <input
                                          className="block w-56 px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                                          accept="image/png, image/jpg, image/jpeg"
                                          type="file"
                                          id={i}
                                          onChange={(e) => {
                                            setChanged((state) => [
                                              ...state,
                                              offer.exterior[i],
                                            ]);
                                            handleCompressedUpload(
                                              e,
                                              "exterior." + i
                                            );
                                          }}
                                          style={{ display: "none" }}
                                        />
                                        <label htmlFor={i}>
                                          {changed.find(
                                            (e) => e === offer.exterior[i]
                                          ) ? (
                                            <svg
                                              className="w-8 h-8 fill-current text-green-600"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                                            </svg>
                                          ) : (
                                            <svg
                                              className="w-8 h-8 fill-current text-yellowDark cursor-pointer"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                            </svg>
                                          )}
                                        </label>
                                      </div>
                                      {Report &&
                                        Report.exterior[i].image_url && (
                                          <div className="md:w-12">
                                            <Button
                                              title={t("ViewImage")}
                                              onClick={() => {
                                                setUrl(
                                                  Report.exterior[i].image_url
                                                );
                                                setOpen(true);
                                              }}
                                            />
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <br />
          <p className="text-md mb-2">{t("CheckBox")}</p>
          {offer && offer.mechanical && (
            <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
              <div className="flex flex-col w-full min-h-full text-sm ">
                <div className="sm:-mx-6 lg:-mx-8 inset-0">
                  <div className="inline-block w-full ">
                    <div className="overflow-x-scroll md:overflow-hidden">
                      <table className="w-full font-Saira">
                        <>
                          <thead className=" bg-darkGray">
                            {
                              <tr>
                                <th
                                  colSpan={4}
                                  className="text-sm font-normal text-white px-6 py-2 rounded-t-md"
                                >
                                  {t("mechanical")}
                                </th>
                              </tr>
                            }
                          </thead>
                          <tbody>
                            {Object.keys(offer.mechanical).map((i, index) => {
                              return (
                                <>
                                  <tr className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                                    <td className="text-black font-normal py-4 whitespace-nowrap">
                                      <p className="form-label font-normal font-Saira inline-block w-full text-darkGray px-2">
                                        {offer.mechanical[i]}
                                      </p>
                                    </td>
                                    <td className=" text-black font-normal py-4 whitespace-nowrap">
                                      {
                                        <div className="">
                                          <p className="form-label font-normal font-Saira inline-block text-darkGray">
                                            {t("Status")}
                                          </p>
                                          <input
                                            type="checkbox"
                                            id={offer.mechanical[i]}
                                            defaultChecked={
                                              Report &&
                                              Report.mechanical[i].status
                                            }
                                            onChange={(e) => {
                                              let json = descriptions;
                                              json.mechanical_check[i] = e
                                                .target.checked
                                                ? "on"
                                                : "off";
                                              setDescriptions(json);
                                            }}
                                            className="form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-green-600 checked:border-green-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain mr-2 cursor-pointer"
                                          />
                                        </div>
                                      }
                                    </td>
                                    <td className={`font-normal px-2 `}>
                                      <input
                                        type="text"
                                        className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                                        id={offer.mechanical[i]}
                                        defaultValue={
                                          Report &&
                                          Report.mechanical[i].description
                                        }
                                        onChange={(e) => {
                                          let json = descriptions;
                                          json.mechanical[i] = e.target.value;
                                          setDescriptions(json);
                                        }}
                                        placeholder={t("Description")}
                                        required
                                      />
                                    </td>
                                    <td
                                      className={`text-black inline-flex mx-auto font-normal`}
                                    >
                                      <div>
                                        <input
                                          className="block w-56 px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                                          accept="image/png, image/jpg, image/jpeg"
                                          type="file"
                                          id={i + ".."}
                                          onChange={(e) => {
                                            setChanged((state) => [
                                              ...state,
                                              offer.mechanical[i],
                                            ]);
                                            handleCompressedUpload(
                                              e,
                                              "mechanical." + i
                                            );
                                          }}
                                          style={{ display: "none" }}
                                        />
                                        <label htmlFor={i + ".."}>
                                          {changed.find(
                                            (e) => e === offer.mechanical[i]
                                          ) ? (
                                            <svg
                                              className="w-8 h-8 fill-current text-green-600"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z" />
                                            </svg>
                                          ) : (
                                            <svg
                                              className="w-8 h-8 fill-current text-yellowDark cursor-pointer"
                                              fill="currentColor"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                                            </svg>
                                          )}
                                        </label>
                                      </div>
                                      {Report &&
                                        Report.mechanical[i].image_url && (
                                          <div className="md:w-12">
                                            <Button
                                              title={t("ViewImage")}
                                              onClick={() => {
                                                setUrl(
                                                  Report.mechanical[i].image_url
                                                );
                                                setOpen(true);
                                              }}
                                            />
                                          </div>
                                        )}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <br />

          <div className="flex items-center justify-center w-full md:px-4 col-span-1 mb-10 sm:mb-0">
            <div className="flex flex-col w-full min-h-full text-sm ">
              <div className="sm:-mx-6 lg:-mx-8 inset-0">
                <div className="inline-block w-full ">
                  <div className="overflow-x-scroll md:overflow-hidden">
                    <table className="w-full font-Saira">
                      <thead className=" bg-darkGray">
                        {
                          <tr>
                            <th
                              colSpan={4}
                              className="text-sm font-normal text-white px-6 py-2 rounded-t-md"
                            >
                              {t("Video")}
                            </th>
                          </tr>
                        }
                      </thead>
                      <tbody>
                        <td className="text-black font-normal py-4 whitespace-nowrap">
                          <p className="form-label font-normal font-Saira inline-block w-full text-darkGray px-2">
                            {t("Video")}
                          </p>
                        </td>
                        <td className={`font-normal px-2 `}>
                          <input
                            type="text"
                            className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                            id="video"
                            defaultValue={Report && Report.video_url}
                            onChange={(e) => {
                              let json = descriptions;
                              json["url"] = e.target.value;
                              setDescriptions(json);
                            }}
                            placeholder={t("Description")}
                            required
                          />
                        </td>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="mb-4 ">
            {/* {console.log(message)} */}
            {!isLoading && message ? (
              message[1] === "success" ? (
                show && <Alert type={message[1]} content={message} />
              ) : (
                show && <Alert type={message[1]} content={message} />
              )
            ) : (
              <></>
            )}
          </div>
          <div className="w-full flex place-content-center">
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={handleSubmit}
                title={t("Submit")}
                className="bg-black w-full"
              />
            )}
          </div>
          <br />
        </div>
      ) : (
        <div className="md:max-w-screen-md mt-5 lg:max-w-screen-lg h-full lg:px-10 lg:inset-x-0">
          <ReportSkeletone />
        </div>
      )}
    </div>
  );
}

export default Report;
