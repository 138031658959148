export const COLUMNS = [
  {
    Header: "command_id",
    Footer: "command_id",
    accessor: "command_id",
  },
  {
    Header: "vin",
    Footer: "vin",
    accessor: "vin",
  },
  {
    Header: "car_brand",
    Footer: "car_brand",
    accessor: "car_brand",
  },
  {
    Header: "car_color",
    Footer: "car_color",
    accessor: "car_color",
  },
  {
    Header: "car_model",
    Footer: "car_model",
    accessor: "car_model",
  },
  {
    Header: "car_plate",
    Footer: "car_plate",
    accessor: "car_plate",
  },
  {
    Header: "gazoline_type",
    Footer: "gazoline_type",
    accessor: "gazoline_type",
  },
  {
    Header: "kilometer_number",
    Footer: "kilometer_number",
    accessor: "kilometer_number",
  },
  {
    Header: "manufacture_year",
    Footer: "manufacture_year",
    accessor: "manufacture_year",
  },
  {
    Header: "Reports",
    Footer: "reports",
    accessor: "reports",
  },
];
