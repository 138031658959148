import axios from "axios";
import { getToken, getUserId, getUserType } from "../utils/helpers";

const API_URL = process.env.REACT_APP_API;

const getProfile = (id, token, lang, type) => {
  return axios.get(API_URL + type.replace("_", "-") + "/" + id + "/" + lang, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateProfile = (
  first_name,
  last_name,
  birth_date,
  commune_id,
  id,
  token,
  lang,
  type
) => {
  const body =
    getUserType() === "admin"
      ? {
          first_name,
          last_name,
          birth_date,
          city: commune_id,
          lang,
        }
      : {
          first_name,
          last_name,
          birth_date,
          commune_id,
          lang,
        };
  return axios.post(API_URL + type.replace("_", "-") + "/update/" + id, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const upadteProfilePic = (formData) => {
  return axios.post(
    API_URL + getUserType() + "/photo/" + getUserId(),
    formData,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const resetPassword = (password, old_password, id, token, lang, type) => {
  return axios.post(
    API_URL + type.replace("_", "-") + "/change-password/" + id,
    {
      password,
      old_password,
      lang,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const changeEmail = (email, id, token, lang, type) => {
  return axios.post(
    API_URL + type.replace("_", "-") + "/new-email/" + id,
    {
      email,
      lang,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const confirmEmail = (code, id, token, lang, type) => {
  return axios.post(
    API_URL + type.replace("_", "-") + "/confirm-new-email/" + id,
    {
      code,
      lang,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const ProfileService = {
  getProfile,
  updateProfile,
  resetPassword,
  changeEmail,
  confirmEmail,
  upadteProfilePic,
};
export default ProfileService;
