import axios from "axios";
import { getToken, getUserId, getUserType } from "../utils/helpers";

const API_URL = process.env.REACT_APP_API;

const getUnverifiedEmployees = (id, token, lang, type) => {
  return axios.get(API_URL + type + "/unverified/" + id + "/" + lang, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getAuthAgents = (id, token, lang, type) => {
  return axios.get(API_URL + type + "/auth-agent/all/" + id + "/" + lang, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getAgents = (id, token, lang, type) => {
  return axios.get(
    API_URL + type.replace("_", "-") + "/agent/all/" + id + "/" + lang,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getClients = (id, token, lang, type) => {
  return axios.get(API_URL + type + "/client/all/" + id + "/" + lang, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getS_admin = (id, token, lang, type) => {
  return axios.get(API_URL + type + "/sub-admin/all/" + id + "/" + lang, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const getBloggers = (id, token, lang, type) => {
  return axios.get(
    API_URL + type.replace("_", "-") + "/blogger/all/" + id + "/" + lang,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deactivateAuthAgent = (id, auth_agent_id, token, type) => {
  return axios.delete(
    API_URL +
      type.replace("_", "-") +
      "/" +
      id +
      "/?auth_agent_id=" +
      auth_agent_id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deactivateAgent = (id, agent_id, token, type) => {
  return axios.delete(
    API_URL + type.replace("_", "-") + "/" + id + "/?agent_id=" + agent_id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deactivateClient = (id, client_id, token, type) => {
  return axios.delete(
    API_URL + type.replace("_", "-") + "/" + id + "/?client_id=" + client_id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deactivateS_admin = (id, sub_admin_id, token, type) => {
  return axios.delete(
    API_URL + type.replace("_", "-") + "/" + id + "/?admin_id=" + sub_admin_id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const deactivateBlogger = (id, blogger_id, token, type) => {
  return axios.delete(
    API_URL + type.replace("_", "-") + "/" + id + "/?blogger_id=" + blogger_id,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const activateAuthAgent = (id, auth_agent_id, token, type) => {
  return axios.post(
    API_URL + type + "/activate-auth-agent/" + id + "/",
    {
      auth_agent_id: auth_agent_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const accept = (user_id, type) => {
  // console.log("asdfsad", user_id);
  return axios.post(
    API_URL + getUserType() + "/accept-" + type + "/" + getUserId() + "/",
    {
      user_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const decline = (user_id, type) => {
  return axios.post(
    API_URL + getUserType() + "/decline-" + type + "/" + getUserId() + "/",
    {
      user_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const activateAgent = (id, agent_id, token, type) => {
  return axios.post(
    API_URL + type + "/activate-agent/" + id + "/",
    {
      agent_id: agent_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const activateClient = (id, client_id, token, type) => {
  return axios.post(
    API_URL + type + "/activate-client/" + id + "/",
    {
      client_id: client_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const activateS_admin = (id, sub_admin_id, token, type) => {
  return axios.post(
    API_URL + type + "/activate-sub-admin/" + id + "/",
    {
      sub_admin_id: sub_admin_id,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const registerAuthAgent = (
  first_name,
  last_name,
  birth_date,
  city,
  communes,
  phone,
  email,
  lang
) => {
  return axios.post(
    API_URL + "admin/create-auth-agent/" + getUserId(),
    {
      first_name,
      last_name,
      birth_date,
      city,
      communes,
      phone,
      email,
      lang,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const registerAgent = (
  first_name,
  last_name,
  birth_date,
  city,
  phone,
  email,
  lang,
  auth_agent_id
) => {
  return axios.post(
    API_URL + getUserType().replace("_", "-") + "/create-agent/" + getUserId(),
    {
      first_name,
      last_name,
      birth_date,
      city,
      phone,
      email,
      lang,
      auth_agent_ID: auth_agent_id,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const registerS_admin = (
  first_name,
  last_name,
  birth_date,
  city,
  phone,
  email,
  lang
) => {
  return axios.post(
    API_URL + "admin/create-sub-admin/" + getUserId(),
    {
      first_name,
      last_name,
      birth_date,
      city,
      phone,
      email,
      lang,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const registerBlogger = (
  first_name,
  last_name,
  birth_date,
  city,
  phone,
  email,
  lang
) => {
  return axios.post(
    API_URL +
      getUserType().replace("_", "-") +
      "/create-blogger/" +
      getUserId(),
    {
      first_name,
      last_name,
      birth_date,
      city,
      phone,
      email,
      lang,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const getAuthAgentsNames = (id, token, lang, type, city) => {
  return axios.get(
    API_URL + type + "/auth-agent/names/" + id + "/" + lang + "?city=" + city,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

const getSettings = () => {
  return axios.get(API_URL + "settings/", {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};
const updateSettings = (data) => {
  return axios.post(
    API_URL + getUserType() + "/update-settings/" + getUserId(),
    data,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};
//api/admin/faq/:id/:FAQ_id
const deleteFaq = (id) => {
  return axios.delete(
    API_URL + getUserType() + "/faq/" + getUserId() + "/" + id,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const AdminService = {
  getUnverifiedEmployees,
  getAuthAgents,
  getAgents,
  getClients,
  getS_admin,
  getBloggers,
  getAuthAgentsNames,
  getSettings,
  deactivateAuthAgent,
  deactivateAgent,
  deactivateClient,
  deactivateS_admin,
  deactivateBlogger,
  deleteFaq,
  activateAuthAgent,
  activateAgent,
  activateClient,
  activateS_admin,
  registerAuthAgent,
  registerAgent,
  registerS_admin,
  registerBlogger,
  updateSettings,
  accept,
  decline,
};

export default AdminService;
