import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "../../../components/Button.compenent";
import Cookies from "js-cookie";
import {
  getSettings,
  updateSettings,
} from "../../../redux/reducers/adminSlice";
import Alert from "../../../components/Alert";

function Index() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Settings } = useSelector((state) => state.admin);
  const { message } = useSelector((state) => state.message);
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(true);
  const [show, setShow] = useState(true);
  const [whatsApp, setWhatsApp] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [email, setEmail] = useState("");
  const [locationEn, setLocationEn] = useState("");
  const [locationAr, setLocationAr] = useState("");
  const [locationFr, setLocationFr] = useState("");
  const [suggComp, setSuggComp] = useState("");
  const [termsAr, setTermsAr] = useState("");
  const [termsFr, setTermsFr] = useState("");
  const [termsEn, setTermsEn] = useState("");

  const [data, setData] = useState({
    location: {},
    faqs: [],
    terms: {},
  });

  const ref = useRef([]);

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  useEffect(() => {
    if (Settings && loading) {
      setFaqs(Settings.FAQs);
      setLoading(false);
    }
  }, [Settings, loading, faqs]);

  useEffect(() => {
    if (update) {
      setFaqs(faqs);
      setUpdate(false);
    }
  }, [update, faqs]);

  const handleAddFAQs = () => {
    // add condition not empty [{question {ar: , en: ,fr: }, answers {ar: , en:, fr: } }]
    const json = [...faqs];
    json.push({
      question: {
        ar: ref.current["faq_q_ar"].value,
        en: ref.current["faq_q_en"].value,
        fr: ref.current["faq_q_fr"].value,
      },
      answer: {
        ar: ref.current["faq_a_ar"].value,
        en: ref.current["faq_a_en"].value,
        fr: ref.current["faq_a_fr"].value,
      },
    });
    setFaqs(json);
  };

  // const json = useMemo(
  //   () => ({
  //     whatsapp: ref.current?.WhatsApp?.value,
  //     facebook: ref.current?.Facebook?.value,
  //     instagram: ref.current?.Instagram?.value,
  //     twitter: ref.current?.Twitter?.value,
  //     email: ref.current?.Email?.value,
  //     location: {
  //       en: ref.current?.["Location_en"]?.value,
  //       ar: ref.current?.["Location_ar"]?.value,
  //       fr: ref.current?.["Location_fr"]?.value,
  //     },
  //     sugg_comp: ref.current?.["Suggestions_complains"]?.value,
  //     terms: {
  //       ar: ref.current?.["Terms_ar"]?.value,
  //       fr: ref.current?.["Terms_fr"]?.value,
  //       en: ref.current?.["Terms_en"]?.value,
  //     },
  //     faqs: faqs,
  //   }),
  //   [faqs, ref]
  // );

  useEffect(() => {
    // setData(json);

    setData({
      whatsapp: whatsApp,
      facebook: facebook,
      instagram: instagram,
      twitter: twitter,
      linked_in: linkedin,
      email: email,
      location: {
        en: locationEn,
        ar: locationAr,
        fr: locationFr,
      },
      sugg_comp: suggComp,
      terms: {
        ar: termsAr,
        fr: termsFr,
        en: termsEn,
      },
      faqs: faqs,
    });
  }, [
    whatsApp,
    facebook,
    instagram,
    twitter,
    linkedin,
    email,
    locationAr,
    locationEn,
    locationFr,
    suggComp,
    termsAr,
    termsEn,
    termsFr,
    faqs,
    ref,
    setData,
  ]);

  const handleDeleteFAQ = (id) => {
    window.confirm(t("Confirm_alert_admin")) &&
      setFaqs(
        faqs.filter(({ question }) => id !== question[currentLanguageCode])
      ) &&
      Settings.FAQs.filter(
        ({ question }) => id !== question[currentLanguageCode]
      );
    setUpdate(true);
    setTimeout(() => {
      setShow(false);
    }, 7000);
  };

  const handleSubmit = () => {
    setShow(true);
    dispatch(updateSettings({ data }));
    setTimeout(() => {
      setShow(false);
    }, 7000);
  };
  // console.log(data);

  return (
    <div className="md:w-full m-4 font-Saira">
      <p className="font-Saira text-center my-4 mb-2 font-extrabold text-4xl text-darkGray ">
        {t("Settings")}
      </p>

      <p className="lg:max-w-screen-lg lg:inset-x-0 w-full mx-auto my-4 text-xl font-bold">
        {t("Social_media")}
      </p>
      <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
        <div className="mb-3 mx-1 w-full">
          <label
            htmlFor="WhatsApp"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("WhatsApp")}
          </label>
          <input
            type="number"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="WhatsApp"
            defaultValue={Settings?.social_media?.whatsapp}
            onChange={(e) => setWhatsApp(e.target.value)}
            placeholder={t("0550555050")}
            required
          />
        </div>
        <div className="mb-3 mx-1 w-full">
          <label
            htmlFor="Facebook"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("Facebook")}
          </label>
          <input
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Facebook"
            defaultValue={Settings?.social_media?.facebook}
            onChange={(e) => setFacebook(e.target.value)}
            placeholder="https://www.facebook.com/elonmusk"
            required
          />
        </div>
        <div className="mb-3 mx-1 w-full">
          <label
            htmlFor="Instagram"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("Instagram")}
          </label>
          <input
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Instagram"
            defaultValue={Settings?.social_media?.instagram}
            onChange={(e) => setInstagram(e.target.value)}
            placeholder="www.instagram.com/trustll.example"
            required
          />
        </div>
        <div className="mb-3 mx-1 w-full">
          <label
            htmlFor="Twitter"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("Twitter")}
          </label>
          <input
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Twitter"
            defaultValue={Settings?.social_media?.twitter}
            onChange={(e) => setTwitter(e.target.value)}
            placeholder="https://twitter.com/elonmusk"
            required
          />
        </div>
        <div className="mb-3 mx-1 w-full">
          <label
            htmlFor="LinkedIn"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("Linkedin")}
          </label>
          <input
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Linkedin"
            defaultValue={Settings?.social_media?.linked_in}
            onChange={(e) => setLinkedin(e.target.value)}
            placeholder="https://www.linkedin.com/company/elonmusk1232"
            required
          />
        </div>
        <div className="mb-3 mx-1 w-full">
          <label
            htmlFor="Email"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("Email")}
          </label>
          <input
            type="email"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Email"
            defaultValue={Settings?.social_media?.email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("trustall@example.com")}
            required
          />
        </div>
      </div>
      <hr className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto" />
      <br />
      <div className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto mb-2">
        <p
          htmlFor="Location"
          className="form-label font-bold font-Saira inline-block text-darkGray "
        >
          {t("Location")}
        </p>
      </div>
      <div className="md:grid md:gap-4 md:grid-cols-3 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto mb-2">
        <div className="mb-3 w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <p
            htmlFor="Arabic"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("Arabic")}
          </p>
          <input
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Arabic"
            defaultValue={Settings?.location?.ar}
            onChange={(e) => setLocationAr(e.target.value)}
            placeholder={t("Arabic")}
            required
          />
        </div>
        <div className="mb-3 w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <p
            htmlFor="English"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("English")}
          </p>
          <input
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="English"
            defaultValue={Settings?.location?.en}
            onChange={(e) => setLocationEn(e.target.value)}
            placeholder={t("English")}
            required
          />
        </div>
        <div className="mb-3 w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <p
            htmlFor="French"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("French")}
          </p>
          <input
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="French"
            defaultValue={Settings?.location?.fr}
            onChange={(e) => setLocationFr(e.target.value)}
            placeholder={t("French")}
            required
          />
        </div>
      </div>
      <div className="mb-3 w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
        <label
          htmlFor="Suggestions_complains"
          className="form-label font-bold font-Saira inline-block text-darkGray"
        >
          {t("Suggestions_complains")}
        </label>
        <input
          type="text"
          className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
          id="Suggestions_complains"
          defaultValue={Settings?.sugg_comp}
          onChange={(e) => setSuggComp(e.target.value)}
          placeholder="https://docs.google.com/forms/d/e/example"
          required
        />
      </div>
      <hr className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto" />
      <br />
      <div>
        <div className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <p className="form-label font-bold font-Saira inline-block text-darkGray">
            {t("FAQs")}
          </p>
        </div>
        <br />
        <div className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <p className="form-label font-bold font-Saira inline-block text-darkGray">
            {t("Question")}
          </p>
        </div>
        <div className="md:grid md:gap-4 md:grid-cols-3 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div>
            <label
              htmlFor="faq_ar"
              className="form-label font-bold font-Saira inline-block text-darkGray"
            >
              {t("Arabic")}
            </label>
            <textarea
              type="text"
              className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
              id="faq_ar"
              ref={(e) => (ref.current["faq_q_ar"] = e)}
              placeholder={t("Arabic")}
              required
            />
          </div>
          <div>
            <label
              htmlFor="faq_en"
              className="form-label font-bold font-Saira inline-block text-darkGray"
            >
              {t("English")}
            </label>
            <textarea
              type="text"
              className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
              id="faq_en"
              ref={(e) => (ref.current["faq_q_en"] = e)}
              placeholder={t("English")}
              required
            />
          </div>
          <div>
            <label
              htmlFor="faq_fr"
              className="form-label font-bold font-Saira inline-block text-darkGray"
            >
              {t("French")}
            </label>
            <textarea
              type="text"
              className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
              id="faq_fr"
              ref={(e) => (ref.current["faq_q_fr"] = e)}
              placeholder={t("French")}
              required
            />
          </div>
        </div>
        <hr className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto my-4" />
        {/* // answers */}
        <div className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <p className="form-label font-bold font-Saira inline-block text-darkGray">
            {t("Answer")}
          </p>
        </div>
        <div className="md:grid md:gap-4 md:grid-cols-3 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto ">
          <div>
            <label
              htmlFor="faq_ar"
              className="form-label font-bold font-Saira inline-block text-darkGray"
            >
              {t("Arabic")}
            </label>
            <textarea
              type="text"
              className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
              id="faq_ar"
              ref={(e) => (ref.current["faq_a_ar"] = e)}
              placeholder={t("Arabic")}
              required
            />
          </div>
          <div>
            <label
              htmlFor="faq_en"
              className="form-label font-bold font-Saira inline-block text-darkGray"
            >
              {t("English")}
            </label>
            <textarea
              type="text"
              className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
              id="faq_en"
              ref={(e) => (ref.current["faq_a_en"] = e)}
              placeholder={t("English")}
              required
            />
          </div>
          <div>
            <label
              htmlFor="faq_fr"
              className="form-label font-bold font-Saira inline-block text-darkGray"
            >
              {t("French")}
            </label>
            <textarea
              type="text"
              className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
              id="faq_fr"
              ref={(e) => (ref.current["faq_a_fr"] = e)}
              placeholder={t("French")}
              required
            />
          </div>
        </div>
        <div className="flex w-full place-content-center my-4">
          <Button title={t("Add")} onClick={handleAddFAQs} />
        </div>

        {!loading && (
          <div className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
            {faqs?.map((faq) => {
              return (
                <Accordion className="my-4">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography
                      style={{ fontFamily: ["saira", "Cairo", "sans-serif"] }}
                      className="w-full"
                    >
                      {faq?.question[currentLanguageCode]}
                    </Typography>
                    <div className="w-full"></div>
                    <Button
                      custom="bg-red-400 hover:text-white hover:bg-red-900"
                      title={t("delete")}
                      onClick={() =>
                        handleDeleteFAQ(faq?.question[currentLanguageCode])
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      style={{ fontFamily: ["saira", "Cairo", "sans-serif"] }}
                    >
                      {faq?.answer[currentLanguageCode]}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        )}
      </div>
      <hr className="w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto" />
      <br />
      <div className="mb-3 w-full lg:max-w-screen-lg lg:inset-x-0 mx-auto">
        <p
          htmlFor="Terms"
          className="form-label font-bold font-Saira inline-block text-darkGray"
        >
          {t("Terms")}
        </p>
        <div>
          <label
            htmlFor="Terms_ar"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("Arabic")}
          </label>
          <textarea
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Terms_ar"
            defaultValue={Settings?.terms?.ar}
            onChange={(e) => setTermsAr(e.target.value)}
            placeholder="بالعربية"
            required
          />
        </div>
        <div>
          <label
            htmlFor="Terms_en"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("English")}
          </label>
          <textarea
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Terms_en"
            defaultValue={Settings?.terms?.en}
            onChange={(e) => setTermsEn(e.target.value)}
            placeholder={t("English")}
            required
          />
        </div>
        <div>
          <label
            htmlFor="Terms_fr"
            className="form-label font-bold font-Saira inline-block text-darkGray"
          >
            {t("French")}
          </label>
          <textarea
            type="text"
            className="block px-3 w-full py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
            id="Terms_fr"
            defaultValue={Settings?.terms?.fr}
            onChange={(e) => setTermsFr(e.target.value)}
            placeholder={t("French")}
            required
          />
        </div>
      </div>

      <div className="flex w-full place-content-center mt-4">
        <Button title={t("Submit")} onClick={handleSubmit} />
      </div>
      <br />
      {message && message ? (
        message[1] === "success" ? (
          show && <Alert type={message[1]} content={message} />
        ) : (
          show && <Alert type={message[1]} content={message} />
        )
      ) : (
        <></>
      )}
    </div>
  );
}

export default Index;
