import { Menu, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
// import profile from "../assets/profile.png";
import "flag-icon-css/css/flag-icons.min.css"
import i18next from "i18next";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { direction } from "../../redux/reducers/directionSlice";
import { useTranslation } from "react-i18next";


const languages = [
    {
        code: 'en',
        name: 'English',
        country_code: "gb"
    },
    {
        code: 'fr',
        name: 'français',
        country_code: "fr"
    },
    {
        code: 'ar',
        name: 'العربية',
        country_code: "dz",
        dir: "rtl"
    },
]

export default function Dropdown(props) {
    const { t } = useTranslation();
    const currentLanguageCode = Cookies.get("i18next") || "ar";
    const currnetLanguage = languages.find((l)=>l.code === currentLanguageCode);
    const [langeCode, setLangeCode] = useState('');
    const dispatch = useDispatch();

    useEffect(() => {
      document.body.dir = currnetLanguage.dir || 'ltr'  ;
      document.title = t("title");
      dispatch(direction({
        dir: currnetLanguage.dir || 'ltr'
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currnetLanguage])


  return (
    <div >
      <Menu as="div" className="relative inline-block text-left z-50">
        <div>
          <Menu.Button className="inline-flex mt-3 w-full justify-center rounded-md hover:opacity-70  mx-2  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <span className={`flag-icon flag-icon-${langeCode || currnetLanguage.country_code}`}></span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className={`absolute ${currentLanguageCode === "ar" ? "left-0" : "right-0"} mt-2 w-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
           {languages.map(({code, name, country_code})=>(
            <div key={country_code} className="px-1 py-1 ">
            <Menu.Item>
              {
                <button
                  className={`
                    hover:bg-yellowPrimary text-gray-800 ${(code === currentLanguageCode) && 'bg-darkGray hover:bg-darkGray text-slate-100' } font-Saira font-normal group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    i18next.changeLanguage(code)
                    setLangeCode(country_code) ;                 
                    
                }}
                disabled={code === currentLanguageCode}
                >
                    <span className={`flag-icon flag-icon-${country_code} mx-2`}></span>
                  {name}
                </button>
                
              }
            </Menu.Item>
          </div>
          ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}