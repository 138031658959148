import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ProfileService from "../../Service/profile.service";
import { setMessage } from "./message";

export const getProfile = createAsyncThunk(
  "profile/getProfile",
  async (payload, thunkAPI) => {
    try {
      const response = await ProfileService.getProfile(
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      return { user: response.data.user };
    } catch (error) {
      thunkAPI.dispatch(setMessage(error.response.data.err));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateProfile = createAsyncThunk(
  "profile/updateProfile",
  async (payload, thunkAPI) => {
    try {
      const response = await ProfileService.updateProfile(
        payload.first_name,
        payload.last_name,
        payload.birth_date,
        payload.commune_id,
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
    } catch (error) {
      // console.log("response ProfileSlice : ", error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const upadteProfilePic = createAsyncThunk(
  "profile/upadteProfilePic",
  async (payload, thunkAPI) => {
    try {
      const response = await ProfileService.upadteProfilePic(payload.formData);
      // console.log("slice ,,,,,", response);
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
      // localStorage.setItem("user", JSON.stringify(user))
      const profile = {
        ...JSON.parse(localStorage.getItem("user")),
        img: response.data.img,
      };
      // console.log(response);
      localStorage.setItem("user", JSON.stringify(profile));
      return response.status;
    } catch (error) {
      // console.log(error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resetPassword = createAsyncThunk(
  "profile/resetPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await ProfileService.resetPassword(
        payload.new_password,
        payload.old_password,
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      thunkAPI.dispatch(setMessage([response.data.msg, "success"]));
    } catch (error) {
      // console.log("response ProfileSlice : ", error);
      thunkAPI.dispatch(setMessage([error.response.data.err, "Failed"]));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changeEmail = createAsyncThunk(
  "profile/changeEmail",
  async (payload, thunkAPI) => {
    try {
      const response = await ProfileService.changeEmail(
        payload.email,
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      // console.log("payload : ", payload);
      // console.log("res : ", response);
      thunkAPI.dispatch(setMessage([response.data.msg, "success", "changed"]));
    } catch (error) {
      // console.log("err ProfileSlice : ", error);
      thunkAPI.dispatch(
        setMessage([error.response.data.err, "Failed", "notChanged"])
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

export const confirmEmail = createAsyncThunk(
  "profile/changeEmail",
  async (payload, thunkAPI) => {
    try {
      const response = await ProfileService.confirmEmail(
        payload.code,
        payload.id,
        payload.token,
        payload.lang,
        payload.type
      );
      // console.log("payload : ", payload);
      // console.log("res : ", response);
      thunkAPI.dispatch(
        setMessage([response.data.msg, "success", "confirmed"])
      );
    } catch (error) {
      // console.log("err ProfileSlice : ", error);
      thunkAPI.dispatch(
        setMessage([error.response.data.err, "Failed", "notConfirmed"])
      );
      return thunkAPI.rejectWithValue();
    }
  }
);

const profileSlice = createSlice({
  name: "profile",
  initialState: { user: [] },
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      state.user = action.payload.user;
    },
    [getProfile.rejected]: (state, action) => {},
  },
});

const { reducer } = profileSlice;
export default reducer;
