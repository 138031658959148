import React from "react";
// import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

function Error404() {
  // const { t } = useTranslation();

  return (
    <>
      <Navigate to="/" />
      {/* <h1 className="font-Saira font-extrabold text-6xl h-screen w-screen flex items-center justify-center bg-darkGray text-white">
        {t("Error404")}
      </h1> */}
    </>
  );
}

export default Error404;
