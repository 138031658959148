export const COLUMNS = [
  {
    Header: "fullname",
    Footer: "fullname",
    accessor: "fullname",
  },
  {
    Header: "phonenumber",
    Footer: "phonenumber",
    accessor: "phonenumber",
  },
  {
    Header: "email",
    Footer: "email",
    accessor: "email",
  },
  {
    Header: "Address",
    Footer: "Address",
    accessor: "Address",
  },
  {
    Header: "",
    Footer: "preview",
    accessor: "preview",
  },
];
