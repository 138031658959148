import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dir: "ltr",
};

const directionSlice = createSlice({
  name: "direction",
  initialState,
  reducers: {
    direction: (state, action) => {
      state.dir = action.payload;
    },
  },
  extraReducers: {},
});

export const { direction } = directionSlice.actions;

export default directionSlice.reducer;
