import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button.compenent";
import { ReactComponent as PriceBg } from "../../assets/Vector1.svg";
import { useNavigate } from "react-router-dom";

function CardOffer(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  

  return (
    <div
      className={`relative container bg-darkGray font-Saira ${
        show ? "h-full" : "min-h-[42rem]"
      } rounded-2xl p-4  mb-4 shadow-lg font-medium`}
    >
      <p className="text-yellowDark text-xl font-medium text-center">{props.title}</p>
      {/* prices */}
      <div className="w-50 mx-4">
        <div className="relative h-8 flex justify-center">
          <PriceBg
            width="197"
            height="44"
            className="absolute"
            fill="#FFC814"
          />
          <p className="absolute text-white mt-1 text-2xl">{props.price}</p>
        </div>

        <div className="relative flex justify-items-end">
          <p className="w-full"></p>
          <div className="flex justify-center w-44 mx-4 -my-3">
            <PriceBg
              width="160"
              height="60"
              className="absolute"
              fill="#ffffff"
            />
            <p className="absolute mt-2 text-xl text-yellowDark">
              {props.mobPrice}
            </p>
            <p className="absolute mt-7 block font-normal text-sm w-full text-center text-yellowDark">
              {props.baridiMob}
            </p>
          </div>
        </div>
      </div>

      <p className="text-sm font-medium mt-14 px-2">{props.description}</p>
      <div
        className={`text-base font-normal mt-4 px-2 ${show ? "mb-40" : "mb-40"}`}
      >
        {props.features.split(" - ").map((feature, i) => {
          return <div key={i}>{i <= 10 && <p>{"- " + feature}</p>}</div>;
        })}
        {show &&
          props.features.split(" - ").map((feature, i) => {
            return <div key={i}>{i > 10 && <p>{"- " + feature}</p>}</div>;
          })}
        <button
          onClick={() => setShow(!show)}
          className="font-normal hover:underline text-yellowPrimary"
        >
          {props.features.split(" - ").length > 10 ? !show ? t("Show_more") : t("Show_less") : ""}
        </button>
      </div>
      <div className="absolute bottom-0 mt-96 mx-auto inset-x-0 px-5 mb-4">
        <div className={`bottom-4 flex justify-center w-full mb-2`}>
          <Button custom="text-base font-normal w-full bg-gray-400" title={t("Preview")} />
        </div>
        <div className={`bottom-4 flex justify-center w-full left-0.5`}>
          <Button
          custom="text-base font-normal w-full"
            onClick={() => 
              // window.location.replace("/request/:id")
               props.isAuth ? navigate("/request",
              { state: {id: props.id}}) : navigate("/signup")
            }
            title={t("Choose")}
          />
        </div>
      </div>
    </div>
  );
}

export default CardOffer;
