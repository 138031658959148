import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  createTheme,
  StepContent,
  StepLabel,
  ThemeProvider,
} from "@mui/material";
import { ReactComponent as Status } from "../../assets/status.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Request_status } from "../../redux/reducers/requestSlice";
import Button from "../../components/Button.compenent";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const steps = [
  {
    label: "Processing",
    description: ``,
  },
  {
    label: "contacting_car_owner",
    description: "",
  },
  {
    label: "payment",
    description: ``,
  },
  {
    label: "In_progress",
    description: ``,
  },
  {
    label: "Done",
    description: ``,
  },
];

function RequestStatus() {
  // const [activeStep, setActiveStep] = React.useState(0);
  // const [completed, setCompleted] = React.useState({});
  const dispatch = useDispatch();
  const { ReqStatus } = useSelector((state) => state.request);
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    dispatch(
      Request_status({
        command_id: id,
      })
    );
  }, [dispatch, id]);

  var activeStep = ReqStatus && ReqStatus.status;

  if (ReqStatus) {
    if (ReqStatus.status === "03" || ReqStatus.status === "04") {
      activeStep = "03";
    } else if (
      ReqStatus.status === "05" ||
      ReqStatus.status === "06" ||
      ReqStatus.status === "07"
    ) {
      activeStep = "04";
    } else if (ReqStatus.status === "08") {
      activeStep = "05";
    }
  }

  const handleViewReport = () => {
    window.open("../../commands/report/" + id, "_self");
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#FFC814",
      },
    },
  });

  return (
    <div className="w-full md:mt-8 font-Saira">
      <div className="max-w-xs md:max-w-screen-md mt-5 lg:max-w-screen-lg lg:px-20 lg:inset-x-0 mx-auto">
        <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div className="flex items-center justify-center my-8 w-full md:px-4 col-span-1">
            <ThemeProvider theme={theme}>
              <Box sx={{ width: "100%", color: "#000000" }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel>
                        <span className="font-Saira font-medium">
                          {t(step.label)}
                        </span>
                      </StepLabel>
                      {index === 2 &&
                        ReqStatus &&
                        ReqStatus.status === "04" &&
                        !ReqStatus.payed.client && (
                          <>
                            <Button
                              title="Payment_method"
                              onClick={() => {
                                window.open(
                                  "/client/commands/payment-method/" + id,
                                  "_self"
                                );
                              }}
                            />
                          </>
                        )}
                      {index === 4 &&
                        ReqStatus &&
                        ReqStatus.status === "08" && (
                          <Button
                            title="ViewReport"
                            onClick={handleViewReport}
                          />
                        )}

                      <StepContent>
                        <Typography>{step.description}</Typography>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {/* {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                      Reset
                    </Button>
                  </Paper>
                )} */}
              </Box>
            </ThemeProvider>
          </div>
          <Status />
        </div>
      </div>
    </div>
  );
}

export default RequestStatus;
