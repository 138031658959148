import React, { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { COLUMNS } from "./columns";
import Select from "react-select";
import "./table.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/ar-dz";
// import Modal from "../Modal.client.admin";
import { clearMessage } from "../../../../redux/reducers/message";
import {
  getMoneyCommands,
  sendConfirmPayment,
} from "../../../../redux/reducers/requestSlice";
import {
  AssignClientAgent,
  getAgentsNames,
} from "../../../../redux/reducers/authAgentSlice";
import Button from "../../../../components/Button.compenent";
import TableSkeleton from "../../../../components/Skeleton/TableSkeleton";
// import { useNavigate } from "react-router-dom";

function Table() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const columns = useMemo(() => COLUMNS, []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { MoneyCommands } = useSelector((state) => state.request);
  const { isLoading } = useSelector((state) => state.request);
  const { Agents_names } = useSelector((state) => state.authAgent);
  const [checked, setChecked] = useState({});
  const [updated, setUpdated] = useState(true);
  const [Agent, setAgent] = useState({});
  const Agents = [];

  const status = {
    "01": "confirm_in_process",
    "02": "confirm_in_process",
    "03": "AssignPaymentToAgent",
    "04": "payment",
    "05": "verfication_in_process",
    "06": "verfication_in_process",
    "07": "verfication_in_process",
    "08": "Done",
  };
  // const status = {
  //   "01": ["confirm_in_process", "confirm_command"],
  //   "02": ["confirm_in_process", "contacting_car_owner"],
  //   "03": ["AssignPaymentToAgent", "payment_in_process"],
  //   "04": ["payment", "payment_in_process"],
  //   "05": ["verfication_in_process", "AssignVerfication"],
  //   "06": ["verfication_in_process", "Report"],
  //   "08": ["Done", "Done"],
  // };

  moment.locale(
    (currentLanguageCode === "ar" && "ar-dz") || currentLanguageCode
  );

  const data = useMemo(
    () =>
      MoneyCommands.map((money, index) => {
        return {
          command_id: money._id.substring(0, 10),
          agent: money.agent_client || "N/A",
          address: money.address || "N/A",
          client_full_name: money.client_name || "N/A",
          client_number: money.client_phone,
          money_amount: money.price,
        };
      }),
    [MoneyCommands]
  );

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getMoneyCommands({ lang: currentLanguageCode }));
    setUpdated(false);
    setChecked({});
  }, [dispatch, updated]);

  Agents_names.map((e) =>
    Agents.push({ value: e._id, label: e.first_name + " " + e.last_name })
  );

  useEffect(() => {
    dispatch(getAgentsNames());
    // setUpdated(false);
  }, [dispatch]);

  const handleStatus = (e, command) => {
    if (window.confirm(t("confirmation_payment_yes"))) {
      setChecked({ ...checked, [command._id]: e.target.value });
      dispatch(
        sendConfirmPayment({
          command_id: command._id,
        })
      );
      setUpdated(true);
    }
  };

  const handleAgent = (e, command_id) => {
    setAgent({ ...Agent, [command_id]: e.value });
  };

  const handleConfirm = (command_id) => {
    dispatch(AssignClientAgent({ command_id, agent_id: Agent[command_id] }));
    setUpdated(true);
  };

  const CustomTheme = (theme) => ({
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: "#f8e9b8",
      primary50: "#e6b412",
      primary: "#e6b412",
    },
  });

  const {
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );
  const { globalFilter } = state;

  return (
    <>
      <div className="p-2 md:m-4 w-full">
        <div className="">
          <p className="font-Saira text-center font-extrabold text-4xl text-darkGray ">
            {t("Money_commands")}
          </p>
          <div className="mb-3">
            <div className=" flex items-stretch md:w-96 w-full mb-4">
              <input
                type="search"
                className="form-control relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-yellow-600 focus:outline-none"
                placeholder="Search"
                aria-label="Search"
                value={globalFilter || ""}
                onChange={(e) => setGlobalFilter(e.target.value)}
                aria-describedby="button-addon2"
              />
              <button
                className="inline-block px-6 py-2.5 bg-yellowPrimary text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-yellow-500 hover:shadow-lg focus:bg-yellow-500  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-800 active:shadow-lg transition duration-150 ease-in-out items-center"
                type="button"
                id="button-addon2"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  className="w-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          {!isLoading ? (
            <div className="overflow-x-scroll overflow-visible h-full md:w-11/12 lg:w-full">
              <>
                <tr>
                  {t("Total")} {rows.length}
                </tr>
              </>
              <table {...getTableBodyProps()} className="font-Saira md:w-full">
                <thead id="tfoot">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          id="tfoot"
                          {...column.getHeaderProps(
                            !(column.Header === "Status") &&
                              column.getSortByToggleProps()
                          )}
                        >
                          {t(column.render("Header"))}
                          {!(column.Header === "Status") &&
                            (column.isSorted ? (
                              column.isSortedDesc ? (
                                "▼"
                              ) : (
                                "▲"
                              )
                            ) : (
                              <>
                                <span>▲</span>
                                <span className="-mx-2">▼</span>
                              </>
                            ))}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr id="tr" {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td id="td" {...cell.getCellProps()}>
                              {(cell.column.Header === "Status" && (
                                <div>
                                  {/* {console.log(MoneyCommands[cell.row.index])} */}
                                  <div className="text-center">
                                    {
                                      status[
                                        MoneyCommands[cell.row.index].status
                                      ]
                                    }
                                  </div>
                                  {MoneyCommands[cell.row.index].status ===
                                    "04" && (
                                    <div className="flex justify-evenly">
                                      <div className="border-x-2 border-slate-300 px-1">
                                        <label>
                                          <input
                                            className="mx-2"
                                            disabled
                                            type="checkbox"
                                            value="Client"
                                            checked={
                                              MoneyCommands[cell.row.index]
                                                .payed.client
                                            }
                                          />
                                          {t("Client")}
                                        </label>
                                      </div>
                                      <div className="px-1">
                                        <label>
                                          <input
                                            className="mx-2"
                                            disabled
                                            type="checkbox"
                                            checked={
                                              MoneyCommands[cell.row.index]
                                                .payed.agent
                                            }
                                          />
                                          {t("Agent")}
                                        </label>
                                      </div>
                                      <div className="px-1 border-x-2 border-slate-300">
                                        <label>
                                          <input
                                            className="mx-2"
                                            type="checkbox"
                                            value={true}
                                            checked={
                                              checked[
                                                MoneyCommands[cell.row.index]
                                                  .payed.agent
                                              ] === true
                                            }
                                            onChange={(e) => {
                                              handleStatus(
                                                e,
                                                MoneyCommands[cell.row.index]
                                              );
                                            }}
                                          />
                                          {t("AgentAgent")}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {MoneyCommands[cell.row.index].status ===
                                    "03" && (
                                    <div className="flex">
                                      <Select
                                        className="font-Saira w-40"
                                        theme={CustomTheme}
                                        placeholder={t("Agent")}
                                        options={Agents}
                                        onChange={(e) => {
                                          handleAgent(
                                            e,
                                            MoneyCommands[cell.row.index]._id
                                          );
                                        }}
                                        required
                                      />
                                      <Button
                                        title={t("Confirm")}
                                        onClick={() =>
                                          handleConfirm(
                                            MoneyCommands[cell.row.index]._id
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                  {MoneyCommands[cell.row.index].status ===
                                    "08" && (
                                    <div className="flex place-content-center">
                                      <Button
                                        title={t("View_report")}
                                        onClick={() =>
                                          window.open(
                                            "./commands/get-report/" +
                                              MoneyCommands[cell.row.index]._id,
                                            "_self"
                                          )
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              )) ||
                                cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </>
  );
}

export default Table;
