import axios from "axios";

const API_URL = process.env.REACT_APP_API;

const getWilaya = (lang) => {
  return axios.get(API_URL + "cities/" + lang).then((response) => {
    // console.log("wilaya service ", response.data);
    return response.data.cities;
  });
};

const getDaira = (wilaya, lang) => {
  return axios
    .get(API_URL + "dairas/" + wilaya + "/" + lang)
    .then((response) => {
      // console.log("wilaya service ", response.data);
      return response.data.dairas;
    });
};

const getCommune = (daira, lang) => {
  return axios
    .get(API_URL + "communes/" + daira + "/" + lang)
    .then((response) => {
      // console.log("wilaya service ", response.data);
      return response.data.communes;
    });
};
///api/communes-by-city/01/ar
const getCommunesAuthAgent = (city, lang) => {
  return axios
    .get(API_URL + "communes-by-city/" + city + "/" + lang)
    .then((response) => {
      return response.data.communes;
    });
};

const wilayaService = { getWilaya, getDaira, getCommune, getCommunesAuthAgent };

export default wilayaService;
