import { Badge } from "@mui/material";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNotifications } from "../redux/reducers/notificationSlice";
import { getUserType } from "../utils/helpers";

function SideBar() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { notifications } = useSelector((state) => state.notification);
  const { dir } = useSelector((state) => state.direction.dir);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const unReadNotifCount = notifications.filter(
    (notification) => notification.isRead === false
  ).length;

  return (
    <div className="flex flex-col min-h-screen h-full p-3 shadow w-60 bg-lightGray">
      <div className="sticky top-20">
        {/* client */}
        {getUserType() === "client" && (
          <>
            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/client/information");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/client/information" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("MyInformation")}
              </button>
            </div>
            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/client/commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/client/commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("MyCommands")}
              </button>
            </div>
            {/* <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/client/support");
                }}
                className={`mx-3 text-grey_light ${window.location.pathname === "/client/support" && "border-yellowPrimary border-b-4"} hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("GetSupport")}
              </button>
            </div> */}
          </>
        )}
        {/* admin  */}
        {getUserType() === "admin" && (
          <>
            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/information");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/information" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("MyInformation")}
              </button>
            </div>
            <div className="flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/notifications");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/notifications" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {unReadNotifCount > 0 ? (
                  <Badge
                    badgeContent={unReadNotifCount}
                    color="error"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: dir === "ltr" ? "right" : "left",
                    }}
                  >
                    {t("Notifications")}
                  </Badge>
                ) : (
                  t("Notifications")
                )}
              </button>
            </div>

            <hr className="border-1 border-slate-300" />

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/cars-commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/cars-commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Cars_Commands")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/money-commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/money-commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Money_commands")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/canceled-commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/canceled-commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Canceled_Commands")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/cars");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/cars" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Cars")}
              </button>
            </div>

            <hr className="border-1 border-slate-300" />

            {JSON.parse(localStorage.getItem("user")).role === "main_admin" && (
              <div className=" flex items-baseline space-x-4">
                <button
                  onClick={() => {
                    navigate("/admin/s-admin");
                  }}
                  className={`mx-3 text-grey_light ${
                    window.location.pathname === "/admin/s-admin" &&
                    "border-yellowPrimary border-b-4"
                  } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
                >
                  {t("Sub_admin")}
                </button>
              </div>
            )}

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/auth-agents");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/auth-agents" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("AuthAgents")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/agents");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/agents" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("Agents")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/clients");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/clients" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("Clients")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/bloggers");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/bloggers" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("Bloggers")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/verification");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/verification" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("Verification")}
              </button>
            </div>

            <hr className="border-1 border-slate-300" />

            {JSON.parse(localStorage.getItem("user")).role === "main_admin" && (
              <div className=" flex items-baseline space-x-4">
                <button
                  onClick={() => {
                    navigate("/admin/manage-plans");
                  }}
                  className={`mx-3 text-grey_light ${
                    window.location.pathname === "/admin/manage-plans" &&
                    "border-yellowPrimary border-b-4"
                  } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
                >
                  {t("Manage_plans")}
                </button>
              </div>
            )}

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/admin/statistics");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/admin/statistics" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("statistics")}
              </button>
            </div>

            <hr className="border-1 border-slate-300" />

            {JSON.parse(localStorage.getItem("user")).role === "main_admin" && (
              <div className=" flex items-baseline space-x-4">
                <button
                  onClick={() => {
                    window.open("/admin/settings", "_self");
                  }}
                  className={`mx-3 text-grey_light ${
                    window.location.pathname === "/admin/settings" &&
                    "border-yellowPrimary border-b-4"
                  } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
                >
                  {t("Settings")}
                </button>
              </div>
            )}
          </>
        )}
        {/* auth_agent */}
        {getUserType() === "auth-agent" && (
          <>
            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/auth-agent/information");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/auth-agent/information" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("MyInformation")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/auth-agent/notifications");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/auth-agent/notifications" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normalalal font-Saira text-xl`}
              >
                {unReadNotifCount > 0 ? (
                  <Badge
                    badgeContent={unReadNotifCount}
                    color="error"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: dir === "ltr" ? "right" : "left",
                    }}
                  >
                    {t("Notifications")}
                  </Badge>
                ) : (
                  t("Notifications")
                )}
              </button>
            </div>

            <hr className="border-1 border-slate-300" />

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/auth-agent/cars-commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/auth-agent/cars-commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Cars_Commands")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/auth-agent/money-commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/auth-agent/money-commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Money_commands")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/auth-agent/canceled-commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname ===
                    "/auth-agent/canceled-commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Canceled_Commands")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/auth-agent/cars");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/auth-agent/cars" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Cars")}
              </button>
            </div>

            <hr className="border-1 border-slate-300" />

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/auth-agent/agents");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/auth-agent/agents" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("Agents")}
              </button>
            </div>
          </>
        )}
        {/* agent */}
        {getUserType() === "agent" && (
          <>
            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/agent/information");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/agent/information" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("MyInformation")}
              </button>
            </div>

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/agent/notifications");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/agent/notifications" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {unReadNotifCount > 0 ? (
                  <Badge
                    badgeContent={unReadNotifCount}
                    color="error"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: dir === "ltr" ? "right" : "left",
                    }}
                  >
                    {t("Notifications")}
                  </Badge>
                ) : (
                  t("Notifications")
                )}
              </button>
            </div>

            <hr className="border-1 border-slate-300" />

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/agent/cars-commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/agent/cars-commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Cars_Commands")}
              </button>
            </div>
            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/agent/money-commands");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/agent/money-commands" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira ${
                  currentLanguageCode === "fr" ? "text-lg" : "text-xl"
                }`}
              >
                {t("Money_commands")}
              </button>
            </div>
          </>
        )}

        {/* client */}
        {getUserType() === "blogger" && (
          <>
            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/blogger/information");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/blogger/information" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("MyInformation")}
              </button>
            </div>

            <hr className="border-1 border-slate-300" />

            <div className=" flex items-baseline space-x-4">
              <button
                onClick={() => {
                  navigate("/blogger/manage-blogs");
                }}
                className={`mx-3 text-grey_light ${
                  window.location.pathname === "/blogger/manage-blogs" &&
                  "border-yellowPrimary border-b-4"
                } hover:border-b-4 hover:border-yellowPrimary text-white h-16 hover:text-darker_grey font-normal font-Saira text-xl`}
              >
                {t("Manage_Blogs")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default SideBar;
