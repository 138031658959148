import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import { ReactComponent as ConfirmCode } from "../../assets/ConfirmCode.svg";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button.compenent";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../redux/reducers/message";
import {
  confirmation,
  resendCodeConfirmation,
} from "../../redux/reducers/authSlice";
import Cookies from "js-cookie";
import Alert from "../../components/Alert";
import { getUserId, getUserType } from "../../utils/helpers";

function Confirmation() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  let user = JSON.parse(localStorage.getItem("user"));
  const { message } = useSelector((state) => state.message);
  const dir = useSelector((state) => state.direction.dir.dir);
  const token = JSON.parse(localStorage.getItem("jwt"));
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    var code = "";
    for (let i = 0; i < 6; i++) {
      code += e.target[i].value;
    }
    dispatch(
      confirmation({
        userType: user.type,
        id: user._id,
        code: code,
        token: token,
        lang: currentLanguageCode,
      })
    );

    // window.location.reload(false);
  };

  const handleResendCode = () => {
    dispatch(
      resendCodeConfirmation({
        userType: getUserType(),
        id: getUserId(),
        token,
        lang: currentLanguageCode,
        type: "resend-code",
      })
    );
  };

  if (message === 200) {
    user.status.verified = true;
    localStorage.setItem("user", JSON.stringify(user));
  }

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  if (getUserType() !== "notVerified") return <Navigate to="/login" />;
  return (
    <div>
      <NavBar />
      <div className="h-screen font-Saira ">
        <div className="font-extrabold mt-11 text-4xl flex justify-center inset-x-0 mx-auto">
          <p className="self-center">{t("Confirmation")}</p>
        </div>
        <div className="font-normal flex justify-center inset-x-0 mx-auto">
          <p className="self-center">{t("EnterTheCode")}</p>
        </div>

        <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center md:max-w-screen-md px-4 md:px-20 lg:max-w-screen-2xl lg:inset-x-0 mx-auto">
          <div className="col-span-1  mt-10 w-full">
            <div className="max-w-sm mx-auto md:max-w-lg mb-32">
              <div className="w-full">
                <div
                  id="otp"
                  className="flex flex-row justify-center text-center md:px-2 mt-5 "
                >
                  <form
                    dir="ltr"
                    className="bloc w-full"
                    onSubmit={handleSubmit}
                  >
                    <div className="mx-auto">
                      <input
                        className="m-2 border border-yellowPrimary h-10 w-10 text-center  form-control rounded focus:outline-none focus:ring-2 focus:ring-yellowDark focus:border-transparent"
                        type="text"
                        id="1"
                        maxLength="1"
                        tabIndex="1"
                        onKeyUp={inputfocus}
                      />
                      <input
                        className="m-2 border border-yellowPrimary h-10 w-10 text-center form-control rounded focus:outline-none focus:ring-2 focus:ring-yellowDark focus:border-transparent"
                        type="text"
                        id="2"
                        maxLength="1"
                        tabIndex="2"
                        onKeyUp={inputfocus}
                      />
                      <input
                        className="m-2 border border-yellowPrimary h-10 w-10 text-center form-control rounded focus:outline-none focus:ring-2 focus:ring-yellowDark focus:border-transparent"
                        type="text"
                        id="3"
                        maxLength="1"
                        tabIndex="3"
                        onKeyUp={inputfocus}
                      />
                      <input
                        className="m-2 border border-yellowPrimary h-10 w-10 text-center form-control rounded focus:outline-none focus:ring-2 focus:ring-yellowDark focus:border-transparent"
                        type="text"
                        id="4"
                        maxLength="1"
                        tabIndex="4"
                        onKeyUp={inputfocus}
                      />
                      <input
                        className="m-2 border border-yellowPrimary h-10 w-10 text-center form-control rounded focus:outline-none focus:ring-2 focus:ring-yellowDark focus:border-transparent"
                        type="text"
                        id="5"
                        maxLength="1"
                        tabIndex="5"
                        onKeyUp={inputfocus}
                      />
                      <input
                        className="m-2 border border-yellowPrimary h-10 w-10 text-center form-control rounded focus:outline-none focus:ring-2 focus:ring-yellowDark focus:border-transparent"
                        type="text"
                        id="6"
                        maxLength="1"
                        tabIndex="6"
                        onKeyUp={inputfocus}
                      />
                    </div>
                    <div
                      dir={dir === "ltr" ? "rtl" : "ltr"}
                      className="flex justify-between mt-5 mb-5"
                    >
                      <Button
                        custom="text-xl px-10 mt-5 block"
                        title={t("Verify")}
                      />
                    </div>
                    {/* <div className="flex justify-center text-center mt-5 mb-5">
                      <Button type="submit" title={t("Verify")} /> */}
                    {/* <button
                        className="px-6 h-12 transition ease-in duration-200 font-normal rounded-2xl bg-yellowPrimary hover:bg-yellowDark text-black border-2 border-yellowDark focus:outline-none"
                        type="submit"
                      >
                        Vérifier
                      </button> */}
                    {/* </div> */}
                  </form>
                </div>
                <div className="absolute -mt-20">
                  <Button
                    custom="px-8 mt-5 bg-lightGray text-white"
                    onClick={handleResendCode}
                    title={t("Resend_code")}
                  />
                </div>
                <div className="flex flex-col hover:underline  hover:text-yellowDark mt-4 mb-4">
                  {message &&
                  message[1] === "success" &&
                  message[2] === "send" ? (
                    <Alert type="Success" content={message[0]} />
                  ) : (
                    message && <Alert type="Failed" content={message} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <ConfirmCode className="col-span-1 hidden md:block my-12 mx-12" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Confirmation;
