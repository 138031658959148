import React, { useEffect } from "react";
import Button from "../../components/Button.compenent";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Car } from "../../assets/car1.svg";
import { ReactComponent as Map } from "../../assets/MAP.svg";
// import { ReactComponent as BgTwo } from "../../assets/BG-tires.svg";
// import { ReactComponent as World } from "../../assets/earth.svg";
import { ReactComponent as ManTaxi } from "../../assets/Man_taxi.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/whatsapp2.svg";
// import Logo from "../../assets/Logo/Logo.svg";
import Feedback1 from "../../assets/feedback/feedback1.png";
import Feedback2 from "../../assets/feedback/feedback2.png";
import Feedback3 from "../../assets/feedback/feedback3.png";
import CardStepOrders from "../../components/Cards/CardStepOrders";
// import CardOffer from "../../components/Cards/CardOffer";
import Feedback from "../../components/Feedback";
import { getUserType } from "../../utils/helpers";
import Plans from "../Client/Plans/Plans";
import { logout } from "../../redux/reducers/authSlice";
import { getSettings } from "../../redux/reducers/adminSlice";
// import GetSupport from "../../components/GetSupport";

function Guest() {
  const { t } = useTranslation();
  const dir = useSelector((state) => state.direction.dir.dir);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const { Settings } = useSelector((state) => state.admin);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  const handleRegister = () => {
    navigate("/signup");
  };

  user &&
    (getUserType() === "notVerified" || user.status.active === false) &&
    dispatch(logout());

  const handleRequestOrRegister = () => {
    if (isAuth) {
      getUserType() === "client" && window.location.replace("/plans");
    } else {
      navigate("/signup");
    }
  };

  return (
    <div className="w-full">
      <div className="bg-lightGray md:h-96 md:overflow-hidden">
        <div className=" grid gap-4 grid-cols-3 md:max-w-screen-2xl  md:mx-auto">
          <Map className="md:mx-auto px-1 py-3 md:h-80 col-span-1" />
          <Car
            className={`py-2 ${
              dir === "rtl" && "origin-center scale-x-[-1] mb-4"
            } md:h-80 w-full col-span-2`}
          />
        </div>
      </div>
      <div
        className={`-mt-6 justify-center text-3xl ${
          isAuth ? "hidden" : "flex"
        }`}
      >
        <Button
          onClick={handleRegister}
          className="self-center"
          title={t("Request")}
        />
      </div>
      {/* Content */}
      <div className="px-4 lg:px-20 font-Saira font-normal text-xl">
        <div className="mx-auto md:max-w-screen-2xl">
          <div className=" flex justify-end mt-5">
            <div
              className={`self-end flex w-full ${
                isAuth && getUserType() !== "client" ? "hidden" : "flex"
              }`}
            >
              {
                <Whatsapp
                  onClick={() =>
                    window.open(
                      "http://api.whatsapp.com/send?phone=213$".replace(
                        "$",
                        Settings &&
                          Settings?.social_media?.whatsapp.substring(
                            1,
                            Settings?.social_media?.whatsapp?.length
                          )
                      )
                    )
                  }
                  className="cursor-pointer"
                />
              }

              {/* {<Whatsapp className="mx-4" />} */}
              <span
                className="pt-3 cursor-pointer font-medium"
                onClick={() =>
                  window.open(
                    "tel:$".replace(
                      "$",
                      Settings && Settings?.social_media?.whatsapp
                    )
                  )
                }
              >
                {t("Call_directly")}
              </span>
            </div>
          </div>
        </div>
        <p className="font-extrabold mt-11 text-2xl md:text-3xl flex justify-center inset-x-0 mx-auto">
          <span className="self-center">
            {t("Who_TrustAll").split(" ").slice(0, 2).join(" ")}
          </span>
          &nbsp;
          <span className="self-center text-yellowPrimary">
            {t("Who_TrustAll").split(" ")[2]}
          </span>
        </p>
        <div className="mt-2 md:grid md:gap-4 md:grid-cols-2  place-items-center lg:max-w-screen-2xl lg:inset-x-0 mx-auto">
          <p className="col-span-1  md:mt-10 font-normal text-base md:text-lg text-center">
            {t("Description_TrustFull")}
          </p>
          <ManTaxi className="col-span-1" />
        </div>
        <p className="font-extrabold mt-11 text-2xl md:text-3xl flex justify-center inset-x-0 mx-auto">
          <span className="self-center">{t("StepsOrder").split(" ")[0]}</span>
          &nbsp;
          <span className="self-center text-yellowPrimary">
            {t("StepsOrder")
              .split(" ")
              .slice(1, t("StepsOrder").split(" ").length)
              .join(" ")}
          </span>
        </p>
        <div className="md:relative text-white md:gap-8 mt-2 md:mt-5 z-[8] md:grid md:grid-cols-3 place-items-center md:px-6 lg:px-20 md:w-full md:max-w-screen-lg lg:inset-x-0 mx-auto">
          <CardStepOrders
            title={t("Service_request")}
            description={t("Order_step1")}
            // buttonTitle={!isAuth ? t("Register") : t("Request")}
            buttonTitle={t("Request")}
            onClick={handleRequestOrRegister}
            number={Settings && Settings?.social_media?.whatsapp}
            isAuth={isAuth}
          />
          <CardStepOrders
            title={t("Team_checks")}
            description={t("Order_step2")}
            hidden="hidden"
          />
          <CardStepOrders
            title={t("Receive_Report")}
            description={t("Order_step3")}
            hidden="hidden"
          />
        </div>
        {/* Offers */}

        <div id="services" className="h-10 invisible"></div>
        <p className="font-bold mt-8 text-2xl md:text-3xl flex justify-center inset-x-0 mx-auto">
          <span
            className={`self-center ${
              t("WhatWeOffer").split(" ").slice(0, 1).join(" ") ===
                "TrustALL" && "text-yellowPrimary"
            }`}
          >
            {t("WhatWeOffer").split(" ").slice(0, 1).join(" ") + " "}
          </span>
          <span
            className={`self-center ${
              t("WhatWeOffer")
                .split(" ")
                .slice(1, t("WhatWeOffer").split(" ").length)
                .join(" ") === "TrustALL" && "text-yellowPrimary"
            } `}
          >
            &nbsp;
            {t("WhatWeOffer")
              .split(" ")
              .slice(1, t("WhatWeOffer").split(" ").length)
              .join(" ")}
          </span>
        </p>

        {/* Plans */}
        <Plans page="guest" />

        {/* Feedback */}
        <br />
        <br />
        <p className="font-bold md:mt-10 text-2xl md:text-3xl flex justify-center inset-x-0 mx-auto">
          <span className="self-center">
            {t("Feedbacks").split(" ")[0] + " "}
          </span>
          <span className="self-center text-yellowPrimary">
            &nbsp;
            {t("Feedbacks")
              .split(" ")
              .slice(1, t("Feedbacks").split(" ").length)
              .join(" ")}
          </span>
        </p>
        <div className="md:relative">
          <div className="md:grid md:gap-16 place-items-center mt-4 md:mt-8 md:grid-cols-3 mb-10 max-w-xs md:max-w-screen-md lg:inset-x-0 mx-auto w-full font-medium">
            {/* <World className="-top-20 hidden md:block md:absolute" /> */}
            <Feedback
              className="md:absolute"
              image={Feedback1}
              title={t("Feedback1")}
            />
            <Feedback
              className="md:absolute"
              image={Feedback2}
              title={t("Feedback2")}
            />
            <Feedback
              className="md:absolute"
              image={Feedback3}
              title={t("Feedback3")}
            />
          </div>

          {/* <GetSupport /> */}
          {/* <div className="md:relative flex justify-center mt-28 my-20">
            <img src={Logo} alt="feedback" className="-mt-6 self-center" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Guest;
