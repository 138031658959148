//get user type

exports.getUserType = () => {
  if (!localStorage.getItem("user")) return false;
  if (
    JSON.parse(localStorage.getItem("user")).type !== "admin" &&
    JSON.parse(localStorage.getItem("user")).status.verified === false
  )
    return "notVerified";
  return JSON.parse(localStorage.getItem("user")).type.replace("_", "-");
};

exports.getToken = () => {
  return JSON.parse(localStorage.getItem("jwt"));
};
exports.getUserStatus = () => {
  return JSON.parse(localStorage.getItem("user")).id_uploaded;
};

exports.getUserId = () => {
  return JSON.parse(localStorage.getItem("user"))._id;
};

exports.getPhotoProfile = () => {
  return JSON.parse(localStorage.getItem("user")).img;
};

exports.convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

// exports.getUserType = () => {
//   if (!localStorage.getItem("user")) return false;
//   if (
//     JSON.parse(localStorage.getItem("user")).type === "client" &&
//     JSON.parse(localStorage.getItem("user")).status.verified === false
//   )
//     return "notVerified";
//   return JSON.parse(localStorage.getItem("user")).type.replace("_", "-");
// };
