import React, { useEffect } from "react";
import CardOffer from "../../../components/Cards/CardOffer";
import { ReactComponent as BgTwo } from "../../../assets/BG-tires.svg";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getOffers } from "../../../redux/reducers/requestSlice";

function Plans(props) {
  const { t } = useTranslation();
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { offers } = useSelector((state) => state.request);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getOffers({
        lang: currentLanguageCode,
      })
    );
  }, [dispatch, currentLanguageCode]);

  return (
    <div className="mb-10">
      {/* <NavBar /> */}
      <p className="font-extrabold text-2xl flex justify-center inset-x-0 mx-auto">
        <span
          className={`self-center font-Saira ${
            props.page === "guest" && "hidden"
          }`}
        >
          {t("ChooseOffer")}
        </span>
      </p>
      <div className="md:grid md:relative text-white md:gap-3 mt-5 md:grid-cols-3 place-items-start md:px-6 lg:px-20 max-w-xs md:max-w-screen-lg lg:inset-x-0 mx-auto">
        <BgTwo className="hidden md:block -top-36 md:absolute mx-auto justify-self-center" />
        {offers.map((offer) => (
          <CardOffer
            key={offer._id}
            className="md:absolute"
            title={offer.title}
            description={offer.description}
            features={offer.options}
            price={offer.price + t("DA")}
            baridiMob={t("BaridiMob")}
            mobPrice={offer.price_baridi_mob + t("DA")}
            id={offer._id}
            isAuth={isAuth}
          />
        ))}
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Plans;
