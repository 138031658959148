import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Email } from "../../assets/icons/email.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Whatsapp } from "../../assets/icons/whatsapp.svg";
import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Background } from "../../assets/Vector.svg";
import { useDispatch, useSelector } from "react-redux";
import { getSettings } from "../../redux/reducers/adminSlice";
import Logo from "../../assets/Logo/Logo.svg";
import Cookies from "js-cookie";
import GetSupport from "../GetSupport";
import { getUserType } from "../../utils/helpers";

function Footer() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { t } = useTranslation();
  const dir = useSelector((state) => state.direction.dir.dir);
  const { Settings } = useSelector((state) => state.admin);
  const isAuth = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  return (
    <div className="md:relative bg-darkGray ">
      <Background
        className={`hidden md:block opacity-10 h-fit ${
          dir === "rtl" && "origin-center transform -rotate-90"
        }`}
      />
      <div className="md:absolute top-0 lg:top-10 mb-15 grid md:grid-cols-3 md:gap-4 font-Saira text-base font-normal text-white min-h-72 px-2 py-4 sm:px-10 md:px-15 lg:px-32 max-w-screen-xl  md:inset-x-0 mx-auto">
        {/* Section one */}
        <div id="contact_us" className="block m-4">
          <p className="font-extrabold text-xl">
            {" "}
            <span className="self-center">
              {t("Who_TrustAll").split(" ").slice(0, 2).join(" ")}
            </span>
            &nbsp;
            <span className="self-center text-yellowDark">
              {t("Who_TrustAll").split(" ")[2]}
            </span>
          </p>
          <p className="py-2">{t("Description_TrustFull")}</p>
          <div className="flex py-2">
            <span>{<Email />}</span>{" "}
            <a
              className="px-2"
              href={`mailto:${Settings && Settings?.social_media?.email}`}
            >
              {Settings && Settings?.social_media?.email}
            </a>
          </div>
          <div
            onClick={() =>
              window.open(
                "http://api.whatsapp.com/send?phone=213$".replace(
                  "$",
                  Settings && Settings?.social_media?.whatsapp.substring(1, Settings?.social_media?.whatsapp?.length)
                )
              )
            }
            className="flex py-2 cursor-pointer"
          >
            {<Whatsapp />}{" "}
            <p className="px-2">
              {Settings && Settings?.social_media?.whatsapp}
            </p>
          </div>
        </div>
        {/* Section two */}
        <div id="about_us" className={`m-4 ${dir === "rtl" && "md:px-10"}`}>
          <p className="font-extrabold text-lg">
            {t("Usefull_links").split(" ").slice(0, 1).join(" ")}
            &nbsp;
            <span className="text-yellowPrimary">
              {t("Usefull_links").split(" ").slice(1, 2).join(" ")}
            </span>
          </p>
          <p className="py-2">
            &nbsp;- &nbsp;&nbsp;
            <span
              onClick={() => window.open("/terms", "_self")}
              className="hover:underline cursor-pointer "
            >
              {t("Terms")}
            </span>
          </p>
          <p className="py-2">
            &nbsp;- &nbsp;&nbsp;
            <span
              onClick={() => window.open(Settings?.sugg_comp)}
              className="hover:underline cursor-pointer "
            >
              {t("Suggestions_complains")}
            </span>
          </p>
          <p className="py-2">
            &nbsp;- &nbsp;&nbsp;
            <span
              onClick={() => window.open("/join-us", "_self")}
              className="hover:underline cursor-pointer "
            >
              {t("join_us")}
            </span>
          </p>
          {/* <p className="py-2">
            &nbsp;- &nbsp;&nbsp;
            <span className="hover:underline cursor-pointer ">
              {t("Signup_services")}
            </span>
          </p> */}
          <p className="py-2">
            &nbsp;- &nbsp;&nbsp;
            <span
              onClick={() => window.open("/FAQs", "_self")}
              className="hover:underline cursor-pointer "
            >
              {t("FAQs")}
            </span>
          </p>
        </div>
        {/* Section three */}
        <div className="m-4">
          <p className="font-extrabold text-lg">
            {t("Location").split(" ").slice(0, 1).join(" ")}{" "}
            <span className="text-yellowPrimary">
              {t("Location")
                .split(" ")
                .slice(1, t("Location").split(" ").length)
                .join(" ")}
            </span>
          </p>
          <p>{Settings && Settings?.location[currentLanguageCode]}</p>
          <div className="flex px-2 ">
            <Facebook
              onClick={() =>
                Settings && window.open(Settings?.social_media?.facebook)
              }
              className="cursor-pointer mx-2 mt-2"
            />
            <Instagram
              onClick={() =>
                Settings && window.open(Settings?.social_media?.instagram)
              }
              className="cursor-pointer mx-2 mt-2"
            />
            <Twitter
              onClick={() =>
                Settings && window.open(Settings?.social_media?.twitter)
              }
              className="cursor-pointer mx-2 mt-2"
            />             
            <LinkedIn
              onClick={() =>
                Settings && window.open(Settings?.social_media?.linked_in)
              }
              className="cursor-pointer mx-2 mt-2"
            /> 
          </div>
          <div className="justify-center my-10 h-10 pb-20">
            <img
              src={Logo}
              alt="feedback"
              className="h-24 cursor-pointer"
              onClick={() => window.open("/", "_self")}
            />
          </div>
        </div>
        {(isAuth &&  getUserType() !== "client" ? "" : <GetSupport />) }
      </div>
      <div className="md:absolute md:bottom-0 md:w-full h-10 bg-black text-white font-Saira flex items-center justify-center truncate font-normal">
        {t("Copyright")} &copy;
      </div>
    </div>
  );
}

export default Footer;
