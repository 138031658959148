import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ReactComponent as ManCheck } from "../../../assets/mancheck.svg";
import Button from "../../../components/Button.compenent";
import { getWilaya } from "../../../redux/reducers/wilayaSlice";
import Cookies from "js-cookie";
import moment from "moment";
import { clearMessage } from "../../../redux/reducers/message";
import Alert from "../../../components/Alert";
import "moment/locale/fr";
import {
  getAuthAgentsNames,
  registerAgent,
} from "../../../redux/reducers/adminSlice";
import { getToken, getUserId, getUserType } from "../../../utils/helpers";

function AddAgent() {
  const currentLanguageCode = Cookies.get("i18next") || "ar";
  const { t } = useTranslation();

  const first_name = useRef();
  const last_name = useRef();
  const birth_date = useRef();
  const phone = useRef();
  const email = useRef();
  const [show, setShow] = useState(true);
  // const [city, setCity] = useState();
  // const [authAgent, setAuthagent] = useState();
  // const wilaya = useSelector((state) => state.wilaya.wilaya);
  const { AuthAgents_names } = useSelector((state) => state.admin);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  // const wilayas = [];
  const AuthAgents = [];

  moment.locale("fr");

  // useEffect(() => {
  //   dispatch(getWilaya({ lang: currentLanguageCode }));
  // }, [dispatch, currentLanguageCode]);

  useEffect(() => {
    dispatch(
      getAuthAgentsNames({
        id: getUserId(),
        token: getToken(),
        lang: currentLanguageCode,
        type: getUserType(),
        // city: city,
      })
    );
    // setUpdated(false);
  }, [dispatch, currentLanguageCode]);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  // wilaya.map((e) =>
  //   wilayas.push({ value: e.wilaya_code, label: e.wilaya_name })
  // );

  AuthAgents_names.map((e) =>
    AuthAgents.push({ value: e._id, label: e.first_name + " " + e.last_name })
  );

  // const handleCity = (e) => {
  //   setCity(e.value);
  // };
  // const handleAuthAgent = (e) => {
  //   setAuthagent(e.value);
  // };

  // const CustomTheme = (theme) => ({
  //   ...theme,
  //   borderRadius: 0,
  //   colors: {
  //     ...theme.colors,
  //     primary25: "#f8e9b8",
  //     primary50: "#e6b412",
  //     primary: "#e6b412",
  //   },
  // });

  const handleSignUp = () => {
    setShow(true);
    dispatch(
      registerAgent({
        first_name: first_name.current.value,
        last_name: last_name.current.value,
        birth_date: moment(birth_date.current.value).format("YYYY-MM-DD"),
        // city: city,
        phone: phone.current.value,
        email: email.current.value,
        lang: currentLanguageCode,
        auth_agent_id: getUserId(),
      })
    );

    setTimeout(() => {
      setShow(false);
    }, 10000);
  };

  return (
    <div>
      <div className="max-w-xs md:max-w-screen-md my-10 min-h-screen lg:max-w-screen-lg lg:px-20 lg:inset-x-0 mx-auto">
        <div className="md:grid md:gap-4 md:grid-cols-2 place-items-center lg:max-w-screen-lg lg:inset-x-0 mx-auto">
          <div className="flex items-center justify-center w-full md:px-4 col-span-1">
            <div className="block w-full">
              <p className="font-Saira text-center font-extrabold text-4xl text-darkGray ">
                {t("AddAgent")}
              </p>
              <br />
              <br />
              <p className="text-md mb-2">
                {t("ObligationFields").split(" * ")[0]}
                <span className="text-red-500"> * </span>
                {t("ObligationFields").split(" * ")[1]}
              </p>
              <div className="mb-3 ">
                <label
                  htmlFor="Firstname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">* </span>
                  {t("Firstname")}
                </label>
                <input
                  type="text"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="firstname"
                  ref={first_name}
                  placeholder={t("Firstname")}
                  required
                />
              </div>
              <div className="mb-3 ">
                <label
                  htmlFor="Famillyname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("Famillyname")}
                </label>
                <input
                  type="text"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="Famillyname"
                  ref={last_name}
                  placeholder={t("Famillyname")}
                  required
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="bithdate"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  {t("Birth_date")}
                </label>
                <input
                  type="date"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="Birth_date"
                  ref={birth_date}
                  placeholder={t("Birth_date")}
                  required
                />
              </div>
              {/* <div className="mb-3">
                <label
                  htmlFor="fullname"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("TheState")}
                </label>
                <Select
                  className="font-Saira font"
                  theme={CustomTheme}
                  placeholder={t("TheState")}
                  options={wilayas}
                  onChange={handleCity}
                  required
                />
              </div> */}
              {/* <div className="mb-3">
                <label
                  htmlFor="authagent"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("AuthAgent")}
                </label>
                <Select
                  className="font-Saira"
                  theme={CustomTheme}
                  placeholder={t("AuthAgent")}
                  options={AuthAgents}
                  value={AuthAgents.filter((d) => d.value === authAgent) || ""}
                  onChange={handleAuthAgent}
                  required
                />
              </div> */}

              <div className="mb-3 ">
                <label
                  htmlFor="phone"
                  className="form-label font-normal font-Saira inline-block mt text-darkGray"
                >
                  <span className="text-red-500">*</span> {t("PhoneNumber")}
                </label>
                <input
                  type="number"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="phone"
                  ref={phone}
                  placeholder={t("PhoneNumber")}
                  required
                />
              </div>
              <div className="mb-3 ">
                <label
                  htmlFor="email"
                  className="form-label font-normal font-Saira inline-block  mt text-darkGray"
                >
                  {t("Email")}
                </label>
                <input
                  type="email"
                  className="block w-full px-3 py-1.5 text-base font-Saira text-darkGray bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-darkGray focus:bg-white focus:border-darkGray focus:outline-none"
                  id="email"
                  ref={email}
                  placeholder={t("Email")}
                  required
                />
              </div>

              <p className="font-extrabold mt-4 invisible">
                {t("AgreeTerms")
                  .split(" - ")
                  .map((cond, i) =>
                    i === 1 ? (
                      <span
                        key={i}
                        className="hover:underline cursor-pointer text-blue-500 font-normal"
                      >
                        &nbsp;{cond}&nbsp;
                      </span>
                    ) : (
                      <span key={i}>&nbsp;{cond}&nbsp;</span>
                    )
                  )}
              </p>
              <div className="flex justify-center">
                <Button
                  onClick={handleSignUp}
                  custom="text-xl px-10 mt-5 block"
                  title={t("Register")}
                />
              </div>
              <div className="mt-4">
                {message ? (
                  message[1] === "success" ? (
                    show && <Alert type={message[1]} content={message[0]} />
                  ) : (
                    show && <Alert type={message[1]} content={message[0]} />
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <ManCheck className="col-span-1 hidden md:block my-8" />
        </div>
      </div>
    </div>
  );
}

export default AddAgent;
