import axios from "axios";
import { getToken, getUserId, getUserType } from "../utils/helpers";

const API_URL = process.env.REACT_APP_API;

const createBlog = (formData) => {
  return axios.post(
    API_URL + getUserType() + "/create-blog/" + getUserId(),
    formData,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const getBlogs = () => {
  return axios.get(API_URL + getUserType() + "/blogs/" + getUserId(), {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
};

const getPublicBlogs = () => {
  return axios.get(API_URL + "blogs/");
};

const deleteBlog = (id) => {
  return axios.delete(
    API_URL + getUserType() + "/delete-blog/" + getUserId() + "/" + id,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
  );
};

const BloggerService = {
  createBlog,
  getBlogs,
  deleteBlog,
  getPublicBlogs,
};

export default BloggerService;
